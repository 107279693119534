/**
 * A function that forbidds the user to insert anything except numbers.
 * Should be used in the "onKeyPress" event handler.
 * @param event
 */
export function validateNumberInput(
  event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLSpanElement>
): false | void {
  return !event.key.match(/\d/) && event.preventDefault();
}

/**
 * A function that forbidds the user to insert unallowed characters.
 * Should be used in the "onKeyPress" event handler.
 * @param event
 */
export function validateRegexInput(
  event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLSpanElement>
): false | void {
  const regex = /^[a-zA-ZÄÖÜäöüÄÖÜäöüÀÈÌÒÙàèìòùÁÉÍÓÚáéíóúÂß0-9 ,.\-'&+*/()]/g;
  return !event.key.match(regex) && event.preventDefault();
}

/**
 * A function that removes spaces from a string
 * @param input (example "Hello ")
 * @return "Hello"
 */
export function removeSpaces(input: string): string {
  return input.replace(/\s/g, '');
}

/**
 * A function that checks if the the input has something other than the following characters:
 * /^[a-zßüÜöÖäÄ_][-\wßüÜöÖäÄ ]*$/i;
 * @param input (example "Hello@$$$$22392")
 * @return true
 */
export function containsUnallowedCharacters(input: string): boolean {
  const regex = /^[a-zA-ZÄÖÜäöüÄÖÜäöüÀÈÌÒÙàèìòùÁÉÍÓÚáéíóúÂß0-9 ,.\-'&+*/()]/g;
  let containsUnallowed = false;

  for (let i = 0; i < input.split('').length; i += 1) {
    if (!input.split('')[i].match(regex)) {
      containsUnallowed = true;
      break;
    }
  }
  return containsUnallowed;
}

/**
 * A function that checks if the email is valid.
 * @param email the email adress of the user
 * @returns boolean
 */
export function validateEmail(input: string): boolean {
  const email = input.replace(/\s/g, '');
  const regex =
    /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (!email) return false;

  const emailParts = email.split('@');

  if (emailParts.length !== 2) return false;

  const account = emailParts[0];
  const address = emailParts[1];

  if (account.length > 64) return false;
  if (address.length > 255) return false;

  const domainParts = address.split('.');
  if (domainParts.some((part) => part.length > 63)) return false;

  if (!regex.test(email)) return false;

  return true;
}

/**
 * A function that returns if an email is forbidden or not.
 * @param email the email adress of the user
 * @returns boolean
 */
export function isEmailForbidden(email: string): boolean {
  let emailIsForbiden = false;
  const forbidedEmails = ['@ergo.de', '@ergo.com', '@dkv.com', '@dkv.de', '@das.de', '@itergo.com'];
  forbidedEmails.map((emailEnding) => {
    if (email.includes(emailEnding)) emailIsForbiden = true;
    return emailIsForbiden;
  });
  return emailIsForbiden;
}

/**
 * A function that capitalizes the first letter.
 * @param input example: "max"
 * @returns "Max"
 */
export function capitalizeFirstLetter(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

/**
 * A function that autotabs to the next input field when reaching the max length of the current input field
 * Must be used in the onKeyUp event handler like the following:
 * ``onKeyUp={() => autotab(document.getElementById('phone-prefix'), document.getElementById('phone-number'))}``
 * @param current HTMLElement | null
 * @param to HTMLElement | null
 */
/*eslint-disable */
export function autotab(current: any, to: any): void {
  if (current && to && current.getAttribute && current.value.length == current.getAttribute('maxlength')) to.focus();
}
/*eslint-disable */

/**
 * A function that detects the user's device
 * @return "tablet" | "mobile" | "desktop"
 */
export function deviceType() {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
  ) {
    return 'mobile';
  }
  return 'desktop';
}

/**
 * A function that returns a string inbetween by defining the begin and the end
 * example: getStringBetween("MyLongString:StringIWant;",":",";")
 * @return StringIWant
 */
export function getStringBetween(text: string, begin: string, end: string) {
  const specialLetters = [
    { sign: '@', code: '%40' },
    { sign: '/', code: '%2F' },
  ];
  const shortString = text.substring(text.indexOf(begin) + begin.length);
  const lastChar = shortString.indexOf(end);
  let newText = shortString.substring(0, lastChar);
  specialLetters.map((letter) => {
    newText = newText.replaceAll(new RegExp(letter.code, 'ig'), letter.sign);
  });
  return newText;
}
