import Button from '@eg/elements/Button';
import Card from '@eg/elements/Card';
import Checkbox from '@eg/elements/Checkbox';
import ControlWithHint from '@eg/elements/ControlWithHint';
import CheckIcon from '@eg/elements/Icons/CheckIcon';
import fileDownload from 'js-file-download';
import { useEffect, useState } from 'react';
import { resetDownloadError, setDownloadError, setServerError } from '../../store/errors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeIDDSteps } from '../../store/offer';
import { createOfferRequestData } from '../../utils/offer-german-mutation';
import { isAxiosError } from '../app/api/request';
import { RoutePath } from '../app/app.interface';
import { setDirectionsState } from '../navigation-buttons/navigation-button.slice';
import { clickEventTrack, errorTrack, pageLoadTrack } from '../tracking/tracking.slice';
import * as api from './download.api';
import './download.component.scss';

const DownloadComponent = function DownloadComponent(): JSX.Element {
  const dispatch = useAppDispatch();
  const offer = useAppSelector((store) => store.offer);
  const aem = useAppSelector((state) => state.aem);
  const [checkbox, setCheckbox] = useState({
    error: false,
    errorMessage: '',
  });
  const [fetchingPdf, setFetchingPdf] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { iddSteps, ...copyOffer } = offer;

  const { disableStepOne, checkStepOne } = iddSteps;
  if (sessionStorage.getItem('copy') !== JSON.stringify(copyOffer))
    dispatch(changeIDDSteps({ checkStepOne: false, disableStepOne: true }));

  const handleDownloadError = (state: boolean) => {
    if (state) {
      setHasError(true);
      dispatch(setDownloadError('pdfDownloadError'));
    } else {
      setHasError(false);
      dispatch(resetDownloadError('pdfDownloadError'));
    }
  };

  useEffect(() => {
    dispatch(
      setDirectionsState({
        backLinkLabel: 'zurück',
        canGoFurther: !disableStepOne && checkStepOne,
        previousStep: RoutePath.PAYMENT,
        nextStep: RoutePath.THANK_YOU,
        showBackButton: true,
      })
    );
    sessionStorage.setItem('copy', JSON.stringify(copyOffer));
  });

  useEffect(() => {
    dispatch(
      pageLoadTrack({
        viewName:
          `${aem.rechtsschutzAemAssets.trackingShortName}:${aem.rechtsschutzAemAssets.trackingViewNameDownload}` || '',
      })
    );
  }, []);

  function handleStepOne() {
    return dispatch(changeIDDSteps({ checkStepOne: true, disableStepOne }));
  }

  return (
    <div className="download__container" id="download__container">
      <h3 className="download__title" id="download__title">
        Beantragen Sie Ihren Vertrag jetzt online.
      </h3>
      <p className="download__subtitle" id="download__subtitle">
        {`Mit dem Klick auf "Online beantragen" werden Ihre Vertragsdaten verbindlich an die ERGO Versicherung AG
        übertragen.`}
      </p>
      <div className="download__card-container" id="download_card-container">
        <Card padding="normal" className="download__card">
          <div className="download__law" id="download_law">
            <h3>EU-Vorgabe: Beratungsverzicht</h3>
            <p>
              Seit Februar 2018 gilt auch für Online-Anträge per Internet:
              <br />
              <br />
              Wir müssen Sie vorher beraten und dies dokumentieren. Sie können auch schnell und dafür ohne Beratung
              online beantragen. Dann brauchen wir vor dem Abschluss Ihren Beratungsverzicht.
            </p>
          </div>
          <div className="download__law-confirmation" id="download_law-confirmation">
            <ControlWithHint
              error={checkbox.error && <p className="download__error-message">{checkbox.errorMessage}</p>}
            >
              <Checkbox
                checked={!disableStepOne}
                data-testid="download_checkbox-idd"
                label="
                    Ich verzichte für diesen Online-Antrag auf eine persönliche Beratung sowie deren Dokumentation. Dies
                    kann sich evtl. auf die Geltendmachung von Schadensersatzansprüchen nachteilig auswirken.
                 "
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckbox({ error: false, errorMessage: '' });
                    dispatch(changeIDDSteps({ checkStepOne, disableStepOne: false }));
                  }
                  if (!e.target.checked) {
                    setCheckbox({
                      error: true,
                      errorMessage:
                        'Bitte bestätigen Sie, dass Sie für diesen Vertragsabschluss auf eine persönliche Beratung verzichten.',
                    });
                    dispatch(changeIDDSteps({ checkStepOne: false, disableStepOne: true }));
                  }
                  dispatch(
                    clickEventTrack({
                      clickedElement: `CheckBox_Beratungsverzicht_${e.target.checked ? 'active' : 'inactive'}`,
                      timedCall: true,
                    })
                  );
                }}
              />
            </ControlWithHint>
          </div>
        </Card>
      </div>

      <div className="download__step1" id="download_step1">
        <div id="download_step1-information">
          <h3>Vertragsunterlagen</h3>
          <p className="download__step-text">
            Bitte speichern Sie vor dem Online-Antrag die Vertragsunterlagen und Informationen als PDF und schließen Sie
            Ihren Online-Antrag gleich ab.
          </p>
        </div>
        <div className="download__option-step" id="download_step1-button">
          <Button
            className="download__option-button"
            variant={checkStepOne ? 'secondary' : 'primary'}
            iconRight={checkStepOne ? CheckIcon : undefined}
            size="large"
            data-testid="download_button"
            loading={fetchingPdf}
            onClick={() => {
              dispatch(
                clickEventTrack({
                  clickedElement: `Button_IDDDownload`,
                  timedCall: true,
                })
              );
              setFetchingPdf(true);
              api
                .getPdfDocument(createOfferRequestData(offer))
                .then((response) => {
                  if (response.data.size > 0) {
                    const fileName = 'ERGO Rechtsschutz Versicherungsunterlagen.pdf';
                    fileDownload(new Blob([response.data], { type: 'application/pdf' }), fileName);
                    handleDownloadError(false);
                    setFetchingPdf(false);
                    handleStepOne();
                  } else {
                    handleDownloadError(true);
                    setFetchingPdf(false);
                  }
                })
                .catch((error: unknown) => {
                  dispatch(setServerError(true));

                  setFetchingPdf(false);
                  if (isAxiosError(error) && error.response) {
                    dispatch(
                      errorTrack({
                        errorType: error.response.status,
                        errorMessages: `${error.request.responseURL}: ${error.message}`,
                        errorUrl: window.location.href,
                      })
                    );
                  }
                });
            }}
            disabled={disableStepOne}
          >
            1 | Herunterladen
          </Button>
          {hasError ? (
            <div className="download__error-message">
              <h3>Entschuldigung</h3>
              <p className="download__step-text">
                Ein Online-Abschluss ist zurzeit leider nicht möglich. Bitte versuchen Sie es später wieder. Oder Sie
                rufen an und lassen sich telefonisch beraten.
              </p>
            </div>
          ) : null}
        </div>
      </div>
      <div className="download__step2" id="download__step2">
        <h3>Zahlungspflichtiger Vertrag</h3>
        <p className="download__step-text">Bei Vertragsabschluss fallen für Sie keine weiteren Kosten an.</p>
      </div>
    </div>
  );
};

export default DownloadComponent;
