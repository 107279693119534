export const PreInsuranceTooltip = function PreInsuranceTooltip(): JSX.Element {
  return (
    <div className="tooltip">
      <h3> Wichtige Mitteilung zu den Folgen einer Anzeigepflichtverletzung</h3>
      <p>
        Werden die in Textform gestellten Fragen, soweit sie für die Übernahme der Gefahr erheblich sind,{' '}
        <b>nicht wahrheitsgemäß</b> oder <b>nicht vollständig</b> beantwortet, kann ERGO den Vertrag unter Einhaltung
        einer Frist von einem Monat <b>kündigen</b>.
      </p>
      <p>
        Bei Vorsatz oder grober Fahrlässigkeit kann ERGO sogar von dem Vertrag <b>zurücktreten</b>. Sie haben dann von
        Anfang an keinen Versicherungsschutz, es sei denn, durch die Verletzung der Anzeigepflicht ist ERGO kein
        Nachteil entstanden.
      </p>
      <p>
        Das Rücktritts- und Kündigungsrecht des Versicherers ist – außer bei Vorsatz – ausgeschlossen, wenn ERGO den
        Vertrag auch bei Kenntnis der nicht angezeigten Umstände geschlossen hätte, wenn auch zu anderen Bedingungen.
      </p>
      <p>
        Die <b>Vertragsanpassung</b>, etwa in Form eines Risikoausschlusses oder einer Beitragserhöhung, wird auf
        Verlangen von ERGO <b>rückwirkend wirksam</b>, bei einer von Ihnen nicht zu vertretenden Pflichtverletzung ab
        der laufenden Versicherungsperiode. Durch die rückwirkende Einfügung eines Risikoausschlusses kann Ihr
        Versicherungsschutz für einen eingetretenen oder zukünftigen Versicherungsfall ebenfalls entfallen.
      </p>
    </div>
  );
};

export const PreInsuranceExpirationTooltip = function PreInsuranceExpirationTooltip(): JSX.Element {
  return (
    <div className="tooltip">
      <h3>Vertragsablauf</h3>

      <p>
        Den Vertragsablauf Ihrer Vorversicherung finden Sie in den Unterlagen Ihres bisherigen Vertrags. Er ist wichtig
        für den <b>rechtzeitigen Beginn</b> Ihres neuen ERGO Rechtsschutzes.
      </p>

      <p>
        <b>Tipp:</b> Legen Sie den Versicherungsbeginn auf denselben Tag, an dem Ihre Vorversicherung endet. Dadurch
        erhalten Sie durchgehenden Versicherungsschutz.
      </p>

      <h3>Beispiele</h3>
      <ul>
        <li>
          <b>Durchgehender Versicherungsschutz</b> <br />
          Vertragsablauf Vorvertrag: 30.9. <br />
          Versicherungsbeginn: 30.9. <br />→ Durchgehender Versicherungsschutz ohne Wartezeiten
        </li>

        <br />
        <li>
          <b>Lücke</b> <br /> Vertragsablauf Vorvertrag: 30.9. <br />
          Versicherungsbeginn: 1.12. <br /> → Lücke im Versicherungsschutz vom 1.10. bis 30.11. Bei einigen
          Leistungsarten bestehen Wartezeiten.
        </li>

        <br />
        <li>
          <b>Doppelversicherung</b> <br /> Vertragsablauf des Vorvertrags: 30.9.
          <br />
          Versicherungsbeginn: 1.7.
          <br /> → Überschneidung der Versicherungen zwischen 1.7. und 30.09.
        </li>
      </ul>
    </div>
  );
};

export const PreInsuranceDropdownTooltip = function PreInsuranceDropdownTooltip(): JSX.Element {
  return (
    <div className="tooltip">
      <h3>War der Vertrag leistungsfrei?</h3>
      <p>
        <b>Tipp:</b> Sie haben schon eine Rechtsschutzversicherung (Vorversicherung) – egal, ob beim ERGO Rechtsschutz
        oder anderswo – und haben <b>in den letzten Jahren keine Leistungen in Anspruch genommen</b>? Legen Sie in
        diesem Fall den <b>Versicherungsbeginn</b> dieses ERGO Rechtsschutzes{' '}
        <b>auf den Tag, an dem Ihre Vorversicherung endet</b>. Dann profitieren Sie im Leistungsfall wie folgt:
      </p>
      <p>
        Sie haben mindestens <b>die letzten 3 Jahre</b> beim Vorversicherer keine Leistungen in Anspruch genommen? Dann{' '}
        <b>halbiert</b> ERGO Ihre gewählte <b>Selbstbeteiligung</b> bei Ihrem 1. Leistungsfall.
      </p>
      <p>
        Sie haben mindestens <b>die letzten 5 Jahre</b> beim Vorversicherer keine Leistungen in Anspruch genommen? Dann{' '}
        <b>verzichtet</b> ERGO bei Ihrem 1. Leistungsfall sogar ganz auf die vereinbarte <b>Selbstbeteiligung</b>.
      </p>
    </div>
  );
};
