/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */

import TooltipIcon, { TooltipIconProps } from '@eg/elements/TooltipIcon';
import React from 'react';
import './tooltip-icon.css';

const EnhancedTooltipIcon = function EnhancedTooltipIcon(
  props: React.PropsWithChildren<TooltipIconProps>
): JSX.Element {
  return (
    <TooltipIcon {...props} isModal>
      <div className="enhanced-tooltip-content">{props.children}</div>
    </TooltipIcon>
  );
};

const TooltipIconComponent = EnhancedTooltipIcon;
export default TooltipIconComponent;
