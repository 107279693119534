import Autocomplete from '@eg/elements/Autocomplete';
import Select from '@eg/elements/components/Select';
import ControlWithHint from '@eg/elements/ControlWithHint';
import { DateInputValue } from '@eg/elements/DateInput';
import DateInputRow from '@eg/elements/DateInputRow';
import FormRow from '@eg/elements/FormRow';
import { AlertIcon } from '@eg/elements/Icons/AlertIcon';
import { CheckColorIcon } from '@eg/elements/Icons/CheckColorIcon';
import Input from '@eg/elements/Input';
import Radio from '@eg/elements/Radio';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import RadioTile from '@eg/elements/RadioTile';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { initializePreInsuranceError, resetPreInsuranceError, setPreInsuranceError } from '../../store/errors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  changePreInsurance,
  changePreInsuranceStartDate,
  changePreInsuranceStatus,
  initializePreInsurance,
} from '../../store/offer';
import { DateErrorMessages, deMoment, deMomentString, tomorrow } from '../../utils/date-configurations';
import { validateNumberInput } from '../../utils/helpers';
import { isAxiosError } from '../app/api/request';
import { RoutePath } from '../app/app.interface';
import { canGoToNextStep, setDirectionsState } from '../navigation-buttons/navigation-button.slice';
import TooltipIcon from '../tooltip-icon/tooltip-icon';
import { clickEventTrack, errorTrack, pageLoadTrack, setTariffOptions } from '../tracking/tracking.slice';
import * as api from './pre-insurance.api';
import './pre-insurance.component.scss';
import {
  isPreInsured,
  PreInsurance,
  PreInsuranceExpirationDate,
  PreInsuranceOptionLabel,
  PreInsuranceQuitter,
} from './pre-insurance.interface';
import {
  PreInsuranceDropdownTooltip,
  PreInsuranceExpirationTooltip,
  PreInsuranceTooltip,
} from './pre-insurance.tooltip';

const preInsuranceStatuses: {
  value: PreInsuranceOptionLabel;
  label: string;
}[] = [
  {
    value: PreInsuranceOptionLabel.YES,
    label: 'Ja',
  },
  {
    value: PreInsuranceOptionLabel.NO,
    label: 'Nein',
  },
];

const PreInsuranceComponent = function PreInsuranceComponent(): JSX.Element {
  const dispatch = useAppDispatch();
  const aem = useAppSelector((state) => state.aem);
  const errors = useAppSelector((state) => state.errors);
  const preInsuranceStatus = useAppSelector((state) => state.offer.preInsuranceStatus);
  const preInsurance = useAppSelector((state) => state.offer.preInsurance);
  const insuranceStartDate = useAppSelector((state) => state.offer.insuranceStartDate);
  const preInsuranceStartDate = useAppSelector((state) => state.offer.preInsurance?.expiration || null);
  const [preInsuranceTypeOne, setPreInsuranceTypeOne] = useState('none');
  const [preInsuranceTypeTwo, setPreInsuranceTypeTwo] = useState('none');
  const [preInsuranceTypeThree, setPreInsuranceTypeThree] = useState('none');
  const [preInsuranceDropdown, setpreInsuranceDropdown] = useState('none');
  const [preInsuranceThreeYears, setpreInsuranceThreeYears] = useState('none');
  const [preInsuranceFiveYears, setpreInsuranceFiveYears] = useState('none');
  const [insurancesCollection, setInsurancesCollection] = useState<api.PreInsuranceResponse[] | null>(null);
  const [name, setName] = useState(preInsurance ? preInsurance.name : '');
  const [number, setNumber] = useState(preInsurance ? preInsurance.number : '');
  const [dropdown, setDropdown] = useState('');
  const [quitter, setQuitter] = useState<PreInsuranceQuitter>(
    preInsurance ? preInsurance.quitter : PreInsuranceQuitter.UNKNOWN
  );
  const [expiration, setExpiration] = useState<PreInsuranceExpirationDate>({
    value: {
      day: preInsuranceStartDate ? preInsuranceStartDate.split('.')[0] : '',
      month: preInsuranceStartDate ? preInsuranceStartDate.split('.')[1] : '',
      year: preInsuranceStartDate ? preInsuranceStartDate.split('.')[2] : '',
    },
    errorMessages: null,
    valid: true,
    touched: false,
  });

  useEffect(() => {
    if (quitter !== PreInsuranceQuitter.POLICY_HOLDER)
      setExpiration({
        value: {
          day: '',
          month: '',
          year: '',
        },
        errorMessages: null,
        valid: true,
        touched: false,
      });
  }, [quitter]);

  const PreInsuranceData: PreInsurance = {
    name,
    number,
    quitter,
    expiration:
      expiration.value.day && expiration.value.month && expiration.value.year
        ? `${expiration.value.day}.${expiration.value.month}.${expiration.value.year}`
        : '',
    dropdown,
  };

  const requirementsAreFulfilled =
    name &&
    number &&
    quitter &&
    expiration.valid &&
    expiration.value.day &&
    expiration.value.month &&
    expiration.value.year;

  useEffect(() => {
    dispatch(changePreInsurance(PreInsuranceData));
  }, [name, number, quitter, expiration, dropdown]);

  const setPreInsuranceStatus = (newStatus: PreInsuranceOptionLabel) => {
    if (isPreInsured(newStatus)) {
      dispatch(changePreInsuranceStatus(newStatus));
      dispatch(canGoToNextStep(!!newStatus));
    }
  };

  const handleCanGoFurther = () => {
    let canGoFurther = true;
    const dropDownIsShown = preInsuranceDropdown !== 'none';
    if (dropDownIsShown) {
      canGoFurther = !!dropdown;
    } else {
      canGoFurther =
        (!!preInsuranceStatus && quitter !== PreInsuranceQuitter.INSURER) ||
        preInsuranceStatus === PreInsuranceOptionLabel.NO;
    }
    return canGoFurther;
  };

  useEffect(() => {
    dispatch(
      setDirectionsState({
        canGoFurther: handleCanGoFurther(),
        previousStep: RoutePath.PERSONAL_DATA,
        nextStep: RoutePath.TARIFF_OVERVIEW,
        showBackButton: true,
        jumpToContent:
          !requirementsAreFulfilled && preInsuranceStatus === 'ja' ? '.pre-insurance__input-title' : undefined,
      })
    );
  });

  useEffect(() => {
    dispatch(
      pageLoadTrack({
        viewName:
          `${aem.rechtsschutzAemAssets.trackingShortName}:${aem.rechtsschutzAemAssets.trackingViewNamePreInsurance}` ||
          '',
      })
    );

    if (preInsurance?.dropdown) setDropdown(preInsurance?.dropdown);
  }, []);

  useEffect(() => {
    setPreInsuranceTypeOne('none');
    setPreInsuranceTypeTwo('none');
    setPreInsuranceTypeThree('none');
    setpreInsuranceDropdown('none');

    if (expiration.valid) {
      const newInsurance = deMomentString(insuranceStartDate || '');
      const oldInsurance = deMomentString(
        `${expiration.value.day}.${expiration.value.month}.${expiration.value.year}` || ''
      );

      if (newInsurance.isSame(oldInsurance)) {
        setPreInsuranceTypeOne('block');
        setpreInsuranceDropdown('block');
      }

      if (oldInsurance.isAfter(newInsurance)) {
        setPreInsuranceTypeTwo('block');
        setpreInsuranceDropdown('block');
      }

      if (
        newInsurance.isAfter(oldInsurance) &&
        oldInsurance.isAfter(deMoment()) &&
        !newInsurance.isSame(tomorrow, 'day')
      ) {
        setPreInsuranceTypeThree('block');
      }
    }
  }, [preInsuranceStartDate]);

  useEffect(() => {
    switch (dropdown) {
      case '3':
        setpreInsuranceThreeYears('flex');
        setpreInsuranceFiveYears('none');
        break;
      case '5':
        setpreInsuranceThreeYears('none');
        setpreInsuranceFiveYears('flex');
        break;
      case '0':
      default:
        setpreInsuranceThreeYears('none');
        setpreInsuranceFiveYears('none');
        break;
    }
  }, [dropdown]);

  useEffect(() => {
    if (name)
      api
        .getPreInsurance(name)
        .then((response) => setInsurancesCollection(response.data))
        .catch((error: unknown) => {
          setInsurancesCollection(null);
          if (isAxiosError(error) && error.response) {
            // if ()
            dispatch(
              errorTrack({
                errorType: error.response.status,
                errorMessages: `${error.request.responseURL}: ${error.message}`,
                errorUrl: window.location.href,
              })
            );
          }
        });
  }, [name]);

  const quitterError =
    quitter === PreInsuranceQuitter.INSURER ? (
      <div className="pre-insurance__quitter-error">
        Ein Online-Abschluss ist leider nicht möglich, wenn die Gesellschaft den Vertrag gekündigt hat.
        <br />
        Ihr ERGO Berater vor Ort hilft Ihnen gern weiter.
      </div>
    ) : null;

  return (
    <div className="pre-insurance__container">
      <div id="pre-insurance_header">
        <div className="page__title">
          Waren oder sind Sie (oder ggf. Ihr mitversicherter Partner) rechtsschutzversichert?
        </div>
        <div className="page__subtitle">
          <p>Bitte beachten Sie die vorvertragliche Anzeigepflicht.</p>
          <TooltipIcon
            isModal
            onToggledOpen={(isOpen) => {
              if (isOpen)
                dispatch(
                  clickEventTrack({
                    clickedElement: 'InfoIcon_VoV',
                    timedCall: true,
                  })
                );
            }}
          >
            <PreInsuranceTooltip />
          </TooltipIcon>
        </div>
      </div>
      <div className="pre-insurance__select-container-mobile" id="pre-insurance_select-container-mobile">
        {preInsuranceStatuses.map(({ value, label }) => (
          <div
            className={classNames({
              'esc_border-radius': true,
              'pre-insurance__single-radio-row': true,
              selected: preInsuranceStatus === value,
            })}
            key={value}
            id={`pre-insurance-select-mobile-${value}`}
          >
            <Radio
              className="pre-insurance__option-radio"
              value={value}
              data-testid={`pre-insurance-select-mobile-${value}`}
              name="pre-insurance-select-mobile"
              checked={preInsuranceStatus === value}
              label={label}
              onChange={() => {
                setPreInsuranceStatus(value);
                dispatch(
                  clickEventTrack({
                    clickedElement: `RButton_${label}`,
                    tariffOptions: { VoV: `${label.charAt(0)}` },
                    timedCall: true,
                  })
                );
                if (value === PreInsuranceOptionLabel.NO) {
                  dispatch(initializePreInsurance());
                  dispatch(initializePreInsuranceError());
                }
                if (value === PreInsuranceOptionLabel.YES) {
                  dispatch(changePreInsurance(PreInsuranceData));
                }
              }}
            />
          </div>
        ))}
      </div>
      <div className="pre-insurance__select-container" id="pre-insurance_select-container">
        {preInsuranceStatuses.map((status) => (
          <div id={`pre-insurance-radio-tile-${status.value}`} key={status.value}>
            <RadioTile
              value={status.value}
              hideCheckMark
              data-testid={`pre-insurance-radio-tile-${status.value}`}
              onChange={() => {
                setPreInsuranceStatus(status.value);
                // if ()
                dispatch(
                  clickEventTrack({
                    clickedElement: `RButton_${status.label}`,
                    tariffOptions: { VoV: `${status.label.charAt(0)}` },
                    timedCall: true,
                  })
                );
                if (status.value === PreInsuranceOptionLabel.NO) {
                  dispatch(initializePreInsurance());
                  dispatch(initializePreInsuranceError());
                }
                if (status.value === PreInsuranceOptionLabel.YES) {
                  dispatch(changePreInsurance(PreInsuranceData));
                  dispatch(initializePreInsuranceError());
                }
              }}
              checked={preInsuranceStatus === status.value}
            >
              <Radio
                className="pre-insurance__icon-description"
                checked={preInsuranceStatus === status.value}
                onChange={() => {
                  setPreInsuranceStatus(status.value);
                  // if ()
                  dispatch(
                    clickEventTrack({
                      clickedElement: `RButton_${status.label}`,
                      tariffOptions: { VoV: `${status.label.charAt(0)}` },
                      timedCall: true,
                    })
                  );
                  if (status.value === PreInsuranceOptionLabel.NO) {
                    dispatch(initializePreInsurance());
                    dispatch(initializePreInsuranceError());
                  }
                  if (status.value === PreInsuranceOptionLabel.YES) {
                    dispatch(changePreInsurance(PreInsuranceData));
                  }
                }}
                label={status.label}
                value={status.value}
              />
            </RadioTile>
          </div>
        ))}
      </div>

      {preInsuranceStatus === PreInsuranceOptionLabel.YES && (
        <div className="pre-insurance__input-container" id="pre-insurance__input-container">
          <div className="pre-insurance__input">
            <div className="pre-insurance__input-title">
              <b> Bitte machen Sie einige zusätzliche Angaben.</b>
              <p>
                <b>Tipp:</b> Am besten nehmen Sie dazu Ihren Vorvertrag zur Hand.
              </p>
            </div>
            <FormRow className="pre-insurance__insurance" label="Versicherungsgesellschaft">
              <ControlWithHint error={!!errors.preInsurance.missingCompany && errors.preInsurance.missingCompany}>
                <Autocomplete
                  placeholder="z. B. ERGO Versicherung AG"
                  value={name}
                  onChange={(value) => {
                    setName(value);
                    dispatch(resetPreInsuranceError('missingCompany'));
                  }}
                  suggestions={insurancesCollection?.map((insurance) => insurance.name)}
                />
              </ControlWithHint>
            </FormRow>
            <FormRow className="pre-insurance__insurance-number" label="Versicherungsscheinnummer">
              <ControlWithHint error={!!errors.preInsurance.missingNumber && errors.preInsurance.missingNumber}>
                <Input
                  placeholder="z. B. SV1234567890"
                  value={number}
                  onChange={(e) => {
                    setNumber(e.target.value);
                    dispatch(resetPreInsuranceError('missingNumber'));
                  }}
                />
              </ControlWithHint>
            </FormRow>
            <RadioGroupRow
              name="quitter"
              label="Gekündigt von"
              className="pre-insurance__insurance-role"
              error={
                (!!errors.preInsurance.missingTerminated && errors.preInsurance.missingTerminated) ||
                (!!quitterError && quitterError)
              }
              value={preInsurance && preInsurance.quitter ? preInsurance.quitter : quitter}
            >
              <Radio
                value={PreInsuranceQuitter.POLICY_HOLDER}
                label="Versicherungsnehmer"
                onChange={() => {
                  setQuitter(PreInsuranceQuitter.POLICY_HOLDER);
                  dispatch(resetPreInsuranceError('missingTerminated'));
                  dispatch(resetPreInsuranceError('terminatedByTheInsurer'));
                  dispatch(setTariffOptions({ VoVK: 'VN' }));
                }}
              />

              <Radio
                value={PreInsuranceQuitter.INSURER}
                label="Versicherer"
                onChange={() => {
                  setQuitter(PreInsuranceQuitter.INSURER);
                  dispatch(setPreInsuranceError('terminatedByTheInsurer'));
                  dispatch(resetPreInsuranceError('missingTerminated'));
                  dispatch(setTariffOptions({ VoVK: 'V' }));
                }}
              />
            </RadioGroupRow>
            {quitter !== PreInsuranceQuitter.INSURER && (
              <div className="pre-insurance__date-input">
                <DateInputRow
                  autoTab
                  value={expiration.value}
                  error={
                    (!!errors.preInsurance.missingDate && errors.preInsurance.missingDate) ||
                    (!!errors.preInsurance.badDateInput && errors.preInsurance.badDateInput)
                  }
                  onKeyPress={(e) => validateNumberInput(e)}
                  onBlur={() => {
                    if (!expiration.valid && expiration.errorMessages) {
                      const errorType = Object.keys(expiration.errorMessages)[0] as DateErrorMessages;
                      if (errorType === 'badInput') dispatch(setPreInsuranceError('badDateInput'));
                      if (expiration.value && expiration.value.year && expiration.value.year.length < 4)
                        dispatch(setPreInsuranceError('missingDate'));
                    }
                  }}
                  onChange={(value: DateInputValue, error) => {
                    const { valid, ...otherErrors } = error;
                    const removeLeadingZerosValue = {
                      day: value.day,
                      month: value.month,
                      year: value.year && value.year.replace(/^0+/, ''),
                    };
                    setDropdown('');
                    setExpiration({ ...expiration, value: removeLeadingZerosValue, errorMessages: otherErrors, valid });
                    dispatch(changePreInsuranceStartDate(expiration.value));
                    dispatch(resetPreInsuranceError('missingDate'));
                    dispatch(resetPreInsuranceError('badDateInput'));
                  }}
                  label={
                    <div className="pre-insurance__date-label">
                      <div>Vertragsablauf</div>
                      <TooltipIcon isModal>
                        <PreInsuranceExpirationTooltip />
                      </TooltipIcon>
                    </div>
                  }
                />
              </div>
            )}
            <FormRow
              className="pre-insurance__date"
              style={{ display: preInsuranceDropdown }}
              label={
                <div className="pre-insurance__date-label">
                  <div>Wie viele Jahre war der Vorvertrag leistungsfrei?</div>
                  <TooltipIcon isModal>
                    <PreInsuranceDropdownTooltip />
                  </TooltipIcon>
                </div>
              }
            >
              <ControlWithHint>
                <Select
                  className="pre-insurance__dropdown"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const optionSelected = e.target.value;
                    if (optionSelected === '') {
                      setDropdown('0');
                    } else {
                      setDropdown(optionSelected);
                    }
                  }}
                  defaultValue={dropdown ?? dropdown}
                >
                  <option value="">Bitte auswählen</option>
                  <option value="0">0 bis 2 Jahre</option>
                  <option value="3">3 bis 4 Jahre</option>
                  <option value="5">5 oder mehr Jahre</option>
                </Select>
              </ControlWithHint>
            </FormRow>
            <div style={{ display: preInsuranceTypeOne }}>
              <div className="pre-insurance__checkbox-text">
                <CheckColorIcon width={20} height={20} />
                <div>
                  Ihr gewählter Versicherungsbeginn ist der {insuranceStartDate || ''}.
                  <p>
                    Sehr gut, Ihr neuer Vertrag schließt direkt an den Vorvertrag an. So entstehen{' '}
                    <strong>keine Versicherungslücken</strong> und <strong>keine Wartezeiten</strong>.
                  </p>
                </div>
              </div>
            </div>
            <div style={{ display: preInsuranceTypeTwo }}>
              <div className="pre-insurance__checkbox-text">
                <AlertIcon width={20} height={20} />
                <div>
                  Ihr gewählter Versicherungsbeginn ist der {insuranceStartDate || ''}.
                  <p>
                    Das Ende Ihrer Vorversicherung und der Beginn dieser Versicherung <strong>überschneiden</strong>{' '}
                    sich. Das bedeutet: Wenn Sie die gleichen Lebensbereiche absichern, sind Sie unter Umständen{' '}
                    <strong>doppelt versichert</strong>.
                  </p>
                  <p>
                    Das ist zwar möglich, doch bei einem Schadensfall bekommen Sie{' '}
                    <strong>nur einmal Leistungen</strong>.
                  </p>
                  <p>
                    <strong>Tipp:</strong> Legen Sie den Versicherungsbeginn auf <strong>denselben Tag</strong>, an dem
                    Ihre Vorversicherung endet. So erhalten Sie <strong>durchgehenden Versicherungsschutz</strong>.
                  </p>
                  <Link to={RoutePath.INSURANCE_START} id="link">
                    Versicherungsbeginn ändern
                  </Link>
                </div>
              </div>
            </div>
            <div style={{ display: preInsuranceTypeThree }}>
              <div className="pre-insurance__checkbox-text">
                <AlertIcon width={20} height={20} />
                <div>
                  Ihr gewählter Versicherungsbeginn ist der {insuranceStartDate || ''}.
                  <p>
                    Zwischen dem Ende Ihrer Vorversicherung und dem Beginn dieser Versicherung entsteht eine{' '}
                    <strong>Lücke</strong>. Dadurch haben Sie auf manche Leistungen <strong>erst nach 3 Monaten</strong>{' '}
                    Anspruch.
                  </p>
                  <p>
                    <strong>Tipp:</strong> Legen Sie den Versicherungsbeginn auf <strong>denselben Tag</strong>, an dem
                    Ihre Vorversicherung endet. So erhalten Sie <strong>durchgehenden Versicherungsschutz</strong>.
                  </p>
                  <Link to={RoutePath.INSURANCE_START} id="link">
                    Versicherungsbeginn ändern
                  </Link>
                </div>
              </div>
            </div>
            <div className="pre-insurance__checkbox-text" style={{ display: preInsuranceThreeYears }}>
              <CheckColorIcon width={20} height={20} />
              <div>
                Sie haben mindestens <b>die letzten 3 Jahre</b> beim Vorversicherer keine Leistungen in Anspruch
                genommen. Deshalb <b>halbiert</b> ERGO Ihre gewählte <b>Selbstbeteiligung</b> bei Ihrem 1.
                Leistungsfall.
              </div>
            </div>
            <div className="pre-insurance__checkbox-text" style={{ display: preInsuranceFiveYears }}>
              <CheckColorIcon width={20} height={20} />
              <div>
                Sie haben mindestens <b>die letzten 5 Jahre</b> beim Vorversicherer keine Leistungen in Anspruch
                genommen. Deshalb <b>verzichtet</b> ERGO bei Ihrem 1. Leistungsfall ganz auf die vereinbarte{' '}
                <b>Selbstbeteiligung</b>.
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreInsuranceComponent;
