import { AxiosResponse } from 'axios';
import { getXfUrlByAemProperty, noCacheHeaders } from '../aem/aemHelper';
import request from '../app/api/request';
import { RsMaritalStatus } from '../marital-status/marital-status.interface';

export type TarifBody = {
  geburtsdatumVN: string | null;
  rechtsschutzPrivat: boolean;
  rechtsschutzBeruf: boolean;
  rechtsschutzVerkehr: boolean;
  rechtsschutzWohnen: boolean;
  tarifauswahl: string;
  situation: RsMaritalStatus | null;
  rabattSeniorentarif: boolean;
  rabattBeamtentarif: boolean;
  rabattStartbonus: boolean;
  selbstbeteiligung: string;
  versicherungsbeginn: string | null;
  vertragsdauer: string | null;
  zahlungsweise: number;
  tarif: string;
};

export const postTarif = (body: TarifBody): Promise<AxiosResponse> => {
  return request.post('/api/calc/post/', body);
};

export const getAemXf = (url: string, isEditMode: boolean): Promise<AxiosResponse> => {
  if (url)
    return request.get(getXfUrlByAemProperty(url, isEditMode), {
      headers: noCacheHeaders,
    });
  throw new Error('No Content Provided');
};
