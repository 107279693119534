export enum PersonSalutation {
  MISTRESS = 'Frau',
  MISTER = 'Herr',
  UNKNOWN = '',
}

// BackEnd:
// {
//     anrede: 'Herr';
//     vorname: 'Anna';
//     nachname: 'Mulerr';
//     geburtsdatum: '01.01.2000';
//     adresse: {
//       strasse: 'Höhenstr.';
//       hausNr: '5';
//       postleitzahl: '47249';
//       wohnort: 'Duisburg';
//     };
//     email: 'fdsfsd@fsd343f.gf';
//   }

export interface PersonalData {
  salutation: PersonSalutation;
  firstName: string;
  lastName: string;
  birthdate: string | null;
  adress: {
    street: string;
    houseNumber: string;
    postcode: string;
    area: string;
    customArea?: boolean;
  };
  email: string;
  countryCode: string;
  phonePrefix: string;
  phoneNumber: string;
}

export enum PersonalDataFieldNames {
  SALUTATION = 'salutation',
  NAME = 'name',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  ADDRESS = 'address',
  STREET = 'street',
  HOUSE_NUMBER = 'houseNumber',
  POSTCODE = 'postcode',
  CITY = 'city',
  BIRTHDATE = 'birthDate',
  PHONE_NUMBER_WITH_PREFIX = 'phoneNumberWithPrefix',
  PHONE_PREFIX = 'phonePrefix',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
}

export type PersonalDataErrors =
  | 'missingSalutation'
  | 'missingName'
  | 'missingSurname'
  | 'missingStreet'
  | 'missingHouseNumber'
  | 'missingPostcode'
  | 'invalidPostcode'
  | 'unknownPostcode'
  | 'missingEmail'
  | 'wrongEmail'
  | 'wrongEmailErgo'
  | 'wrongPhoneNumber'
  | 'unallowedCharacters';

export const PersonalDataErrorMessages = {
  missingSalutation: 'Bitte wählen Sie eine Anrede aus.',
  missingName: 'Bitte geben Sie Ihren Vornamen an.',
  missingSurname: 'Bitte geben Sie Ihren Familien-/Nachnamen an.',
  missingStreet: 'Bitte geben Sie Ihre Straße an.',
  missingHouseNumber: 'Bitte geben Sie Ihre Hausnummer an.',
  missingPostcode: 'Bitte geben Sie Ihre Postleitzahl an.',
  invalidPostcode: 'Bitte prüfen Sie die PLZ.',
  unknownPostcode:
    'Es konnte kein Ort ermittelt werden. Bitte prüfen Sie die PLZ oder geben Sie den Wohnort ein. Ihr Wohnsitz muss in Deutschland sein.',
  missingEmail: 'Bitte geben Sie Ihre E-Mail-Adresse an.',
  wrongEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
  wrongEmailErgo:
    'Die Eingabe von E-Mail-Adressen mit den Endungen "@ergo.de", "@ergo.com" "@dkv.com", "@dkv.de", "@das.de" und "@itergo.com" ist nicht möglich. Bitte geben Sie eine andere E-Mail-Adresse an.',
  wrongPhoneNumber: 'Bitte prüfen Sie Ihre Telefonnummer.',
  unallowedCharacters: 'Das Feld enthält ein unerlaubtes Zeichen',
};

export interface Area {
  isValid: boolean;
  model: AreaDetails[];
  stringResult: null;
}

export interface AreaDetails {
  artID: number;
  ort: string;
  ortsteil: null;
  postleitzahl: string;
  strasse: null;
}

export interface AreaComponentProps {
  areaCollection: AreaDetails[] | null | undefined;
  area: string;
  setArea: React.Dispatch<React.SetStateAction<string>>;
  personalData?: PersonalData | null;
  customArea: boolean;
  setCustomArea: React.Dispatch<React.SetStateAction<boolean>>;
}

export type AreaBody = {
  Postleitzahl: string;
};

export interface AreaResponse {
  isValid: boolean;
  model: AreaModel[] | null;
  stringResult: null;
}

export interface AreaModel {
  artID: number;
  ort: string;
  ortsteil: null;
  postleitzahl: string;
  strasse: null;
}
