import { AxiosResponse } from 'axios';
import request from '../app/api/request';

export type PreInsuranceResponse = {
  name: string;
  number: string;
};

export const getPreInsurance = (input: string): Promise<AxiosResponse> => {
  return request.get(`/api/Vorversicherung/Autocomplete?term=${input}`);
};
