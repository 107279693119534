import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RechtsschutzAemAssets } from './aem.interface';

// Define a type for the slice state
export interface AemAssetsState {
  rechtsschutzAemAssets: RechtsschutzAemAssets;
  inAem: boolean;
  initialized: boolean;
  isEditMode: boolean;
}

// Define the initial state using that type
export const initialState: AemAssetsState = {
  rechtsschutzAemAssets: {},
  inAem: false,
  initialized: false,
  isEditMode: false,
};

export const aemAssetsSlice = createSlice({
  name: 'aemAssets',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    loadAemAssets: (state, action: PayloadAction<RechtsschutzAemAssets>) => {
      state.rechtsschutzAemAssets = action.payload;
      state.inAem = true;
    },
    aemLoadingComplete: (state) => {
      state.initialized = true;
    },
    setEditMode: (state) => {
      state.isEditMode = true;
    },
    resetAem: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { loadAemAssets, aemLoadingComplete, setEditMode, resetAem } = aemAssetsSlice.actions;

const aemAssetsReducer = aemAssetsSlice.reducer;
export default aemAssetsReducer;
