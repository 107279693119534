import { NODE_ENV } from './config';

export enum Environment {
  DEVELOPMENT = 'development',
  DVIT = 'dvit',
  FAIT = 'fait',
  ABN = 'abn',
  PRODUCTION = 'production',
}

export const isDevelopmentEnv = NODE_ENV === Environment.DEVELOPMENT;
export const appId = 'rs-otr';
