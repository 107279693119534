import Button from '@eg/elements/Button';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { appId } from '../../constants';
import { resetDownloadError, setDownloadError, setPersonalDataError, setPreInsuranceError } from '../../store/errors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { containsUnallowedCharacters } from '../../utils/helpers';
import { useKeyPress } from '../../utils/hooks';
import { createOfferRequestData } from '../../utils/offer-german-mutation';
import { aemTracking } from '../aem/aem-tracking';
import { RoutePath } from '../app/app.interface';
import { postOffer } from '../download/download.api';
import { SubmitOfferErrorMessages, SubmitOfferResponse } from '../download/download.interface';
import { PreInsuranceOptionLabel } from '../pre-insurance/pre-insurance.interface';
import { clickEventTrack } from '../tracking/tracking.slice';
import { changeLoading } from './navigation-button.slice';
import './navigation-buttons.scss';

export const NavigationButtons = function NavigationButtons(): JSX.Element {
  const offer = useAppSelector((state) => state.offer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isLoading,
    canGoFurther,
    goToNextUri,
    showButton,
    showBackButton,
    previousUri,
    isPreviousUriExternal,
    buttonLabel,
    backLinkLabel,
    jumpToContent,
  } = useAppSelector((state) => state.navigationButtons);
  const [submitError, setSubmitError] = useState(false);
  const enterPressed: boolean = useKeyPress('Enter');

  const handleSubmitError = (state: boolean) => {
    if (state) {
      setSubmitError(true);
      dispatch(setDownloadError('offerSubmitError'));
    } else {
      setSubmitError(false);
      dispatch(resetDownloadError('offerSubmitError'));
    }
  };

  const nextClicked = () => {
    handleSubmitError(false);
    if (location.pathname.includes(RoutePath.DOWNLOAD)) {
      dispatch(
        clickEventTrack({
          clickedElement: 'Button_OnlineAbschließen',
          timedCall: false,
        })
      );
      dispatch(changeLoading(true));
      postOffer(createOfferRequestData(offer))
        .then((response) => {
          const data = response.data as SubmitOfferResponse;
          dispatch(changeLoading(false));
          if (canGoFurther && response.status === 200 && data.stringResult && data.stringResult[0]) {
            navigate(goToNextUri);
          } else {
            handleSubmitError(true);
          }
        })
        .catch(() => {
          dispatch(changeLoading(false));
          handleSubmitError(true);
        });
    }
    if (canGoFurther && !location.pathname.includes(RoutePath.DOWNLOAD)) {
      navigate(goToNextUri);

      let label = '';
      switch (location.pathname) {
        case RoutePath.DOWNLOAD:
          label = 'Button_OnlineAbschließen';
          break;
        case RoutePath.TARIFF_DATA:
          label = 'Button_OnlineBeantragen';
          break;
        default:
          label = 'Button_Weiter';
      }
      dispatch(
        clickEventTrack({
          clickedElement: label,
          timedCall: false,
        })
      );
    }
  };

  const backButtonClicked = () => {
    handleSubmitError(false);
    navigate(previousUri);

    dispatch(
      clickEventTrack({
        clickedElement: `Button_Zurück`,
        timedCall: false,
      })
    );
  };

  const backLinkClicked = () => null;

  const handleErrors = () => {
    /** Personal Data Page */
    if (location.pathname.includes(RoutePath.PERSONAL_DATA)) {
      const personalData = { ...offer.personalData };

      /** Missing values */
      if (!personalData.salutation) dispatch(setPersonalDataError('missingSalutation'));
      if (!personalData.firstName) dispatch(setPersonalDataError('missingName'));
      if (!personalData.lastName) dispatch(setPersonalDataError('missingSurname'));
      if (!personalData.adress?.street) dispatch(setPersonalDataError('missingStreet'));
      if (!personalData.adress?.houseNumber) dispatch(setPersonalDataError('missingHouseNumber'));
      if (!personalData.adress?.postcode) dispatch(setPersonalDataError('missingPostcode'));
      if (!personalData.email) dispatch(setPersonalDataError('missingEmail'));

      /** Wrong values */
      if (
        (personalData.phonePrefix && personalData.phonePrefix.length < 3) ||
        (personalData.phoneNumber && personalData.phoneNumber.length < 3) ||
        (personalData.phonePrefix && !personalData.phoneNumber) ||
        (personalData.phoneNumber && !personalData.phonePrefix)
      ) {
        dispatch(setPersonalDataError('wrongPhoneNumber'));
      }
      if (personalData.adress?.postcode && personalData.adress?.postcode.length < 5) {
        dispatch(setPersonalDataError('invalidPostcode'));
      }
      /** Unallowed Characters */
      if (
        (personalData.firstName && containsUnallowedCharacters(personalData.firstName)) ||
        (personalData.lastName && containsUnallowedCharacters(personalData.lastName)) ||
        (personalData.adress && containsUnallowedCharacters(personalData.adress.street)) ||
        (personalData.adress && containsUnallowedCharacters(personalData.adress.houseNumber)) ||
        (personalData.adress && containsUnallowedCharacters(personalData.adress.postcode))
      )
        dispatch(setPersonalDataError('unallowedCharacters'));
    }
    /** Personal Data Page */

    /** Pre Insurance Page */
    if (location.pathname.includes(RoutePath.PRE_INSURANCE)) {
      const preInsurance = { ...offer.preInsurance };
      const { preInsuranceStatus } = offer;

      if (preInsuranceStatus === PreInsuranceOptionLabel.YES) {
        if (!preInsurance.expiration) dispatch(setPreInsuranceError('missingDate'));
        if (!preInsurance.name) dispatch(setPreInsuranceError('missingCompany'));
        if (!preInsurance.number) dispatch(setPreInsuranceError('missingNumber'));
        if (!preInsurance.quitter) dispatch(setPreInsuranceError('missingTerminated'));
      }
    }
    /** Pre Insurance Page */
  };

  const handleSubmit = () => {
    handleErrors();
    if (jumpToContent !== null) {
      document.querySelector(jumpToContent)?.scrollIntoView();
    } else {
      nextClicked();
      aemTracking.status.setIsFormSubmitted({ appId, isFormSubmitted: true });
    }
  };

  useEffect(() => {
    handleSubmitError(false);
  }, [location.pathname]);

  useEffect(() => {
    if (enterPressed && canGoFurther && !isLoading) handleSubmit();
  }, [enterPressed]);

  return (
    <>
      <div className="navigation-buttons">
        {showButton && (
          <div id="navigation-buttons_submit">
            <Button
              data-testid="navigation-submit-button"
              className="submit-button"
              type="button"
              variant="primary"
              size="large"
              onClick={handleSubmit}
              disabled={!canGoFurther || isLoading}
            >
              {buttonLabel}
            </Button>
          </div>
        )}

        {showBackButton ? (
          <div className="navigation-buttons__back-link-wrapper" id="navigation-buttons_back">
            {isPreviousUriExternal ? (
              <a
                data-testid="navigation-back-button"
                className="navigation-buttons__back-link"
                href={previousUri}
                onClick={() => backLinkClicked()}
              >
                {backLinkLabel}
              </a>
            ) : (
              <Button
                data-testid="navigation-back-button"
                className="navigation-buttons__back-link"
                onClick={() => backButtonClicked()}
                variant="text-link"
              >
                {backLinkLabel}
              </Button>
            )}
            {submitError && (
              <p className="navigation-buttons__error">
                <b>Entschuldigung</b>. {SubmitOfferErrorMessages.offerSubmitError}
              </p>
            )}
          </div>
        ) : null}
      </div>
      <LoadingSpinner show={isLoading} />
    </>
  );
};
