import { RoutePath } from '../app/app.interface';

type NavigationRouteType = Partial<Record<string, number>>;
export const NavigationPathsIndexes: NavigationRouteType = {
  [RoutePath.ROOT]: 1,
  [RoutePath.MARITAL_STATUS]: 1,
  [RoutePath.EMPLOYMENT]: 2,
  [RoutePath.EMPLOYMENT_SITUATION]: 2,
  [RoutePath.INSURANCE_START]: 3,
  [RoutePath.BIRTHDATE]: 4,
  [RoutePath.TARIFF_DATA]: 5,
  [RoutePath.PERSONAL_DATA]: 6,
  [RoutePath.PRE_INSURANCE]: 7,
  [RoutePath.TARIFF_OVERVIEW]: 8,
  [RoutePath.PAYMENT]: 9,
  [RoutePath.DOWNLOAD]: 10,
  [RoutePath.THANK_YOU]: 11,
};
