import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigationButtonsDirectionProps } from './navigation-buttons.interface';

// Define a type for the slice state
export interface NavigationButtonState {
  canGoFurther: boolean;
  goToNextUri: string;
  previousUri: string;
  isPreviousUriExternal?: boolean;
  buttonLabel: string;
  showButton: boolean;
  showBackButton: boolean;
  backLinkLabel: string;
  isLoading: boolean;
  jumpToContent: string | null;
  spacingExists: boolean;
}

// Define the initial state using that type
export const initialState: NavigationButtonState = {
  canGoFurther: false,
  previousUri: '',
  isPreviousUriExternal: false,
  goToNextUri: '',
  buttonLabel: 'weiter',
  showButton: true,
  backLinkLabel: 'zurück',
  isLoading: false,
  showBackButton: false,
  jumpToContent: null,
  spacingExists: true,
};

export const navigationButtonSlice = createSlice({
  name: 'navigationButton',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    changeLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    canGoToNextStep: (state, action: PayloadAction<boolean>) => {
      state.canGoFurther = action.payload;
    },
    setButtonLabel: (state, action: PayloadAction<string>) => {
      state.buttonLabel = action.payload;
    },
    changeShowButton: (state, action: PayloadAction<boolean>) => {
      state.showButton = action.payload;
    },
    changeShowBackButton: (state, action: PayloadAction<boolean>) => {
      state.showBackButton = action.payload;
    },
    setDirectionsState: (state, action: PayloadAction<NavigationButtonsDirectionProps>) => {
      state.canGoFurther = action.payload.canGoFurther;
      state.backLinkLabel = action.payload.backLinkLabel || 'zurück';
      state.previousUri = action.payload.previousStep;
      state.showBackButton = action.payload.showBackButton || false;
      state.goToNextUri = action.payload.nextStep;
      state.isPreviousUriExternal = action.payload.isPreviousUriExternal || false;
      state.jumpToContent = action.payload.jumpToContent || null;
    },
    setButtonSpacing: (state, action: PayloadAction<boolean>) => {
      state.spacingExists = action.payload;
    },
    resetNavigation: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  changeLoading,
  canGoToNextStep,
  setButtonLabel,
  changeShowButton,
  changeShowBackButton,
  setDirectionsState,
  setButtonSpacing,
  resetNavigation,
} = navigationButtonSlice.actions;

const navigationButtonReducer = navigationButtonSlice.reducer;
export default navigationButtonReducer;
