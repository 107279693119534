import { DateInputValue } from '@eg/elements/DateInput';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Agent } from '../components/download/download.interface';
import {
  EmployedSituationOptionLabel,
  UnemployedSituationOptionLabel,
} from '../components/employment-situation/employment-situation.interface';
import { EmploymentStatusOptionLabel } from '../components/employment/employment.interface';
import { RsMaritalStatus } from '../components/marital-status/marital-status.interface';
import { Payement } from '../components/payment/payement.interface';
import { PersonalData } from '../components/personal-data/personal-data.interface';
import { PreInsurance, PreInsuranceOptionLabel } from '../components/pre-insurance/pre-insurance.interface';

export interface OfferState {
  maritalStatus: RsMaritalStatus | null;
  insuranceStartDate: string | null;
  birthdate: string | null;
  preInsuranceStatus: PreInsuranceOptionLabel | null;
  preInsurance: PreInsurance | null;
  employmentStatus: EmploymentStatusOptionLabel | null;
  employmentSituation: UnemployedSituationOptionLabel | EmployedSituationOptionLabel | null;
  personalData: PersonalData | null;
  duration: number;
  contribution: number;
  interval: number;
  payment: {
    bankName: string | null;
    iban: string | null;
    bic: string | null;
  };
  // Aspects that should be covered by RS
  rsPrivate: boolean;
  rsEmployment: boolean;
  rsTraffic: boolean;
  rsHouse: boolean;
  variant: string;
  youngDiscount: boolean;
  price: number | undefined;
  priceYearNetto: number | undefined;
  iddSteps: IDDSteps;
  agent: Agent;
}

interface Tarif {
  name: string;
  value: boolean;
}

interface IDDSteps {
  disableStepOne: boolean;
  checkStepOne: boolean;
}

export const initialState: OfferState = {
  maritalStatus: null,
  insuranceStartDate: null,
  birthdate: null,
  preInsuranceStatus: null,
  preInsurance: null,
  employmentStatus: null,
  employmentSituation: null,
  payment: {
    bankName: null,
    iban: null,
    bic: null,
  },
  personalData: null,
  rsPrivate: true,
  rsEmployment: false,
  rsTraffic: false,
  rsHouse: false,
  duration: 1,
  contribution: 150,
  interval: 12,
  variant: 'best',
  youngDiscount: false,
  price: undefined,
  priceYearNetto: undefined,
  iddSteps: {
    checkStepOne: false,
    disableStepOne: true,
  },
  agent: {
    code: '',
    useVermittler: false,
    pnr: null,
    oeNr: null,
    vorname: null,
    nachname: null,
    anrede: null,
    tel: null,
    fax: null,
    email: null,
    internet: null,
    mobileNr: null,
    regNr: null,
    strasse: null,
    plz: null,
    ort: null,
    isVermittlerMode: false,
  },
};

export type QueryParams = {
  pnr: string | null;
  oenr: string | null;
  vorname: string | null;
  nachname: string | null;
  anrede: string | null;
  tel: string | null;
  fax: string | null;
  mail: string | null;
  internet: string | null;
  mobil: string | null;
  strasse: string | null;
  plz: string | null;
  ort: string | null;
  regnr: string | null;
};

export const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    changeMaritalStatus: (state, action: PayloadAction<RsMaritalStatus>) => {
      state.maritalStatus = action.payload;
    },
    changeStartDate: (state, action: PayloadAction<DateInputValue>) => {
      state.insuranceStartDate = `${action.payload.day}.${action.payload.month}.${action.payload.year}`;
    },
    resetStartDate: (state) => {
      state.insuranceStartDate = null;
    },
    changeBirthdate: (state, action: PayloadAction<DateInputValue>) => {
      state.birthdate = `${action.payload.day}.${action.payload.month}.${action.payload.year}`;
    },
    changeEmploymentStatus: (state, action: PayloadAction<EmploymentStatusOptionLabel>) => {
      if (state.employmentStatus !== action.payload) state.employmentSituation = null;
      state.employmentStatus = action.payload;
    },
    changePreInsuranceStartDate: (state, action: PayloadAction<DateInputValue>) => {
      if (state.preInsurance)
        state.preInsurance.expiration = `${action.payload.day}.${action.payload.month}.${action.payload.year}`;
    },
    changePreInsuranceStatus: (state, action: PayloadAction<PreInsuranceOptionLabel>) => {
      state.preInsuranceStatus = action.payload;
    },
    changePreInsurance: (state, action: PayloadAction<PreInsurance>) => {
      state.preInsurance = action.payload;
    },
    initializePreInsurance: (state) => {
      state.preInsurance = initialState.preInsurance;
    },
    changePrice: (state, action: PayloadAction<{ price: number; priceYearNetto: number }>) => {
      state.price = action.payload.price;
      state.priceYearNetto = action.payload.priceYearNetto;
    },
    changeYoungDiscount: (state, action: PayloadAction<boolean>) => {
      state.youngDiscount = action.payload;
    },
    changePersonalData: (state, action: PayloadAction<PersonalData>) => {
      state.personalData = action.payload;
    },
    changeVariant: (state, action: PayloadAction<string>) => {
      state.variant = action.payload;
      if (action.payload === 'smart') {
        if (state.contribution === 500) {
          state.contribution = 150;
        }
      }
    },
    changeDuration: (state, action: PayloadAction<number>) => {
      state.duration = action.payload;
    },
    changeContribution: (state, action: PayloadAction<number>) => {
      state.contribution = action.payload;
    },
    changeInterval: (state, action: PayloadAction<number>) => {
      state.interval = action.payload;
    },
    changeEmploymentSituation: (
      state,
      action: PayloadAction<EmployedSituationOptionLabel | UnemployedSituationOptionLabel | null>
    ) => {
      state.employmentSituation = action.payload;
      if (
        action.payload === EmployedSituationOptionLabel.EMPLOYEE ||
        action.payload === EmployedSituationOptionLabel.OFFICAL
      )
        state.rsEmployment = true;
      else state.rsEmployment = false;
    },
    changeTarif: (state, action: PayloadAction<Tarif>) => {
      switch (action.payload.name) {
        case 'private':
          state.rsPrivate = action.payload.value;
          if (action.payload.value === false) {
            state.rsEmployment = false;
            state.rsHouse = false;
          }
          break;
        case 'employment':
          state.rsEmployment = action.payload.value;
          break;
        case 'traffic':
          state.rsTraffic = action.payload.value;
          break;
        case 'house':
          state.rsHouse = action.payload.value;
          break;
        default:
      }
    },
    changePayment: (state, action: PayloadAction<Payement>) => {
      state.payment = action.payload;
    },
    changeIDDSteps: (state, action: PayloadAction<IDDSteps>) => {
      state.iddSteps = action.payload;
    },

    changeAgent: (state, action: PayloadAction<QueryParams>) => {
      const { pnr, oenr, vorname, nachname, anrede, tel, fax, mail, internet, mobil, strasse, plz, ort, regnr } =
        action.payload;
      const agent = {
        code: '',
        useVermittler: true,
        pnr,
        oeNr: oenr,
        vorname,
        nachname,
        anrede,
        tel,
        fax,
        email: mail,
        internet,
        mobileNr: mobil,
        strasse,
        plz,
        ort,
        regNr: regnr,
        isVermittlerMode: true,
      };

      return { ...state, agent };
    },
    resetOffer: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  changeMaritalStatus,
  changeStartDate,
  resetStartDate,
  changeBirthdate,
  changePreInsuranceStatus,
  changePreInsuranceStartDate,
  changePreInsurance,
  initializePreInsurance,
  changeEmploymentStatus,
  changeEmploymentSituation,
  changePersonalData,
  changeTarif,
  changeContribution,
  changeDuration,
  changeInterval,
  changePrice,
  changeYoungDiscount,
  changeVariant,
  changePayment,
  changeIDDSteps,
  changeAgent,
  resetOffer,
} = offerSlice.actions;

const offerReducer = offerSlice.reducer;
export default offerReducer;
