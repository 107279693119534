import { DateInputValue } from '@eg/elements/DateInput';

export interface InsuranceStartDateOption {
  label?: string;
  trackingLabel?: InsuranceStartTrackingLabel;
  shortDate?: string;
  longDate?: string;
  value: string;
}

export enum InsuranceStartDateOptionLabel {
  TOMORROW = 'Morgen',
  NEXT_MONTH = 'Nächsten Monat',
  OTHER_DATE = 'Anderes Datum',
}

export enum InsuranceStartTrackingLabel {
  TOMORROW = 'Morgen',
  NEXT_MONTH = 'NaechstenMonat',
  OTHER_DATE = 'AnderesDatum',
}

export interface RsInsuranceStart {
  value: DateInputValue;
  errorMessages: {
    badInput?: string | undefined;
    notAdult?: string | undefined;
    rangeOverflow?: string | undefined;
    rangeUnderflow?: string | undefined;
    valueMissing?: string | undefined;
    errorMessage?: string | undefined;
  } | null;
  valid: boolean;
  touched: boolean;
}
