import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { aemTracking } from '../aem/aem-tracking';
import { AemWindow } from '../aem/aem.interface';
import {
  AdobeAnalyticsSysEnv,
  PageClickTrackProps,
  PageLoadTrackProps,
  TariffOptions,
  TrackingBasic,
  TrackingError,
  TrackingErrorProps,
  TransactionAttributes,
  UserAttributes,
} from './tracking.interface';

export interface InitialProps {
  trackingInformation: TrackingBasic;
  tariffOptions: TariffOptions;
}

// Define the initial state using that type
export const initialState: InitialProps = {
  trackingInformation: {
    event: '',
    eventdetails: {},
    page: {
      pageInfo: { sysEnv: AdobeAnalyticsSysEnv.PROD, issueDate: '' },
      attributes: { displayedBrand: '', brokerModus: 'Off' },
      category: { pagePurpose: '', primaryCategory: '' },
    },
    product: [
      {
        productInfo: { productName: '' },
        category: { primaryCategory: '' },

        attributes: {
          productCombination: '',
          tariffOptions1: '',
          insuranceStart: '',
        },
      },
    ],
    transaction: {
      transactionID: '',
      attributes: {},
    },
    user: [{ profile: { attributes: { jobTitle: '', birthday: '', gender: '' } } }],
  },
  tariffOptions: { Rechner: 'Rechtsschutz2022' },
};

export const trackingSlice = createSlice({
  name: 'tracking',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    initTrack: (state, action: PayloadAction<TrackingBasic>) => {
      state.trackingInformation.page = action.payload.page;
      state.trackingInformation.product = action.payload.product;
      state.trackingInformation.transaction = action.payload.transaction;
      const aemWindow = window as unknown as AemWindow;
      aemWindow.transactionID = action.payload.transaction.transactionID;
    },
    setTrackingBirthday: (state, action: PayloadAction<string>) => {
      if (
        state.trackingInformation.user &&
        state.trackingInformation.user[0] &&
        state.trackingInformation.user[0].profile.attributes
      )
        state.trackingInformation.user[0].profile.attributes.birthday = action.payload;
      else {
        state.trackingInformation.user = [
          {
            profile: {
              attributes: {
                birthday: action.payload,
              },
            },
          },
        ];
      }
    },
    setTrackingInsuranceStart: (state, action: PayloadAction<string>) => {
      state.trackingInformation.product[0].attributes.insuranceStart = action.payload;
    },
    setProductCombination: (state, action: PayloadAction<string>) => {
      state.trackingInformation.product[0].attributes.productCombination = action.payload;
    },
    setTariffOptions: (state, action: PayloadAction<TariffOptions>) => {
      Object.keys(action.payload).map((key) => {
        state.tariffOptions[key] = action.payload[key];
        return key;
      });
    },
    addUserAttribute: (state, action: PayloadAction<UserAttributes>) => {
      if (
        state.trackingInformation.user &&
        state.trackingInformation.user[0] &&
        state.trackingInformation.user[0].profile.attributes
      ) {
        const attributes = {
          ...state.trackingInformation.user[0].profile.attributes,
          ...action.payload,
        };
        state.trackingInformation.user[0].profile.attributes = attributes;
      } else {
        state.trackingInformation.user = [
          {
            profile: { attributes: { ...action.payload } },
          },
        ];
      }
    },
    addTransactionAttribute: (state, action: PayloadAction<TransactionAttributes>) => {
      if (state.trackingInformation.transaction.attributes) {
        const attributes = {
          ...state.trackingInformation.transaction.attributes,
          ...action.payload,
        };
        state.trackingInformation.transaction.attributes = attributes;
      } else {
        state.trackingInformation.transaction.attributes = {
          ...action.payload,
        };
      }
    },
    addUserAddress: (state, action: PayloadAction<string>) => {
      if (state.trackingInformation.user && state.trackingInformation.user[0])
        state.trackingInformation.user[0].profile.address = { postalCode: action.payload };
    },

    errorTrack: (state, action: PayloadAction<TrackingErrorProps>) => {
      const error: TrackingError = {
        ...state.trackingInformation,
        event: 'GeneralClick',
        miscellaneous: { errors: { errorFlag: true, ...action.payload } },
        transaction: { transactionID: state.trackingInformation.transaction.transactionID },
        eventdetails: { clickType: 'other', timedCall: true },
      };
      // init carbon data layer array if not already present
      const trackingWindow = window as unknown as AemWindow;
      trackingWindow.appEventData = trackingWindow.appEventData || [];

      // tracking call for error
      aemTracking.push(error);
      // trackingWindow.appEventData.push(error);
    },

    pageLoadTrack: (state, action: PayloadAction<PageLoadTrackProps>) => {
      if (action.payload.tariffOptions) {
        Object.keys(action.payload.tariffOptions).map((key) => {
          if (action.payload.tariffOptions) state.tariffOptions[key] = action.payload.tariffOptions[key];
          return key;
        });
      }

      const trackingData = cloneDeep(state.trackingInformation);
      trackingData.event = 'PageLoaded';
      if (trackingData.eventdetails) trackingData.eventdetails.timedCall = true;
      trackingData.page.pageInfo.pageName = action.payload.viewName;
      if (action.payload.transactionAttributes) {
        trackingData.transaction.attributes = {
          ...trackingData.transaction.attributes,
          ...action.payload.transactionAttributes,
        };
      }

      if (action.payload.transactionTotal) {
        trackingData.transaction.total = action.payload.transactionTotal;
      }

      // init carbon data layer array if not already present
      const trackingWindow = window as unknown as AemWindow;
      trackingWindow.appEventData = trackingWindow.appEventData || [];

      // tracking call for pageloads
      const tariffOptions = Object.keys(state.tariffOptions).map((key) => `${key}=${state.tariffOptions[key]}`);
      const tariffOptionsString = tariffOptions.join('|');
      if (state.trackingInformation.product[0]) {
        trackingData.product[0].attributes.tariffOptions1 = tariffOptionsString;
        state.trackingInformation.product[0].attributes.tariffOptions1 = tariffOptionsString;
      }
      // trackingWindow.appEventData.push(trackingData);
      aemTracking.push(trackingData);
    },
    clickEventTrack: (state, action: PayloadAction<PageClickTrackProps>) => {
      if (action.payload.tariffOptions) {
        Object.keys(action.payload.tariffOptions).map((key) => {
          if (action.payload.tariffOptions) state.tariffOptions[key] = action.payload.tariffOptions[key];
          return key;
        });
      }

      const trackingData = cloneDeep(state.trackingInformation);
      trackingData.event = 'GeneralClick';
      trackingData.eventdetails = {
        clickedElement: action.payload.clickedElement,
        clickType: 'other',
        timedCall: action.payload.timedCall,
      };

      // init carbon data layer array if not already present
      const trackingWindow = window as unknown as AemWindow;
      trackingWindow.appEventData = trackingWindow.appEventData || [];

      // tracking call for clicks
      const tariffOptions = Object.keys(state.tariffOptions).map((key) => `${key}=${state.tariffOptions[key]}`);
      const tariffOptionsString = tariffOptions.join('|');
      if (state.trackingInformation.product[0]) {
        trackingData.product[0].attributes.tariffOptions1 = tariffOptionsString;
        state.trackingInformation.product[0].attributes.tariffOptions1 = tariffOptionsString;
      }
      aemTracking.push(trackingData);
      // trackingWindow.appEventData.push(trackingData);
    },
    changeBrokerMode: (state, action: PayloadAction<string>) => {
      state.trackingInformation.page.attributes.brokerModus = action.payload;
    },
    resetTracking: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  initTrack,
  pageLoadTrack,
  clickEventTrack,
  setTrackingBirthday,
  setTrackingInsuranceStart,
  setTariffOptions,
  addUserAttribute,
  addUserAddress,
  errorTrack,
  setProductCombination,
  resetTracking,
  changeBrokerMode,
  addTransactionAttribute,
} = trackingSlice.actions;

const trackingReducer = trackingSlice.reducer;
export default trackingReducer;
