import { AxiosResponse } from 'axios';
import { getXfUrlByAemProperty, noCacheHeaders } from '../aem/aemHelper';
import request from '../app/api/request';

export const getAemXf = (url: string, isEditMode: boolean): Promise<AxiosResponse> => {
  if (url)
    return request.get(getXfUrlByAemProperty(url, isEditMode), {
      headers: noCacheHeaders,
    });
  throw new Error('No Content Provided');
};
