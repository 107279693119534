import DateInput from '@eg/elements/DateInput';
import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import TooltipIcon from '@eg/elements/TooltipIcon';
import { useEffect, useState } from 'react';
import { resetBirthdateError, setBirthdateError } from '../../store/errors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeBirthdate, changeYoungDiscount } from '../../store/offer';
import {
  DateErrorMessages,
  DatePickerErrorMessages,
  deMoment,
  isAdult,
  isInTheFuture,
  isProfitGroup,
} from '../../utils/date-configurations';
import { validateNumberInput } from '../../utils/helpers';
import { RoutePath } from '../app/app.interface';
import { UnemployedSituationOptionLabel } from '../employment-situation/employment-situation.interface';
import { setDirectionsState } from '../navigation-buttons/navigation-button.slice';
import { clickEventTrack, pageLoadTrack, setTrackingBirthday } from '../tracking/tracking.slice';
import './birthdate.component.scss';
import { BirthdateComponentState, ErrorMessages } from './birthdate.interface';
import { BIRTHDATE_TOOLTIP } from './birthdate.tooltip';

export const BirthdateComponent = function BirthdateComponent(): JSX.Element {
  const minUserAge = deMoment().add(-130, 'y');
  const dispatch = useAppDispatch();
  const insuranceStartDate = useAppSelector((state) => state.offer.insuranceStartDate);
  const offer = useAppSelector((state) => state.offer);
  const aem = useAppSelector((state) => state.aem);
  const birthdate = useAppSelector((state) => state.offer.birthdate);
  const [touched, setTouched] = useState(false);
  const initialState = {
    value: {
      day: birthdate ? birthdate.split('.')[0] : '',
      month: birthdate ? birthdate.split('.')[1] : '',
      year: birthdate ? birthdate.split('.')[2] : '',
    },
    errorMessages: null,
    valid: true,
    touched,
  };
  const [input, setInput] = useState<BirthdateComponentState>(initialState);

  useEffect(() => {
    dispatch(
      setDirectionsState({
        backLinkLabel: 'zurück',
        canGoFurther: input.valid && !!birthdate,
        previousStep: RoutePath.INSURANCE_START,
        nextStep: RoutePath.TARIFF_DATA,
        showBackButton: true,
      })
    );
  });

  useEffect(() => {
    dispatch(
      pageLoadTrack({
        viewName:
          `${aem.rechtsschutzAemAssets.trackingShortName}:${aem.rechtsschutzAemAssets.trackingViewNameBirthdate}` || '',
      })
    );
  }, []);

  function setBirthdate(newBirthdate: BirthdateComponentState) {
    setInput(newBirthdate);

    if (newBirthdate.valid) {
      dispatch(setTrackingBirthday(`${newBirthdate.value.day}.${newBirthdate.value.month}.${newBirthdate.value.year}`));
    }

    dispatch(resetBirthdateError());
    if (newBirthdate.valid && isAdult(newBirthdate.value)) {
      dispatch(changeBirthdate(newBirthdate.value));
    }

    if (newBirthdate.valid && !isAdult(newBirthdate.value)) {
      setInput({
        ...newBirthdate,
        valid: false,
        errorMessages: { notAdult: DatePickerErrorMessages.notAdult },
      });
      if (isInTheFuture(newBirthdate.value)) {
        setInput({
          ...newBirthdate,
          valid: false,
          errorMessages: { badInput: DatePickerErrorMessages.badInput },
        });
      }
    }

    if (!newBirthdate.valid && newBirthdate.errorMessages) {
      const errorType = Object.keys(newBirthdate.errorMessages)[0] as DateErrorMessages;
      if (input.value && input.value.year && input.value.year.length === 4 && errorType === 'rangeUnderflow') {
        setInput({
          ...newBirthdate,
          valid: false,
          errorMessages: { badInput: DatePickerErrorMessages.badInput },
        });
      } else if (input.value && input.value.year && input.value.year.length < 4) {
        setInput({
          ...newBirthdate,
          valid: false,
          errorMessages: { valueMissing: DatePickerErrorMessages.valueMissing },
        });
      } else {
        setInput({
          ...newBirthdate,
          valid: false,
          errorMessages: { [errorType as DateErrorMessages]: DatePickerErrorMessages[errorType] },
        });
      }
    }
  }

  function listErrors(errors: ErrorMessages) {
    const keys = errors && Object.keys(errors);

    return (
      <div>
        {keys &&
          keys.map((key) => (
            <p key={key} className="birthday-date__error">
              {errors[key]}
            </p>
          ))}
      </div>
    );
  }

  if (
    birthdate &&
    insuranceStartDate &&
    input.valid &&
    isProfitGroup(birthdate, insuranceStartDate) &&
    offer.employmentSituation !== UnemployedSituationOptionLabel.OFFICAL_RETIREMENT &&
    offer.employmentSituation !== UnemployedSituationOptionLabel.RETIREMENT
  ) {
    dispatch(changeYoungDiscount(true));
  } else {
    dispatch(changeYoungDiscount(false));
  }

  return (
    <div className="birthday-date__container">
      <div id="birthday-date_header">
        <div className="page__title">Geben Sie Ihr Geburtsdatum ein.</div>
        <div className="page__subtitle">
          <p>Ihr Alter ist wichtig für die Berechnung Ihres Beitrags.</p>
          <TooltipIcon
            isModal
            onToggledOpen={(isOpen) => {
              if (isOpen)
                dispatch(
                  clickEventTrack({
                    clickedElement: 'InfoIcon_GebDatum',
                    timedCall: true,
                  })
                );
            }}
          >
            {BIRTHDATE_TOOLTIP}
          </TooltipIcon>
        </div>
      </div>
      <div className="birthday-date__input" id="birthday-date_input">
        <div className="birthday-date__input-datepicker">
          <DateInput
            id="birthday-date-input"
            data-testid="birthday-date-input"
            autoTab
            value={input.value}
            minDate={minUserAge.toDate()}
            error={!input.valid && touched}
            onKeyPress={(e) => validateNumberInput(e)}
            onBlur={() => {
              setTouched(true);
              if (!input.valid && input.errorMessages) {
                const errorType = Object.keys(input.errorMessages)[0] as DateErrorMessages;
                dispatch(setBirthdateError(errorType));
              }
            }}
            onChange={(value, errors) => {
              const { valid, ...otherErrors } = errors;
              const removeLeadingZerosValue = {
                day: value.day,
                month: value.month,
                year: value.year && value.year.replace(/^0+/, ''),
              };
              setTouched(false);
              setBirthdate({
                ...input,
                value: removeLeadingZerosValue,
                errorMessages: otherErrors,
                valid: removeLeadingZerosValue.year && removeLeadingZerosValue.year.length < 4 ? false : valid,
              });
            }}
          />
          {!input.valid && touched && input.errorMessages && listErrors(input.errorMessages)}
        </div>
      </div>

      {birthdate && insuranceStartDate && input.valid && isProfitGroup(birthdate, insuranceStartDate) && (
        <div>
          {offer.employmentSituation === UnemployedSituationOptionLabel.OFFICAL_RETIREMENT ||
          offer.employmentSituation === UnemployedSituationOptionLabel.RETIREMENT ? (
            <div className="birthday-date__info-text">
              <p>
                Sie profitieren vom günstigen Rechtsschutz Vital. Dieser kann nicht mit dem Startbonus kombiniert
                werden.
              </p>
            </div>
          ) : (
            <div className="birthday-date__info-text">
              <CheckColorIcon className="birthday-date__profit-info-icon" />
              <p>
                Sie erhalten als Startbonus <b>10 % Nachlass</b> auf den Gesamtbeitrag.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
