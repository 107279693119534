import * as React from 'react';

export const InsuranceAmountTooltip = (
  <div className="tooltip">
    <h3>Versicherungssumme</h3>

    <p>
      Die Versicherungssumme gibt an, bis zu welcher Höhe ERGO innerhalb des Geltungsbereichs die gesetzlichen Gebühren
      übernimmt für:
    </p>
    <ul>
      <li>Anwälte</li>
      <li>Gericht</li>
      <li>Zeugen</li>
      <li>Sachverständige</li>
      <li>Außergerichtliche Streitschlichtungsverfahren</li>
    </ul>
  </div>
);

export const ContractInsuranceTooltip = (
  <div className="tooltip">
    <h3>Rechtsschutz im Vertrags- und Sachenrecht</h3>

    <p>Hilft Ihnen, Ihre rechtlichen Interessen aus Verträgen des täglichen Lebens durchzusetzen, z. B. aus:</p>
    <ul>
      <li>Kaufverträgen</li>
      <li>Werkverträgen</li>
      <li>Reparaturverträgen</li>
    </ul>
    <p>
      <strong>Weltweiter Rechtsschutz</strong> für über das Internet abgeschlossene Verträge.
    </p>
    <p>
      <strong>Zusätzlich in Best:</strong> Auch Streitigkeiten aus Kapitalanlagegeschäften (z. B. Aktien und Anleihen)
      sind bis zu einem Anlagebetrag von insgesamt 50.000 € abgesichert (pro Anlageposition).
    </p>
  </div>
);

export const CyberInsuranceTooltip = (
  <div className="tooltip">
    <h3>Cyber-/Urheber-Rechtsschutz und Phishing</h3>
    <ul>
      <li>
        <strong>Cyber-Rechtsschutz</strong>
        <p>Hilft Ihnen, wenn Sie von einer Cyber-Attacke oder von Cyber-Mobbing betroffen sind.</p>
      </li>
    </ul>

    <strong>Zusätzlich in Best:</strong>

    <ul>
      <li>
        <strong>Rechtsschutz bei Urheberrechtsverletzungen im Internet</strong>
        <p>
          Hilft Ihnen, sich gegen den Vorwurf zu wehren, bei der privaten Nutzung des Internets gegen das Urheberrecht
          verstoßen zu haben, z. B. durch illegale Musikdownloads.
        </p>
      </li>
      <li>
        <strong>Phishing</strong>
        <p>Hilft Ihnen beim Schutz Ihrer Identität bei der privaten Nutzung des Internets.</p>
      </li>
    </ul>
  </div>
);

export const LastWillTooltip = (
  <div className="tooltip">
    <h3>Vorsorgeverfügungen bzw. Testament</h3>
    <p>
      Der ERGO Rechtsschutz Leistungsservice vermittelt Ihnen einen Rechtsanwalt, der Ihnen beim Erstellen oder Ändern
      folgender Dokumente hilft:
    </p>
    <ul>
      <li>Vorsorgevollmacht</li>
      <li>Betreuungsverfügung</li>
      <li>Patientenverfügung</li>
      <li>Sorgerechtsverfügung</li>
    </ul>
  </div>
);

export const SpecialInsuranceTooltip = (
  <div className="tooltip">
    <h3>Spezial-Straf-Rechtsschutz</h3>
    <p>
      Mit erweitertem Versicherungsschutz für den gewählten Bereich (Privat/Beruf) sowie für die Ausübung ehrenamtlicher
      Tätigkeiten. Auch zur Verteidigung gegen den Vorwurf eines nur vorsätzlich begehbaren Vergehens.
      <br />
      Versicherungsschutz besteht auch in unmittelbarem Zusammenhang mit der Nutzung elektronischer Daten bzw. Medien
      (z. B. Internet, Intranet, digitale Kurznachrichten- oder Messengerdienste).
    </p>
  </div>
);

export const PrivatTooltip = (
  <div className="tooltip">
    <h3>
      <strong>Privat</strong>
    </h3>
    <p>Abgesichert sind z. B. Streitigkeiten im Zusammenhang mit:</p>
    <ul>
      <li>Kauf- oder Reparaturverträgen</li>
      <li>Ihren Schadensersatzforderungen </li>
      <li>Ihrer Einkommenssteuer</li>
      <li>Sozialrechtlichen Ansprüchen</li>
    </ul>
  </div>
);

export const EmploymentTooltip = (
  <div className="tooltip">
    <h3>
      <strong>Beruf</strong>
    </h3>
    <p>Bietet Schutz bei arbeitsrechtlichen Streitigkeiten, z. B. wegen:</p>
    <ul>
      <li>Abmahnung</li>
      <li>Kündigung</li>
      <li>Zeugniserteilung</li>
      <li>Arbeitszeitfragen</li>
      <li>Versetzung</li>
      <li>Urlaubsanspruch</li>
    </ul>
  </div>
);

export const HouseTooltip = (
  <div className="tooltip">
    <h3>
      <strong>Wohnen</strong>
    </h3>
    <p>
      Bietet Schutz bei Streitigkeiten im Zusammenhang mit Ihrer selbst bewohnten Einheit, z. B. mit Ihren Nachbarn oder
      Ihrem Vermieter wegen
    </p>
    <ul>
      <li>Kaution</li>
      <li>Mieterhöhung</li>
      <li>Nebenkosten</li>
      <li>Kündigung</li>
      <li>Räumung</li>
    </ul>
  </div>
);

export const TrafficTooltip = (
  <div className="tooltip">
    <h3>
      <strong>Verkehr</strong>
    </h3>
    <p>Abgesichert sind z. B. Streitigkeiten im Zusammenhang mit:</p>
    <ul>
      <li>Verkehrsunfall</li>
      <li>Fahrzeugkauf</li>
      <li>Reparaturen</li>
      <li>Führerscheinentzug</li>
    </ul>
  </div>
);

export const PaymentTooltip = (
  <div className="tooltip">
    <h3>
      <strong>Zahlungsweise</strong>
    </h3>
    <p>Je nach Zahlungsweise enthält Ihr Beitrag diese Ratenzahlungszuschläge:</p>
    <ul>
      <li>Monatlich: 6 %</li>
      <li>Vierteljährlich: 5 %</li>
      <li>Halbjährlich: 3 %</li>
      <li>Jährlich: kein Zuschlag</li>
    </ul>
  </div>
);

export const ContributionTooltip = (
  <div className="tooltip">
    <h3>
      <strong>Selbstbeteiligung</strong>
    </h3>
    <p>
      Die Selbstbeteiligung (SB) ist der <b>Eigenanteil</b>, den Sie als Versicherungsnehmer in jedem Versicherungsfall
      tragen. Je höher die SB, desto geringer ist i. d. R. Ihr Beitrag.
    </p>

    <p>
      Mögliche SB im ERGO Rechtsschutz <b>Smart</b>: <br /> 150 € / 250 €
    </p>
    <p>
      Mögliche SB im ERGO Rechtsschutz <b>Best</b>: <br />
      150 € / 250 € / 500 €
    </p>

    <p>
      <b>Tipp</b>: Sie haben schon eine Rechtsschutzversicherung (Vorversicherung) – egal, ob beim ERGO Rechtsschutz
      oder anderswo – und haben <b>in den letzten Jahren keine Leistungen in Anspruch genommen</b>? Legen Sie in diesem
      Fall den <b>Versicherungsbeginn</b> dieses ERGO Rechtsschutzes{' '}
      <b>auf den Tag, an dem Ihre Vorversicherung endet.</b> Dann profitieren Sie im Leistungsfall wie folgt:
    </p>
    <p>
      Sie haben mindestens <strong>die letzten 3 Jahre</strong> beim Vorversicherer keine Leistungen in Anspruch
      genommen? Dann <strong>halbiert</strong> ERGO Ihre gewählte <strong>Selbstbeteiligung</strong> bei Ihrem 1.
      Leistungsfall.
    </p>
    <p>
      Sie haben mindestens <strong>die letzten 5 Jahre</strong> beim Vorversicherer keine Leistungen in Anspruch
      genommen? Dann <strong>verzichtet</strong> ERGO bei Ihrem 1. Leistungsfall sogar ganz auf die vereinbarte{' '}
      <strong>Selbstbeteiligung.</strong>
    </p>
  </div>
);

export const DurationTooltip = (
  <div className="tooltip">
    <h3>
      <strong>Vertragsdauer</strong>
    </h3>
    <p>
      <strong>1 Jahr</strong>
      <br />
      Der <strong>Vertrag verlängert sich</strong> nach Ablauf der Vertragsdauer <strong>stillschweigend</strong> von
      Jahr zu Jahr.
      <br />
      Ausnahme: Dem anderen Vertragspartner ist <strong>spätestens 3 Monate</strong> vor dem jeweiligen Ablauf eine
      Kündigung zugegangen.
    </p>
    <p>
      <strong>3 Jahre</strong>
      <br />
      Entscheiden Sie sich für eine 3-jährige Vertragsdauer, erhalten Sie <strong>10 % Nachlass</strong> auf Ihren
      Nettobeitrag.
    </p>
    <p>
      Bei einer <strong>Vertragsdauer ab 3 Jahren</strong> können Sie den Vertrag zum Schluss des 3. oder jedes
      folgenden Jahres kündigen, wenn Sie eine Frist von 3 Monaten einhalten.
    </p>
  </div>
);
