import Radio from '@eg/elements/Radio';
import RadioTile from '@eg/elements/RadioTile';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeEmploymentStatus } from '../../store/offer';
import { RoutePath } from '../app/app.interface';
import { canGoToNextStep, setDirectionsState } from '../navigation-buttons/navigation-button.slice';
import TooltipIcon from '../tooltip-icon/tooltip-icon';
import { clickEventTrack, pageLoadTrack } from '../tracking/tracking.slice';
import './employment.component.scss';
import { EmploymentStatusOptionLabel, isEmploymentStatus } from './employment.interface';
import { EmploymentTooltip } from './employment.tooltip';

const employmentStatuses: {
  value: EmploymentStatusOptionLabel;
  label: string;
}[] = [
  {
    value: EmploymentStatusOptionLabel.YES,
    label: 'Ja',
  },
  {
    value: EmploymentStatusOptionLabel.NO,
    label: 'Nein',
  },
];

const EmploymentContainer = function EmploymentContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const employmentStatus = useAppSelector((state) => state.offer.employmentStatus);
  const aem = useAppSelector((state) => state.aem);
  const setEmploymentStatus = (newStatus: EmploymentStatusOptionLabel) => {
    if (isEmploymentStatus(newStatus)) {
      dispatch(changeEmploymentStatus(newStatus));
      dispatch(canGoToNextStep(!!newStatus));
    }
  };

  useEffect(() => {
    dispatch(
      setDirectionsState({
        canGoFurther: !!employmentStatus,
        previousStep: RoutePath.MARITAL_STATUS,
        nextStep: RoutePath.EMPLOYMENT_SITUATION,
        showBackButton: true,
      })
    );
    dispatch(
      pageLoadTrack({
        viewName:
          `${aem.rechtsschutzAemAssets.trackingShortName}:${aem.rechtsschutzAemAssets.trackingViewNameEmployment}` ||
          '',
      })
    );
  }, []);

  return (
    <div className="employment__container">
      <div id="employment_header">
        <div className="page__title">Sind Sie berufstätig?</div>
        <div className="page__subtitle">
          <b>Tipp:</b> Im Lebensbereich Beruf bietet ERGO wichtigen Schutz z. B. bei Problemen mit dem Arbeitgeber.
          <TooltipIcon
            isModal
            onToggledOpen={(isOpen) => {
              if (isOpen)
                dispatch(
                  clickEventTrack({
                    clickedElement: 'InfoIcon_BerufAuswahl',
                    timedCall: true,
                  })
                );
            }}
          >
            <EmploymentTooltip />
          </TooltipIcon>
        </div>
      </div>
      <div className="employment__select-container-mobile" id="employment_select-container-mobile">
        {employmentStatuses.map(({ value, label }) => (
          <div
            className={classNames({
              'esc_border-radius': true,
              'employment__single-radio-row': true,
              selected: employmentStatus === value,
            })}
            key={value}
          >
            <Radio
              className="employment__option-radio"
              value={value}
              data-testid={`employment-select-mobile-${value}`}
              name="employment-select-mobile"
              checked={employmentStatus === value}
              label={label}
              onChange={() => {
                setEmploymentStatus(value);
                dispatch(
                  clickEventTrack({
                    clickedElement: `RButton_Beruf${label}`,
                    tariffOptions: { BerT: `${label.charAt(0)}` },
                    timedCall: true,
                  })
                );
              }}
            />
          </div>
        ))}
      </div>
      <div className="employment__select-container" id="employment_select-container">
        {employmentStatuses.map((status) => (
          <RadioTile
            value={status.value}
            key={status.value}
            data-testid={`employment-radio-tile-${status.value}`}
            hideCheckMark
            onChange={() => {
              setEmploymentStatus(status.value);
              dispatch(
                clickEventTrack({
                  clickedElement: `RButton_Beruf${status.label}`,
                  tariffOptions: { BerT: `${status.label.charAt(0)}` },
                  timedCall: true,
                })
              );
            }}
            checked={employmentStatus === status.value}
          >
            <Radio
              className="employment__icon-description"
              checked={employmentStatus === status.value}
              onChange={() => {
                setEmploymentStatus(status.value);
                dispatch(
                  clickEventTrack({
                    clickedElement: `RButton_Beruf${status.label}`,
                    tariffOptions: { BerT: `${status.label.charAt(0)}` },
                    timedCall: true,
                  })
                );
              }}
              label={status.label}
              value={status.value}
            />
          </RadioTile>
        ))}
      </div>
    </div>
  );
};

const EmploymentComponent = EmploymentContainer;
export default EmploymentComponent;
