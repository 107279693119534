export const EmployedSituationTooltip = function EmployedSituationTooltip(): JSX.Element {
  return (
    <div className="tooltip">
      <h3>Berufliche Situation</h3>
      <div>
        <ul>
          <li>
            <p>
              <b>Arbeitnehmer</b> - auch Azubis
            </p>
          </li>
          <li>
            <p>
              <b>Beamter</b> bzw. im öffentlichen Dienst tätig
            </p>
          </li>
          <li>
            <p>
              <b>Selbstständig</b> bzw. <b>Freiberufler</b>
            </p>
          </li>
        </ul>
        <div>
          Versicherungsschutz besteht für den privaten Lebensbereich. Wenn Sie den Lebensbereich Beruf zusätzlich
          absichern möchten, sind nur im <b>Rechtsschutz Best</b> selbstständige Tätigkeiten mitversichert. Aber nur,
          wenn <b>keine Mitarbeiter</b> beschäftigt werden und bis zu einem{' '}
          <b>Gesamtumsatz von max. 22.000 € pro Kalenderjahr</b>.
          <br />
          <br />
          Möchten Sie selbstständige Tätigkeiten <b>über 22.000 € pro Jahr</b> absichern, hilft Ihnen Ihr ERGO Berater
          vor Ort gern weiter.
        </div>
      </div>
    </div>
  );
};

export const UnemployedSituationTooltip = function UnemployedSituationTooltip(): JSX.Element {
  return (
    <div className="tooltip">
      <h3>Nicht berufstätig</h3>
      <div>
        <ul>
          <li>
            <p>
              <strong>(Vor-)Ruhestand oder dauerhaft nicht mehr erwerbstätig</strong>
            </p>
          </li>
          <p>
            Sie sind dauerhaft nicht mehr erwerbstätig bzw. im (Vor-)Ruhestand? Dann profitieren Sie vom günstigeren
            Rechtsschutz Vital für Senioren (verglichen mit dem Normaltarif).
          </p>
          <p>
            <strong>Tipp:</strong> Wenn Ihr <strong>Ehe- bzw. Lebenspartner noch berufstätig</strong> ist, schließen Sie
            besser einen Rechtsschutz mit vollem Versicherungsschutz im Lebensbereich Beruf ab. Denn der Rechtsschutz
            Vital für Senioren enthält <strong>nur einen eingeschränkten Arbeits-Rechtsschutz.</strong> Dieser hilft
            Ihnen bei rechtlichen Angelegenheiten zu geringfügigen Beschäftigungen, zur betrieblichen Altersvorsorge und
            zum Beihilferecht.
          </p>
          <p>
            Sie sind <strong>jünger als 55 Jahre</strong>? Dann ist bei der Antragstellung ein Nachweis über Ihren
            (Vor-)Ruhestand erforderlich, z. B. eine Kopie des Rentenbescheids.
          </p>

          <li>
            <p>
              <strong>Beamter im Ruhestand</strong>
            </p>
          </li>

          <li>
            <p>
              <strong>Zurzeit nicht berufstätig</strong>
            </p>
            Sie sind z. B.
            <ul>
              <li>Student</li>
              <li>Hausfrau bzw. Hausmann</li>
              <li>Arbeitssuchend</li>
            </ul>
          </li>
        </ul>
        <p>
          <strong>Achtung</strong>: Falsche Angaben können im Leistungsfall dazu führen, dass Sie Ihren{' '}
          <strong>Versicherungsschutz verlieren</strong>. Das bedeutet, Sie müssen die{' '}
          <strong>Kosten selbst tragen</strong>.
        </p>
      </div>
    </div>
  );
};
