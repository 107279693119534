// eslint-disable-next-line
import Button from '@eg/elements/Button';
import Details from '@eg/elements/Details';
import EditIcon from '@eg/elements/Icons/EditIcon';
import TooltipIcon from '@eg/elements/TooltipIcon';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { DateFormat, deMomentString } from '../../utils/date-configurations';
import { capitalizeFirstLetter } from '../../utils/helpers';
import { calculateDisplayTarif } from '../../utils/offer-german-mutation';
import { RoutePath } from '../app/app.interface';
import { allEmploymentSituations } from '../employment-situation/employment-situation.component';
import { EmployedSituationOptionLabel } from '../employment-situation/employment-situation.interface';
import { setDirectionsState } from '../navigation-buttons/navigation-button.slice';
import { PayementIntervals } from '../tariff-data/tariff-data.interface';
import { clickEventTrack, pageLoadTrack } from '../tracking/tracking.slice';
import './tarif-overview.component.scss';
import { SELF_EMPLYED_TOOLTIP } from './tarif-overview.tooltip';

const TarifOverviewComponent = function TarifOverviewComponent(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const offer = useAppSelector((state) => state.offer);
  const aem = useAppSelector((state) => state.aem);
  const employementSituation = allEmploymentSituations.filter(
    (situation) => situation.value === offer.employmentSituation
  )[0];

  const preInsuranceDuration = () => {
    let message = '-';

    if (offer.preInsurance?.dropdown === '0') message = '0 bis 2 Jahre leistungsfrei';
    if (offer.preInsurance?.dropdown === '3')
      message =
        '3 bis 4 Jahre leistungsfrei (ERGO halbiert Ihre gewählte Selbstbeteiligung bei Ihrem 1. Leistungsfall)';
    if (offer.preInsurance?.dropdown === '5')
      message =
        '5 oder mehr Jahre leistungsfrei (ERGO verzichtet bei Ihrem 1. Leistungsfall ganz auf die vereinbarte Selbstbeteiligung)';

    return message;
  };

  const handlePreInsuranceData = () => {
    let data = [{ title: 'Vorversicherung', value: 'Nein' }];

    if (offer.preInsurance?.name)
      data = [
        { title: 'Vorversicherung', value: 'Ja' },
        {
          title: 'Versicherungsscheinnummer',
          value: `${offer.preInsurance?.number}`,
        },
        { title: 'Versicherungsunternehmen', value: `${offer.preInsurance?.name}` },
        { title: 'Gekündigt von', value: `${offer.preInsurance?.quitter}` },
        { title: 'Vertragsablauf', value: `${offer.preInsurance?.expiration}` },
        { title: 'Vorvertrag', value: preInsuranceDuration() },
      ];

    return data;
  };

  const summary = [
    {
      title: 'Persönliche Situation',
      trackingTitle: 'PersoenlicheSituation',
      page: RoutePath.MARITAL_STATUS,
      data: [
        {
          title: 'Lebenssituation',
          value: offer.maritalStatus,
        },
        {
          title: 'Berufstätig',
          value: offer.employmentStatus,
        },
        {
          title: 'Beruflicher Status',
          value: employementSituation && employementSituation.label,
        },
      ],
    },

    {
      title: 'Tarif und Beitrag',
      trackingTitle: 'TarifBeitrag',
      page: RoutePath.TARIFF_DATA,
      data: [
        {
          title: 'Ihr Tarif',
          value: `${calculateDisplayTarif(offer)}`,
        },
        {
          title: (
            <b style={{ display: 'flex', alignItems: 'center' }}>
              Versicherte Lebensbereiche
              {offer.employmentSituation === EmployedSituationOptionLabel.SELF_EMPLOYED && (
                <TooltipIcon isModal>{SELF_EMPLYED_TOOLTIP}</TooltipIcon>
              )}
            </b>
          ),
          value: (
            <p style={{ display: 'flex' }}>
              {offer.rsPrivate && 'Privat'}
              {offer.rsPrivate && <br />}
              {offer.rsEmployment && 'Beruf'}
              {offer.rsEmployment && <br />}
              {offer.rsHouse && 'Wohnen'}
              {offer.rsHouse && <br />}
              {offer.rsTraffic && 'Verkehr'}
            </p>
          ),
        },
        {
          title: 'Ihr Beitrag',
          value: `${String(offer.price?.toFixed(2)).replace('.', ',')} € ${
            PayementIntervals.filter((interval) => interval.value === String(offer.interval))[0].description
          }`,
        },
        {
          title: 'Selbstbeteiligung',
          value: `${offer.contribution} €`,
        },
        {
          title: 'Vertragsdauer',
          value: offer.duration > 1 ? `${offer.duration} Jahre: 10% Dauernachlass` : `${offer.duration} Jahr`,
        },
        {
          title: 'Vertragslaufzeit',
          value:
            offer.insuranceStartDate &&
            deMomentString(offer.insuranceStartDate).add(offer.duration, 'years').format(DateFormat.UI_DATE),
        },
      ],
    },
    {
      title: 'Versicherungsbeginn',
      trackingTitle: 'VersBeginn',
      page: RoutePath.INSURANCE_START,
      data: [
        {
          title: 'Versicherungsbeginn',
          value: offer.insuranceStartDate,
        },
      ],
    },
    {
      title: 'Daten zur Vorversicherung',
      trackingTitle: 'Vorversicherung',
      page: RoutePath.PRE_INSURANCE,
      data: handlePreInsuranceData(),
    },

    {
      title: 'Persönliche Daten',
      trackingTitle: 'PersoenlicheDaten',
      page: RoutePath.PERSONAL_DATA,
      data: [
        { title: 'Anrede', value: offer.personalData && offer.personalData?.salutation },
        {
          title: 'Vorname',
          value: offer.personalData && offer.personalData?.firstName,
        },
        {
          title: 'Nachname',
          value: offer.personalData && offer.personalData?.lastName,
        },
        {
          title: 'Straße, Hausnummer',
          value: offer.personalData && `${offer.personalData?.adress.street} ${offer.personalData?.adress.houseNumber}`,
        },
        {
          title: 'PLZ, Ort',
          value: offer.personalData && `${offer.personalData?.adress.postcode} ${offer.personalData?.adress.area}`,
        },
        { title: 'Geburtsdatum', value: offer.personalData && `${offer.personalData?.birthdate}` },
        { title: 'E-Mail-Adresse', value: offer.personalData && `${offer.personalData?.email}` },
        {
          title: 'Telefon',
          value:
            offer.personalData && offer.personalData?.phoneNumber && offer.personalData.phonePrefix
              ? `${offer.personalData?.countryCode} ${offer.personalData?.phonePrefix} ${offer.personalData?.phoneNumber}`
              : '-',
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(
      setDirectionsState({
        backLinkLabel: 'zurück',
        canGoFurther: true,
        previousStep: RoutePath.PRE_INSURANCE,
        nextStep: RoutePath.PAYMENT,
        showBackButton: true,
      })
    );
  });

  useEffect(() => {
    dispatch(
      pageLoadTrack({
        viewName:
          `${aem.rechtsschutzAemAssets.trackingShortName}:${aem.rechtsschutzAemAssets.trackingViewNameTariffOverview}` ||
          '',
        transactionAttributes: { conversionType: 'SaleFunnelFinalCheck' },
      })
    );
  }, []);

  return (
    <div className="tarif-overview__container">
      <div id="tarif_overview_header">
        <div className="page__title">Sie haben es fast geschafft.</div>
        <div className="page__subtitle">
          <p>Bitte prüfen Sie, ob Ihre Angaben richtig und vollständig sind.</p>
        </div>
      </div>
      <div className="tarif-overview__summary">
        <div className="tarif-overview__divider" />
        {summary.map((item, idx) => (
          <div id={`tarif-overview_${item.trackingTitle}`} key={`tarif-overview_${item.trackingTitle}`}>
            <Details
              summary={<b className="tarif-overview__summary-title"> {item.title}</b>}
              key={`tarif-overview__${item.title}`}
              isInitiallyOpen={idx === 0}
              onToggledOpen={() => {
                dispatch(
                  clickEventTrack({
                    clickedElement: `Acc_${item.trackingTitle}`,
                    timedCall: true,
                  })
                );
              }}
            >
              <div id={`tarif-overview_${item.trackingTitle}-properties`}>
                {item.data.map(
                  (param) =>
                    param && (
                      <div
                        className="tarif-overview__summary-param"
                        key={`tarif-overview__${param.title}`}
                        id={`tarif-overview_${param.title}`}
                      >
                        {typeof param.value === 'string' ? <b>{param.title}</b> : param.title}
                        {typeof param.value === 'string' ? (
                          <p>{param.value && capitalizeFirstLetter(param.value)}</p>
                        ) : (
                          param.value
                        )}
                      </div>
                    )
                )}
              </div>
              <div
                className="tarif-overview__navigation-button"
                id={`tarif-overview_navigation-link-${item.trackingTitle}`}
              >
                <Button
                  variant="text-link"
                  iconLeft={EditIcon}
                  size="large"
                  onClick={() => {
                    navigate(item.page);
                    dispatch(
                      clickEventTrack({
                        clickedElement: `Link_Aendern${item.trackingTitle}`,
                        timedCall: false,
                      })
                    );
                  }}
                >
                  Angaben ändern
                </Button>
              </div>
            </Details>
          </div>
        ))}
        <div className="tarif-overview__info-text">
          <div>Im nächsten Schritt sind noch Angaben zu Ihrer Bankverbindung nötig.</div>
        </div>
      </div>
    </div>
  );
};

export default TarifOverviewComponent;
