// TODO: enable eslint when implementing the Session Storage
/*eslint-disable */
export const loadSession = () => {
  try {
    const sessionState = sessionStorage.getItem('state');
    if (!sessionState) {
      return undefined;
    }

    return JSON.parse(sessionState);
  } catch (error) {
    throw new Error('An Error occured while loading the Session');
  }
};

// eslint-disable-next-line
export const saveState = (state: any): any => {
  try {
    const sessionState = JSON.stringify(state);
    sessionStorage.setItem('state', sessionState);
  } catch (error) {
    throw new Error('An Error occured while saving the state in the Session Storage');
  }
};
/*eslint-disable */
