import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
export interface ProgressbarState {
  currentProgress: number;
}

// Define the initial state using that type
export const initialState: ProgressbarState = {
  currentProgress: (1 / 10) * 100,
};

export const progressbarSlice = createSlice({
  name: 'progressbar',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setProgress: (state, action: PayloadAction<number>) => {
      state.currentProgress = (1 / 10) * 100 * action.payload;
    },
  },
});

export const { setProgress } = progressbarSlice.actions;

const progressbarReducer = progressbarSlice.reducer;
export default progressbarReducer;
