import DateInput, { DateInputValue } from '@eg/elements/DateInput';
import Radio from '@eg/elements/Radio';
import RadioTile from '@eg/elements/RadioTile';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { resetInsuranceStartDateError, setInsuranceStartDateError } from '../../store/errors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeStartDate, resetStartDate } from '../../store/offer';
import {
  beginningOfNextMonth,
  DateErrorMessages,
  DateFormat,
  DatePickerErrorMessages,
  deMoment,
  tomorrow,
} from '../../utils/date-configurations';
import { validateNumberInput } from '../../utils/helpers';
import { RoutePath } from '../app/app.interface';
import { ErrorMessages } from '../birthdate/birthdate.interface';
import { canGoToNextStep, setDirectionsState } from '../navigation-buttons/navigation-button.slice';
import TooltipIcon from '../tooltip-icon/tooltip-icon';
import { clickEventTrack, pageLoadTrack, setTrackingInsuranceStart } from '../tracking/tracking.slice';
import './insurance-start-date.component.scss';
import {
  InsuranceStartDateOption,
  InsuranceStartDateOptionLabel,
  InsuranceStartTrackingLabel,
  RsInsuranceStart,
} from './insurance-start-date.interface';
import { INSURANCE_START_TOOLTIP } from './insurance-start-date.tooltip';

export const minInsuranceStartDate = deMoment();
export const maxInsuranceStartDate = deMoment().add(1, 'y');
const initialState = {
  value: {
    day: '',
    month: '',
    year: '',
  },
  errorMessages: null,
  valid: true,
  touched: false,
};

const insuranceStartDateOptions = (): InsuranceStartDateOption[] => {
  return [
    {
      label: InsuranceStartDateOptionLabel.TOMORROW,
      trackingLabel: InsuranceStartTrackingLabel.TOMORROW,
      shortDate: tomorrow.format(DateFormat.UI_DATE),
      longDate: tomorrow.format(DateFormat.UI_DAY_NAME_WITH_DATE),
      value: tomorrow.format(DateFormat.UI_DATE),
    },
    {
      label: InsuranceStartDateOptionLabel.NEXT_MONTH,
      trackingLabel: InsuranceStartTrackingLabel.NEXT_MONTH,
      shortDate: beginningOfNextMonth.format(DateFormat.UI_DATE),
      longDate: beginningOfNextMonth.format(DateFormat.UI_DAY_NAME_WITH_DATE),
      value: beginningOfNextMonth.format(DateFormat.UI_DATE),
    },
    {
      label: InsuranceStartDateOptionLabel.OTHER_DATE,
      trackingLabel: InsuranceStartTrackingLabel.OTHER_DATE,
      value: 'Wählen Sie Ihren Wunschtermin',
    },
  ];
};

const dateInputErrors = (errors: ErrorMessages) => {
  const keys = errors && Object.keys(errors);
  return (
    <div>
      {keys &&
        keys.map((key) => (
          <p key={key} className="insurance-start-date__error">
            {errors[key]}
          </p>
        ))}
    </div>
  );
};

const InsuranceStartDateComponent = function InsuranceStartDateComponent(): JSX.Element {
  const dispatch = useAppDispatch();
  const insuranceStartDate = useAppSelector((state) => state.offer.insuranceStartDate);
  const aem = useAppSelector((state) => state.aem);
  const [selectedOption, setSelectedOption] = useState(tomorrow.format(DateFormat.UI_DATE));
  const [touched, setTouched] = useState(false);
  const [input, setInput] = useState<RsInsuranceStart>(initialState);
  useEffect(() => {
    dispatch(
      setDirectionsState({
        backLinkLabel: 'zurück',
        canGoFurther: input.valid && !!insuranceStartDate,
        previousStep: RoutePath.EMPLOYMENT_SITUATION,
        nextStep: RoutePath.BIRTHDATE,
        showBackButton: true,
      })
    );
  });

  function handleChecked(option: InsuranceStartDateOption) {
    let checked = false;
    if (insuranceStartDate && option.value === insuranceStartDate) checked = true;

    if (option.label === InsuranceStartDateOptionLabel.OTHER_DATE && selectedOption === option.value) checked = true;

    return checked;
  }

  useEffect(() => {
    if (!insuranceStartDate) {
      dispatch(
        changeStartDate({
          day: tomorrow.format(DateFormat.UI_DATE).split('.')[0],
          month: tomorrow.format(DateFormat.UI_DATE).split('.')[1],
          year: tomorrow.format(DateFormat.UI_DATE).split('.')[2],
        })
      );
      dispatch(setTrackingInsuranceStart(tomorrow.format(DateFormat.UI_DATE) || ''));
    }

    if (
      insuranceStartDate &&
      insuranceStartDate !== tomorrow.format(DateFormat.UI_DATE) &&
      insuranceStartDate !== beginningOfNextMonth.format(DateFormat.UI_DATE)
    ) {
      setSelectedOption(
        insuranceStartDateOptions().filter((option) => option.label === InsuranceStartDateOptionLabel.OTHER_DATE)[0]
          .value
      );
      setInput({
        value: {
          day: insuranceStartDate.split('.')[0],
          month: insuranceStartDate.split('.')[1],
          year: insuranceStartDate.split('.')[2],
        },
        errorMessages: null,
        valid: true,
        touched: false,
      });
    }

    dispatch(
      pageLoadTrack({
        viewName:
          `${aem.rechtsschutzAemAssets.trackingShortName}:${aem.rechtsschutzAemAssets.trackingViewNameInsuranceStart}` ||
          '',
      })
    );
  }, []);

  function onRadioButtonDateSelected(startDate: string) {
    setSelectedOption(startDate);
    if (startDate && startDate !== 'Wählen Sie Ihren Wunschtermin') {
      setInput({ ...input, valid: true });
      dispatch(
        changeStartDate({
          day: startDate.split('.')[0],
          month: startDate.split('.')[1],
          year: startDate.split('.')[2],
        })
      );
      dispatch(setTrackingInsuranceStart(startDate || ''));
    }

    if (startDate && startDate === 'Wählen Sie Ihren Wunschtermin') {
      dispatch(resetStartDate());
      setInput(initialState);
    }
  }

  function setStartDate(newStartDate: RsInsuranceStart) {
    dispatch(canGoToNextStep(newStartDate.valid));
    setInput(newStartDate);
    dispatch(resetInsuranceStartDateError());

    if (newStartDate.valid) {
      dispatch(changeStartDate(newStartDate.value));
      dispatch(
        setTrackingInsuranceStart(`${newStartDate.value.day}.${newStartDate.value.month}.${newStartDate.value.year}`)
      );
    }

    if (!newStartDate.valid && newStartDate.errorMessages) {
      const errorType = Object.keys(newStartDate.errorMessages)[0] as DateErrorMessages;
      setInput({
        ...newStartDate,
        valid: false,
        errorMessages: { errorMessage: DatePickerErrorMessages[errorType] },
      });

      if (input.value && input.value.year && input.value.year.length < 4)
        setInput({
          ...newStartDate,
          valid: false,
          errorMessages: { errorMessage: DatePickerErrorMessages.valueMissing },
        });
    }
  }

  return (
    <div className="insurance-start-date__container">
      <div id="insurance-start-date_header">
        <h3 className="page__title">Wann soll Ihre Versicherung beginnen?</h3>
        <div className="page__subtitle">
          Je früher der Versicherungsbeginn, desto schneller ist die Wartezeit vorbei.
          <TooltipIcon
            isModal
            onToggledOpen={(isOpen) => {
              if (isOpen)
                dispatch(
                  clickEventTrack({
                    clickedElement: 'InfoIcon_VersBeginn',
                    timedCall: true,
                  })
                );
            }}
          >
            {INSURANCE_START_TOOLTIP}
          </TooltipIcon>
        </div>
      </div>
      <div className="insurance-start-date__option-wrapper" id="insurance-start-date_option-wrapper">
        {insuranceStartDateOptions().map((option: InsuranceStartDateOption, idx) => (
          <div
            key={option.value}
            className={classNames({
              'insurance-start-date__option': true,
              selected: option.value === selectedOption,
            })}
            id={`insurance-start-date-option-${option.trackingLabel}`}
          >
            <RadioTile
              className="insurance-start-date__option-radio"
              data-testid={`insurance-start-date-option-${idx}`}
              name="insuranceStartDate"
              hideCheckMark
              value={option.value}
              checked={handleChecked(option)}
              onChange={(e) => {
                onRadioButtonDateSelected(e.target.value);
                dispatch(
                  clickEventTrack({
                    clickedElement: `RButton_${option.trackingLabel}`,
                    timedCall: true,
                  })
                );
              }}
            >
              <Radio
                checked={handleChecked(option)}
                className="insurance-start-date__option-radio__sub"
                onChange={(e) => {
                  onRadioButtonDateSelected(e.target.value);
                  dispatch(
                    clickEventTrack({
                      clickedElement: `RButton_${option.trackingLabel}`,
                      timedCall: true,
                    })
                  );
                }}
                label={
                  <>
                    <div className="insurance-start-date__option-label">{option.label}</div>
                    <div className="insurance-start-date__option-date">
                      {option.longDate ? option.longDate : option.value}
                    </div>
                  </>
                }
                value={option.value}
              />
            </RadioTile>
          </div>
        ))}
      </div>

      {selectedOption === 'Wählen Sie Ihren Wunschtermin' && (
        <div id="insurance-start-date_date-input">
          <div className="insurance-start-date__input-title">
            <p>Ihr gewünschter Versicherungsbeginn</p>
          </div>
          <div className="insurance-start-date__input-container">
            <div className="insurance-start-date__input-datepicker">
              <DateInput
                autoTab
                id="insurance-start-date"
                data-testid="insurance-start-date-input"
                value={input.value}
                error={!input.valid && touched}
                onKeyPress={(e) => validateNumberInput(e)}
                onBlur={() => {
                  setTouched(true);
                  if (!input.valid && input.errorMessages) {
                    const errorType = Object.keys(input.errorMessages)[0] as DateErrorMessages;
                    dispatch(setInsuranceStartDateError(errorType));
                  }
                }}
                onChange={(value: DateInputValue, errors) => {
                  const { valid, ...otherErrors } = errors;
                  const removeLeadingZerosValue = {
                    day: value.day,
                    month: value.month,
                    year: value.year && value.year.replace(/^0+/, ''),
                  };
                  setTouched(false);
                  if (`${value.day}.${value.month}.${value.year}` === insuranceStartDateOptions()[0].shortDate) {
                    setSelectedOption(insuranceStartDateOptions()[0].value);
                  }
                  if (`${value.day}.${value.month}.${value.year}` === insuranceStartDateOptions()[1].shortDate) {
                    setSelectedOption(insuranceStartDateOptions()[1].value);
                  }
                  setStartDate({
                    ...input,
                    value: removeLeadingZerosValue,
                    errorMessages: otherErrors,
                    valid,
                  });
                }}
                minDate={minInsuranceStartDate.toDate()}
                maxDate={maxInsuranceStartDate.toDate()}
              />
              {!input.valid && touched && input.errorMessages && dateInputErrors(input.errorMessages)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InsuranceStartDateComponent;
