import { DateInputValue } from '@eg/elements/DateInput';

export enum PreInsuranceOptionLabel {
  YES = 'ja',
  NO = 'nein',
}

export enum PreInsuranceQuitter {
  POLICY_HOLDER = 'Versicherungsnehmer',
  INSURER = 'Versicherer',
  UNKNOWN = '',
}
export interface PreInsurance {
  name: string;
  number: string;
  quitter: PreInsuranceQuitter;
  expiration: string;
  dropdown?: string;
}

export interface PreInsuranceExpirationDate {
  value: DateInputValue;
  errorMessages: {
    badInput?: string | undefined;
    rangeOverflow?: string | undefined;
    rangeUnderflow?: string | undefined;
    valueMissing?: string | undefined;
    errorMessage?: string | undefined;
  } | null;
  valid: boolean;
  touched: boolean;
}

export const PreInsuranceOptionLabelList = [PreInsuranceOptionLabel.YES, PreInsuranceOptionLabel.NO];

export function isPreInsured(value: PreInsuranceOptionLabel): boolean {
  return PreInsuranceOptionLabelList.includes(value) || false;
}

export type PreInsuranceErrors =
  | 'missingDate'
  | 'badDateInput'
  | 'missingTerminated'
  | 'missingNumber'
  | 'missingCompany'
  | 'terminatedByTheInsurer';

export const PreInsuranceErrorMessages = {
  missingDate: 'Bitte geben Sie an, wann Ihr Vertrag abläuft (Format TT.MM.JJJJ).',
  badDateInput: 'Bitte geben Sie ein gültiges Datum ein.',
  missingTerminated: 'Bitte geben Sie an, wer gekündigt hat.',
  missingNumber: 'Bitte geben Sie die Versicherungsscheinnummer an.',
  missingCompany: 'Bitte geben Sie den Namen der Gesellschaft an.',
  terminatedByTheInsurer:
    'Ein Online-Abschluss ist leider nicht möglich, wenn die Gesellschaft den Vertrag gekündigt hat. Ihr ERGO Berater vor Ort hilft Ihnen gern weiter.',
};
