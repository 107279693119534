import './personal-data.component.scss';

export const PERSONALDATA_SUBTITLE_TOOLTIP = (
  <div className="tooltip">
    <h3>Datenschutz bei ERGO</h3>
    <p>
      Bei der Verarbeitung personenbezogener Daten beachtet ERGO die Vorschriften der EU-Datenschutz-Grundverordnung.
      Ausführliche Informationen finden Sie im Datenschutzbereich.
      <br />
      <br />
      Wenn Sie ein Angebot anfordern, erhalten Sie die Informationen in Ihren Vertragsunterlagen.
    </p>
  </div>
);

export const PERSONALDATA_EMAIL_TOOLTIP = (
  <div className="tooltip">
    <h3>Warum benötigt ERGO Ihre E-Mail-Adresse?</h3>
    <p>
      Ihre E-Mail-Adresse wird benötigt, um Ihnen die Anforderung per E-Mail zu bestätigen. Diese Angabe unterliegt
      natürlich strengen Datenschutzbestimmungen.
    </p>
  </div>
);
