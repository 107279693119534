const MaritalStatusTooltipContent = [
  {
    title: 'Single',
    paragraph: (
      <>
        Versichert sind <b>Sie</b> als Versicherungsnehmer.
      </>
    ),
  },
  {
    title: 'Alleinerziehend',
    paragraph: (
      <>
        Versichert sind <b>Sie und Ihr Kind</b> bzw. Ihre Kinder*.
      </>
    ),
  },
  {
    title: 'Paar',
    paragraph: (
      <>
        Versichert sind <b>Sie und Ihr (Ehe-)Partner</b>, wenn Sie zusammenleben und er mit Erstwohnsitz bei Ihnen
        gemeldet ist. Außerdem Ihre <b>Eltern</b> und die Ihres mitversicherten Partners, wenn diese im selben Haushalt
        leben wie Sie und dort mit Erstwohnsitz gemeldet sind.
      </>
    ),
  },
  {
    title: 'Familie',
    paragraph: (
      <>
        Versichert sind <b>Sie, Ihr (Ehe-)Partner</b>, wenn Sie zusammenleben, und Ihr Kind bzw. Ihre <b>Kinder*</b>{' '}
        sowie die Kinder Ihres (Ehe-)Partners. Darüber hinaus Ihre <b>Eltern</b> und die Ihres mitversicherten Partners,
        wenn diese im selben Haushalt leben wie Sie und dort mit Erstwohnsitz gemeldet sind.
        <br />
        <br />
        <b>Zusätzlich in Best</b>: Ihre weiteren <b>Verwandten</b> sowie die Ihres Partners mit ihren jeweiligen
        Partnern. Vorausgesetzt, diese leben im selben Haushalt wie Sie und sind dort mit Erstwohnsitz gemeldet.
      </>
    ),
  },
];

export const MaritalStatusTooltip = function MaritalStatusTooltip(): JSX.Element {
  return (
    <div className="tooltip">
      <h3>Lebenssituationen</h3>
      {MaritalStatusTooltipContent.map((element) => (
        <div className="marital-status__tooltip-content" key={`marital-status__tooltip-content-${element.title}`}>
          <h4>{element.title}</h4>
          <p>{element.paragraph}</p>
        </div>
      ))}

      <br />
      <div className="tooltip__dimmed">
        <p>
          <strong>* Mitversicherte Kinder</strong> sind
        </p>
        <ul>
          <li>
            <p> Unverheiratete minderjährige Kinder (auch Stief-, Adoptiv-, Pflegekinder)</p>
          </li>
          <li>
            <p>Volljährige Kinder in Schule, Berufsausbildung, Studium, freiwilligem Wehrdienst, sozialem Jahr</p>
          </li>
          <li>
            <p>
              {' '}
              Volljährige Kinder während Arbeitslosigkeit nach Schule, Berufsausbildung, Studium, freiwilligem
              Wehrdienst, sozialem Jahr
            </p>
          </li>
          <li>
            <p>Volljährige Kinder in Wartezeiten zwischen den Ausbildungsabschnitten</p>
          </li>
        </ul>
        <p>
          <strong>Zusätzlich in Best</strong>: Volljährige Kinder, die einen Beruf ausüben, mit Ihnen zusammenleben und
          mit Erstwohnsitz bei Ihnen gemeldet sind.
        </p>
      </div>
    </div>
  );
};
