import axios, { AxiosError } from 'axios';
import { CONFIG_BACKEND_API_BASE_URL } from '../../../config';

const request = axios.create({
  baseURL: CONFIG_BACKEND_API_BASE_URL,
});

export default request;

export const isAxiosError = (error: unknown): error is AxiosError => {
  return (error as AxiosError).isAxiosError;
};
