import { AemWindow } from './aem.interface';

const trackingWindow = window as unknown as AemWindow;

const init = (): void => {
  trackingWindow.appEventData = trackingWindow.appEventData || [];
};

const push = (data: unknown): void => {
  trackingWindow.appEventData.push(data);
};

const status = {
  set: ({ appId, isTracking }: { appId: string; isTracking: boolean }): void =>
    localStorage.setItem(`${appId}_aem`, isTracking.toString()),
  get: (appId: string): string | null => localStorage.getItem(`${appId}_aem`),
  remove: (appId: string): void => localStorage.removeItem(`${appId}_aem`),
  setIsFormSubmitted: ({ appId, isFormSubmitted }: { appId: string; isFormSubmitted: boolean }): void =>
    localStorage.setItem(`${appId}_submit`, isFormSubmitted.toString()),
  getIsFormSubmitted: (appId: string): string | null => localStorage.getItem(`${appId}_submit`),
};

export const aemTracking = {
  init,
  push,
  status,
  trackingWindow,
};
