export enum EmploymentStatusOptionLabel {
  YES = 'Ja',
  NO = 'Nein',
}

export const EmploymentStatusOptionLabelList = [EmploymentStatusOptionLabel.YES, EmploymentStatusOptionLabel.NO];

export function isEmploymentStatus(value: EmploymentStatusOptionLabel): boolean {
  return EmploymentStatusOptionLabelList.includes(value) || false;
}
