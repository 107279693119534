import Modal from '@eg/elements/Modal';
import Button from '@eg/elements/Button';
import './error-handling-modal.scss';
import { ErrorModalProps, FetchError } from './error-handling-modal.interface';

const ErrorHandlingModal = function ErrorHandlingModal(props: ErrorModalProps): JSX.Element {
  const { open, onClick, errorType } = props;
  return (
    <Modal open={open}>
      <>
        {/* This conditional Logic is there because business constantly changes text displayed  */}
        {errorType === FetchError.NETWORK && (
          <>
            <h1 className="error-handling-modal__title">Entschuldigung</h1>
            <p>
              Ein Online-Abschluss ist zurzeit leider nicht möglich. Bitte versuchen Sie es später wieder. Oder Sie
              rufen an und lassen sich telefonisch beraten.
            </p>
          </>
        )}

        {errorType === FetchError.SERVER && (
          <>
            <h1 className="error-handling-modal__title">Entschuldigung</h1>
            <p>
              Ein Online-Abschluss ist zurzeit leider nicht möglich. Bitte versuchen Sie es später wieder. Oder Sie
              rufen an und lassen sich telefonisch beraten.
            </p>
          </>
        )}
        <div className="error-handling-modal__button-container">
          <Button type="button" variant="primary" size="large" onClick={() => onClick()}>
            Erneut versuchen
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default ErrorHandlingModal;
