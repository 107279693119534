import React from 'react';
import './birthdate.component.scss';

export const BIRTHDATE_TOOLTIP = (
  <div className="tooltip">
    <h2>Sie sind unter 25?</h2>

    <p>
      Dann sichern Sie sich den <b>Startbonus für junge Leute</b> in Höhe von <b>10 %</b> des Beitrags. Einfach eine
      Rechtsschutzversicherung <b>mit Selbstbeteiligung</b> wählen und der Nachlass wird automatisch berücksichtigt.
      <br />
      <br />
      <b>Achtung:</b> Der Startbonus kann grundsätzlich <b>nicht mit anderen Rabatten</b> kombiniert werden.
    </p>
  </div>
);
