import Card from '@eg/elements/Card';
import { useEffect, useState } from 'react';
import { appId } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { calculateDisplayTarif } from '../../utils/offer-german-mutation';
import { aemTracking } from '../aem/aem-tracking';
import { loadExternalJs } from '../aem/aemHelper';
import { isAxiosError } from '../app/api/request';
import { changeShowBackButton, changeShowButton } from '../navigation-buttons/navigation-button.slice';
import { PayementIntervals } from '../tariff-data/tariff-data.interface';
import { errorTrack, pageLoadTrack } from '../tracking/tracking.slice';
import * as api from './thank-you.api';
import './thank-you.component.scss';

const ThankYouComponent = function ThankYouComponent(): JSX.Element {
  const isFormSubmittedSuccessfully = aemTracking.status.getIsFormSubmitted(appId);
  const dispatch = useAppDispatch();
  const { rechtsschutzAemAssets, isEditMode } = useAppSelector((state) => state.aem);
  const offer = useAppSelector((state) => state.offer);
  const aem = useAppSelector((state) => state.aem);
  const [welcomeMessages, setWelcomeMessages] = useState('');
  const insuranceArea = (
    <>
      {offer.rsPrivate ? <span>Privat</span> : null}
      {offer.rsEmployment ? <span>Beruf</span> : null}
      {offer.rsHouse ? <span>Wohnen</span> : null}
      {offer.rsTraffic ? <span>Verkehr</span> : null}
    </>
  );
  const interval = PayementIntervals.filter((payementInterval) => Number(payementInterval.value) === offer.interval)[0];
  const summary = [
    { title: 'Ihr Tarif', value: `${calculateDisplayTarif(offer)}` },
    { title: 'Versicherte Lebensbereiche', value: insuranceArea },
    { title: 'Versicherungsbeginn', value: offer.insuranceStartDate },
    {
      title: 'Ihr Beitrag',
      value: `${String(offer.price?.toFixed(2)).replace('.', ',')} € ${
        interval.description
      } (inkl. Versicherungssteuer von zurzeit 19 %)`,
    },
  ];

  useEffect(() => {
    dispatch(changeShowButton(false));
    dispatch(changeShowBackButton(false));
  });

  useEffect(() => {
    if (isFormSubmittedSuccessfully === 'true') {
      dispatch(
        pageLoadTrack({
          viewName:
            `${aem.rechtsschutzAemAssets.trackingShortName}:${aem.rechtsschutzAemAssets.trackingViewNameThankYou}` ||
            '',
          transactionAttributes: { conversionType: 'Sale' },
          transactionTotal: { transactionTotal: offer.priceYearNetto },
        })
      );
      aemTracking.status.set({ appId, isTracking: false });
      aemTracking.status.setIsFormSubmitted({ appId, isFormSubmitted: false });
    }
  }, []);

  useEffect(() => {
    try {
      api.getAemXf(rechtsschutzAemAssets.feedbackLinkOnlineContract || '', isEditMode).then((response) => {
        setWelcomeMessages(loadExternalJs(response.data));
      });
    } catch (error: unknown) {
      if (isAxiosError(error) && error.response) {
        dispatch(
          errorTrack({
            errorType: error.response.status,
            errorMessages: `${error.request.responseURL}: ${error.message}`,
            errorUrl: window.location.href,
          })
        );
      }
      setWelcomeMessages('');
    }
  });

  return (
    <div className="thank-you__container">
      <div className="thank-you__body-container">
        <p className="thank-you__subtitle" id="thank-you_subtitle">
          Sie haben bereits eine Bestätigung an die angegebene E-Mail-Adresse erhalten. Ihre Vertragsunterlagen bekommen
          Sie in wenigen Tagen per Post.
        </p>

        <div id="thank-you-content">
          <h3 className="thank-you__title" id="thank-you_title">
            Ihr ERGO Rechtsschutz
          </h3>
          <Card padding="normal" className="thank-you__summary" id="thank-you_summary">
            {summary.map((param) => (
              <div className="thank-you__summary-param" key={`feedback-param-${param.title}`}>
                <b>{param.title}</b>
                <p>{param.value}</p>
              </div>
            ))}
          </Card>
        </div>
        {welcomeMessages ? <div id="thank-you_aem-xf" dangerouslySetInnerHTML={{ __html: welcomeMessages }} /> : null}
      </div>
    </div>
  );
};

export default ThankYouComponent;
