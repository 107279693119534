import React from 'react';

export const SelectFieldSvg: React.FC = () => {
  return (
    <span className="ee_select__toggle-wrapper">
      <svg
        width="24px"
        height="24px"
        className="ee_svg-icon ee_svg-icon--regular ee_icon ee_icon-arrowdownicon ee_select__toggle"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        role="img"
        aria-labelledby=""
      >
        <g>
          <path
            id="arrowdownicon-a"
            transform="rotate(-90 12 12.167)"
            d="M18.1666667,19.6606426 L8.02380952,12.1425703 L18.1666667,4.62449799 L18.1666667,2.16666667 L6.5952381,10.6967871 C6.11904762,11.0341365 5.83333333,11.564257 5.83333333,12.1425703 C5.83333333,12.7208835 6.11904762,13.251004 6.5952381,13.5883534 L18.1666667,22.1666667 L18.1666667,19.6606426 Z"
          />
        </g>
      </svg>
    </span>
  );
};
