export enum EmployedSituationOptionLabel {
  EMPLOYEE = 'Arbeitnehmer',
  SELF_EMPLOYED = 'Selbstaendiger',
  OFFICAL = 'Beamter',
}

export enum UnemployedSituationOptionLabel {
  RETIREMENT = 'Ruhestand',
  OFFICAL_RETIREMENT = 'RuhestandBeamter',
  UNEMPLOYED = 'OhneTaetigkeit',
}

export const EmploymentSituationList = [
  EmployedSituationOptionLabel.EMPLOYEE,
  EmployedSituationOptionLabel.OFFICAL,
  EmployedSituationOptionLabel.SELF_EMPLOYED,
  UnemployedSituationOptionLabel.OFFICAL_RETIREMENT,
  UnemployedSituationOptionLabel.RETIREMENT,
  UnemployedSituationOptionLabel.UNEMPLOYED,
];

export function isEmploymentSituation(value: EmployedSituationOptionLabel | UnemployedSituationOptionLabel): boolean {
  return EmploymentSituationList.includes(value) || false;
}
