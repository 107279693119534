import Checkbox from '@eg/elements/Checkbox';
import TooltipIcon from '@eg/elements/TooltipIcon';
import { useEffect, useState } from 'react';
import { resetTarifDataError, setTarifDataError } from '../../store/errors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeTarif } from '../../store/offer';
import {
  EmployedSituationOptionLabel,
  UnemployedSituationOptionLabel,
} from '../employment-situation/employment-situation.interface';
import { EmploymentStatusOptionLabel } from '../employment/employment.interface';
import { clickEventTrack, setTariffOptions } from '../tracking/tracking.slice';
import { TarifType } from './tariff-data.interface';
import { EmploymentTooltip, HouseTooltip, PrivatTooltip, TrafficTooltip } from './tariff-data.tooltip';

type CheckboxProps = {
  variant: string;
};

export const Checkboxes = function Checkboxes(props: CheckboxProps): JSX.Element {
  const dispatch = useAppDispatch();
  const offer = useAppSelector((state) => state.offer);
  const [hasError, setHasError] = useState(false);
  const { variant } = props;
  const checkBoxErrorMessage = 'Wählen Sie mindestens einen Lebensbereich.';

  useEffect(() => {
    dispatch(resetTarifDataError());
    if (!offer.rsPrivate && !offer.rsTraffic && !hasError && variant === offer.variant) {
      dispatch(setTarifDataError(checkBoxErrorMessage));
      setHasError(true);
    } else if (offer.rsPrivate || offer.rsTraffic) setHasError(false);
  });

  return (
    <div className="tariff-data__secondary-tab-container" id="tariff-data__checkbox-tab">
      <h3 className="radio-title" id={`tariff-data_checkbox-title-${variant}`}>
        Wählen Sie Ihre Lebensbereiche:
      </h3>
      {offer.employmentSituation === EmployedSituationOptionLabel.SELF_EMPLOYED ? (
        <div className="self-employed-info">
          Private Risiken sind abgesichert. Um den Lebensbereich Beruf zusätzlich abzusichern, wählen Sie den{' '}
          <b>Rechtsschutz Best</b>. Nur darin sind Risiken aus <b>selbstständiger Tätigkeit</b> bis zu einem
          Gesamtumsatz von <b>22.000 € pro Jahr</b> mitversichert, wenn <b>keine Mitarbeiter</b> beschäftigt sind.
          <br />
          <br />
          Möchten Sie selbstständige Tätigkeiten <b>über 22.000 € pro Jahr</b> absichern, hilft Ihnen Ihr ERGO Berater
          vor Ort gern weiter.
        </div>
      ) : null}
      {hasError ? <div className="tariff-data__checkbox-error">{checkBoxErrorMessage}</div> : null}
      <ul className="checkbox-list">
        <li id="tariff-data_checkbox-privat">
          <div>
            <Checkbox
              data-testid="tariff-data__checkbox-privat"
              label={
                <div>
                  <strong>Privat</strong>
                  <div className="subtext">Kann auch allein abgeschlossen werden</div>
                </div>
              }
              checked={offer.rsPrivate}
              onChange={(event) => {
                dispatch(changeTarif({ name: TarifType.PRIVATE, value: event.target.checked }));
                dispatch(setTariffOptions({ RSPr: event.target.checked ? 'J' : 'N' }));
                if (!event.target.checked) {
                  dispatch(setTariffOptions({ RSBe: 'N', RSWo: 'N' }));
                }
              }}
            />
            <TooltipIcon
              isModal
              onToggledOpen={(isOpen) => {
                if (isOpen)
                  dispatch(
                    clickEventTrack({
                      clickedElement: 'InfoIcon_Privat',
                      timedCall: true,
                    })
                  );
              }}
            >
              {PrivatTooltip}
            </TooltipIcon>
          </div>
        </li>
        {offer.employmentStatus === EmploymentStatusOptionLabel.YES ||
        offer.employmentSituation === UnemployedSituationOptionLabel.UNEMPLOYED ? (
          <li id="tariff-data_checkbox-employment">
            <div>
              <Checkbox
                data-testid="tariff-data__checkbox-employment"
                label={
                  <div>
                    <strong>Beruf</strong>
                    <div className="subtext">Kann nur zusammen mit Privat abgeschlossen werden</div>
                  </div>
                }
                disabled={!offer.rsPrivate}
                checked={offer.rsEmployment}
                onChange={(event) => {
                  dispatch(changeTarif({ name: TarifType.EMPLOYMENT, value: event.target.checked }));
                  dispatch(setTariffOptions({ RSBe: event.target.checked ? 'J' : 'N' }));
                }}
              />
              <TooltipIcon
                isModal
                onToggledOpen={(isOpen) => {
                  if (isOpen)
                    dispatch(
                      clickEventTrack({
                        clickedElement: 'InfoIcon_Beruf',
                        timedCall: true,
                      })
                    );
                }}
              >
                {EmploymentTooltip}
              </TooltipIcon>
            </div>
          </li>
        ) : null}
        <li id="tariff-data_checkbox-living">
          <div>
            <Checkbox
              data-testid="tariff-data__checkbox-living"
              label={
                <div>
                  <strong>Wohnen</strong>
                  <div className="subtext">Kann nur zusammen mit Privat abgeschlossen werden</div>
                </div>
              }
              disabled={!offer.rsPrivate}
              checked={offer.rsHouse}
              onChange={(event) => {
                dispatch(changeTarif({ name: TarifType.HOUSE, value: event.target.checked }));
                dispatch(setTariffOptions({ RSWo: event.target.checked ? 'J' : 'N' }));
              }}
            />
            <TooltipIcon
              isModal
              onToggledOpen={(isOpen) => {
                if (isOpen)
                  dispatch(
                    clickEventTrack({
                      clickedElement: 'InfoIcon_Wohnen',
                      timedCall: true,
                    })
                  );
              }}
            >
              {HouseTooltip}
            </TooltipIcon>
          </div>
        </li>
        <li id="tariff-data_checkbox-traffic">
          <div>
            <Checkbox
              data-testid="tariff-data__checkbox-traffic"
              label={
                <div>
                  <strong>Verkehr</strong>
                  <div className="subtext">Kann auch allein abgeschlossen werden</div>
                </div>
              }
              checked={offer.rsTraffic}
              onChange={(event) => {
                dispatch(changeTarif({ name: TarifType.TRAFFIC, value: event.target.checked }));
                dispatch(setTariffOptions({ RSVe: event.target.checked ? 'J' : 'N' }));
              }}
            />
            <TooltipIcon
              isModal
              onToggledOpen={(isOpen) => {
                if (isOpen)
                  dispatch(
                    clickEventTrack({
                      clickedElement: 'InfoIcon_Verkehr',
                      timedCall: true,
                    })
                  );
              }}
            >
              {TrafficTooltip}
            </TooltipIcon>
          </div>
        </li>
      </ul>
    </div>
  );
};
