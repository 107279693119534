import { AxiosResponse } from 'axios';
import { getXfUrlByAemProperty, noCacheHeaders } from '../aem/aemHelper';
import request from '../app/api/request';
import { AreaBody } from './personal-data.interface';

export const getArea = (body: AreaBody): Promise<AxiosResponse> => {
  return request.post('/api/AdressePruefen', body);
};

export const getAemXf = (url: string, isEditMode: boolean): Promise<AxiosResponse> => {
  if (url)
    return request.get(getXfUrlByAemProperty(url, isEditMode), {
      headers: noCacheHeaders,
    });
  throw new Error('No Content Provided');
};
