export enum RsMaritalStatus {
  FAMILY = 'Familie',
  SINGLE = 'Single',
  PAIR = 'Paar',
  SELF_PARENTING = 'Alleinerziehend',
}

export const RsMaritalStatusList: string[] = [
  RsMaritalStatus.FAMILY,
  RsMaritalStatus.SINGLE,
  RsMaritalStatus.PAIR,
  RsMaritalStatus.SELF_PARENTING,
];

export function isMaritalStatus(value: RsMaritalStatus): boolean {
  return RsMaritalStatusList.includes(value) || false;
}
