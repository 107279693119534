import Price from '@eg/elements/Price';
import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { calculateDisplayTarif } from '../../utils/offer-german-mutation';
import { RoutePath } from '../app/app.interface';
import './price-header.component.scss';

const PAGES_WITHOUT_PRICE: string[] = [
  RoutePath.ROOT,
  RoutePath.INIT,
  RoutePath.MARITAL_STATUS,
  RoutePath.EMPLOYMENT,
  RoutePath.EMPLOYMENT_SITUATION,
  RoutePath.BIRTHDATE,
  RoutePath.INSURANCE_START,
];

interface Props {
  location: string;
}

const PriceHeaderComponent = function PriceHeaderComponent(props: Props): JSX.Element {
  const offer = useAppSelector((state) => state.offer);
  const insuranceName = 'ERGO Rechtsschutz';
  const { location } = props;

  const paymentInterval = () => {
    switch (offer.interval) {
      case 4:
        return 'viertelj.';

      case 2:
        return 'halbj.';

      case 1:
        return 'jährl.';

      case 12:
      default:
        return 'mtl.';
    }
  };

  return (
    <div className="price-header">
      <div className="price-header__container">
        <div className="price-header__text">
          <div className="price-header__label">{insuranceName}</div>
          {!PAGES_WITHOUT_PRICE.includes(location) ? (
            <div className="price-header__description">{calculateDisplayTarif(offer)}</div>
          ) : null}
        </div>
        {!PAGES_WITHOUT_PRICE.includes(location) ? (
          <div className="price-header__price-field">
            <span className="price-header__price">
              <Price value={offer.price && offer.price > 0 ? offer.price : undefined} unstyled />
            </span>{' '}
            <span className="price-header__payment-interval">{paymentInterval()}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export { PriceHeaderComponent };
