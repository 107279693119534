import { DateInputValue } from '@eg/elements/DateInput';
import moment from 'moment';
import 'moment/locale/de';

type ErrorMessages = {
  [key in DateErrorMessages]: string;
};
export type DateErrorMessages = 'valueMissing' | 'badInput' | 'notAdult' | 'rangeUnderflow' | 'rangeOverflow';
export enum DateFormat {
  UI_DATE = 'DD.MM.YYYY',
  UI_DAY_NAME_WITH_DATE = 'dddd, DD.MM.YYYY',
}

export const MAX_PROFIT_AGE = 25;
export const DatePickerErrorMessages: ErrorMessages = {
  valueMissing: 'Bitte geben Sie das vollständige Datum ein (Format TT MM JJJJ).',
  badInput: 'Bitte geben Sie ein gültiges Datum ein.',
  notAdult: 'Sie müssen bei Vertragsabschluss mindestens 18 Jahre alt sein.',
  rangeUnderflow: 'Der frühestmögliche Versicherungsbeginn ist morgen, der späteste in einem Jahr.',
  rangeOverflow: 'Der Versicherungsbeginn kann höchstens ein Jahr in der Zukunft liegen.',
};

moment.locale('en');
export const deMoment = (): moment.Moment => moment().locale('de');
export const deMomentString = (date: string): moment.Moment => moment(date, DateFormat.UI_DATE).locale('de');
export const tomorrow = deMoment().add(1, 'd');
export const beginningOfNextMonth =
  deMoment().add(1, 'M').date(1).date() === tomorrow.date()
    ? deMoment().add(2, 'M').date(1)
    : deMoment().add(1, 'M').date(1);
export const minDate = moment(tomorrow).toDate();

/**
 * A function that checks if the age of the user falls
 * under the profit Group defined by the const  MAX_PROFIT_AGE
 * @param birthdate The birthdate of the user:`${day}-${month}-${year}`.
 * @param insuranceBegin When the user wants the insurance to begin:`${day}-${month}-${year}`.
 * @returns true or false.
 */
export function isProfitGroup(birthdate: string, insuranceBegin: string): boolean {
  const insuranceBeginDate = moment(insuranceBegin, DateFormat.UI_DATE);
  const inputValueDate = moment(birthdate, DateFormat.UI_DATE);
  return insuranceBeginDate.diff(inputValueDate, 'years') < MAX_PROFIT_AGE;
}

/**
 * A function that checks if the user is at least 18 years Old
 * @param birthdate The birthdate of the user.
 * @returns true or false.
 */
export function isAdult(birthdate: DateInputValue): boolean {
  const inputValueDate = moment(`${birthdate.day}.${birthdate.month}.${birthdate.year}`, DateFormat.UI_DATE);
  const dateNow = moment(
    `${new Date().getDate()}.${new Date().getMonth() + 1}.${new Date().getFullYear()}`,
    DateFormat.UI_DATE
  );

  return dateNow.diff(inputValueDate, 'years') >= 18;
}

/**
 * A function that checks if the date is in the future
 * @param date The birthdate of the user.
 * @returns true or false.
 */
export function isInTheFuture(date: DateInputValue): boolean {
  const inputValueDate = moment(`${date.day}.${date.month}.${date.year}`, DateFormat.UI_DATE);
  const dateNow = moment(
    `${new Date().getDate()}.${new Date().getMonth() + 1}.${new Date().getFullYear()}`,
    DateFormat.UI_DATE
  );

  return inputValueDate.isAfter(dateNow);
}
