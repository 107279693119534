export const SELF_EMPLYED_TOOLTIP = (
  <div className="tooltip">
    <p>
      Versicherungsschutz besteht für den <b>privaten Lebensbereich</b>. Wenn Sie den Lebensbereich „Beruf“ zusätzlich
      absichern möchten, sind <b>nur im Rechtsschutz Best</b> die selbstständigen Tätigkeiten bis zu einem{' '}
      <b>Gesamtumsatz von 22.000 € pro Kalenderjahr</b> mitversichert. Vorausgesetzt, es sind <b>keine Mitarbeiter</b>{' '}
      beschäftigt.
    </p>
    <p>
      Möchten Sie selbstständige Tätigkeiten über 22.000 € pro Jahr absichern, hilft Ihnen Ihr ERGO Berater vor Ort gern
      weiter.
    </p>
  </div>
);
