import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AemWindow } from '../components/aem/aem.interface';
import { SubmitOfferErrorMessages, SubmitOfferErrors } from '../components/download/download.interface';
import { FetchError } from '../components/error-handling-modal/error-handling-modal.interface';
import { PersonalDataErrorMessages, PersonalDataErrors } from '../components/personal-data/personal-data.interface';
import { PreInsuranceErrorMessages, PreInsuranceErrors } from '../components/pre-insurance/pre-insurance.interface';
import { TrackingError, TrackingErrorType } from '../components/tracking/tracking.interface';
import { DateErrorMessages, DatePickerErrorMessages } from '../utils/date-configurations';
import { loadSession } from './sessionStore';

export interface ErrorHandlingState {
  network: boolean;
  server: boolean;
  insuranceStartDate: {
    valueMissing: string | null;
    notAdult: string | null;
    badInput: string | null;
    rangeUnderflow: string | null;
    rangeOverflow: string | null;
  };
  birthdate: {
    valueMissing: string | null;
    badInput: string | null;
    notAdult: string | null;
    rangeUnderflow: string | null;
    rangeOverflow: string | null;
  };
  tarifData: {
    checkBoxError: string | null;
  };

  personalData: {
    missingSalutation: string | null;
    missingName: string | null;
    missingSurname: string | null;
    missingEmail: string | null;
    missingStreet: string | null;
    missingHouseNumber: string | null;
    missingPostcode: string | null;
    invalidPostcode: string | null;
    unknownPostcode: string | null;
    wrongEmail: string | null;
    wrongEmailErgo: string | null;
    wrongPhoneNumber: string | null;
    unallowedCharacters: string | null;
  };
  preInsurance: {
    missingCompany: string | null;
    badDateInput: string | null;
    missingNumber: string | null;
    missingDate: string | null;
    missingTerminated: string | null;
    terminatedByTheInsurer: string | null;
  };
  payment: {
    unallowedIban: string | null;
  };
  download: {
    pdfDownloadError: string | null;
    offerSubmitError: string | null;
  };
}

export const initialState: ErrorHandlingState = {
  network: false,
  server: false,
  insuranceStartDate: {
    valueMissing: null,
    badInput: null,
    notAdult: null,
    rangeUnderflow: null,
    rangeOverflow: null,
  },
  birthdate: {
    valueMissing: null,
    badInput: null,
    notAdult: null,
    rangeUnderflow: null,
    rangeOverflow: null,
  },
  tarifData: {
    checkBoxError: null,
  },
  personalData: {
    missingSalutation: null,
    missingName: null,
    missingSurname: null,
    missingStreet: null,
    missingHouseNumber: null,
    missingPostcode: null,
    invalidPostcode: null,
    unknownPostcode: null,
    missingEmail: null,
    wrongEmail: null,
    wrongEmailErgo: null,
    wrongPhoneNumber: null,
    unallowedCharacters: null,
  },
  preInsurance: {
    missingCompany: null,
    badDateInput: null,
    missingNumber: null,
    missingDate: null,
    missingTerminated: null,
    terminatedByTheInsurer: null,
  },
  payment: {
    unallowedIban: null,
  },
  download: {
    pdfDownloadError: null,
    offerSubmitError: null,
  },
};

const handleErrorsTracking = (error: { type: TrackingErrorType; message: string }) => {
  // init carbon data layer array if not already present
  const trackingWindow = window as unknown as AemWindow;
  trackingWindow.appEventData = trackingWindow.appEventData || [];
  const data = loadSession();
  let errorBody: TrackingError = {
    event: 'GeneralClick',
    miscellaneous: {
      errors: {
        errorFlag: true,
        errorType: error.type,
        errorMessages: `${error.message}`,
        errorUrl: window.location.href,
      },
    },
    eventdetails: { clickType: 'other', timedCall: true },
  };

  if (data)
    errorBody = {
      ...data.tracking.trackingInformation,
      ...errorBody,
    };

  // tracking call for error
  trackingWindow.appEventData.push(errorBody);
};

export const errorHandlingSlice = createSlice({
  name: 'errorHandling',
  initialState,
  reducers: {
    setNetworkError: (state, action: PayloadAction<boolean>) => {
      state.network = action.payload;
      if (action.payload === true)
        handleErrorsTracking({
          type: FetchError.NETWORK,
          message: `The user faced ${FetchError.NETWORK} issues.`,
        });
    },
    setServerError: (state, action: PayloadAction<boolean>) => {
      state.server = action.payload;
      if (action.payload === true)
        handleErrorsTracking({
          type: FetchError.SERVER,
          message: `The user faced ${FetchError.SERVER} issues.`,
        });
    },
    setInsuranceStartDateError: (state, action: PayloadAction<DateErrorMessages>) => {
      state.insuranceStartDate[action.payload] = DatePickerErrorMessages[action.payload];
      handleErrorsTracking({
        type: 'FormFieldError',
        message: DatePickerErrorMessages[action.payload],
      });
    },
    resetInsuranceStartDateError: (state) => {
      state.insuranceStartDate = initialState.insuranceStartDate;
    },
    setBirthdateError: (state, action: PayloadAction<DateErrorMessages>) => {
      state.birthdate[action.payload] = DatePickerErrorMessages[action.payload];
      handleErrorsTracking({
        type: 'FormFieldError',
        message: DatePickerErrorMessages[action.payload],
      });
    },
    resetBirthdateError: (state) => {
      state.birthdate = initialState.birthdate;
    },
    setTarifDataError: (state, action: PayloadAction<string>) => {
      state.tarifData.checkBoxError = action.payload;
      handleErrorsTracking({
        type: 'CheckBoxError',
        message: action.payload,
      });
    },
    resetTarifDataError: (state) => {
      state.tarifData.checkBoxError = null;
    },
    setPersonalDataError: (state, action: PayloadAction<PersonalDataErrors>) => {
      state.personalData[action.payload] = PersonalDataErrorMessages[action.payload];
      handleErrorsTracking({
        type: 'FormFieldError',
        message: PersonalDataErrorMessages[action.payload],
      });
    },
    resetPersonalDataError: (state, action: PayloadAction<PersonalDataErrors>) => {
      state.personalData[action.payload] = null;
    },
    setPreInsuranceError: (state, action: PayloadAction<PreInsuranceErrors>) => {
      state.preInsurance[action.payload] = PreInsuranceErrorMessages[action.payload];
      handleErrorsTracking({
        type: 'FormFieldError',
        message: PreInsuranceErrorMessages[action.payload],
      });
    },
    resetPreInsuranceError: (state, action: PayloadAction<PreInsuranceErrors>) => {
      state.preInsurance[action.payload] = null;
    },
    initializePreInsuranceError: (state) => {
      state.preInsurance = initialState.preInsurance;
    },
    setPaymentError: (state, action: PayloadAction<string>) => {
      state.payment.unallowedIban = action.payload;
      handleErrorsTracking({
        type: 'FormFieldError',
        message: action.payload,
      });
    },
    resetPaymentError: (state) => {
      state.payment.unallowedIban = null;
    },
    setDownloadError: (state, action: PayloadAction<SubmitOfferErrors>) => {
      state.download[action.payload] = SubmitOfferErrorMessages[action.payload];
      handleErrorsTracking({
        type: 'FormFieldError',
        message: SubmitOfferErrorMessages[action.payload],
      });
    },
    resetDownloadError: (state, action: PayloadAction<SubmitOfferErrors>) => {
      state.download[action.payload] = null;
    },
    resetErrors: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setNetworkError,
  setServerError,
  setInsuranceStartDateError,
  resetInsuranceStartDateError,
  setBirthdateError,
  resetBirthdateError,
  setTarifDataError,
  resetTarifDataError,
  setPersonalDataError,
  resetPersonalDataError,
  setPreInsuranceError,
  resetPreInsuranceError,
  initializePreInsuranceError,
  setPaymentError,
  resetPaymentError,
  setDownloadError,
  resetDownloadError,
  resetErrors,
} = errorHandlingSlice.actions;

const errorHandlingReducer = errorHandlingSlice.reducer;
export default errorHandlingReducer;
