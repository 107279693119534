import { TarifBody } from '../tariff-data/tarif-data.api';

export interface SubmitOfferResponse {
  isValid: boolean;
  model: null;
  stringResult: string[] | null;
}

export interface OfferRequest {
  key: null;
  websitePartnerId: number;
  tarifDaten: TarifBody;
  vorversicherung: Vorversicherung;
  persDaten: PersDaten;
  bankDaten: BankDaten;
  vermittler: Agent;
  calculatedTarif: null;
  angebotsUrl: null;
  tarifGewaehlt: boolean;
  url: string;
  zahldatum: string | null;
}

export interface BankDaten {
  zahlart: number;
  isLastschrift: boolean;
  zahlungstag: number;
  iban: string | null;
  bic: string | null;
  bankname: string | null;
  abbuchungAm: string | null;
  ibanFormatted: string | null;
  ibanDisplay: string | null;
  zahlungstagAsString: string | null;
}

export interface PersDaten {
  anrede: string | null;
  vorname: string | null;
  nachname: string | null;
  geburtsdatum: string | null;
  adresse: Adresse;
  telefon: string | null;
  telefonVorwahl: string | null;
  telefonNr: string | null;
  email: string | null;
  partnernummer: null;
  freiwilligeEinverstaendniserklaerung: boolean;
  kontaktPerEmail: boolean;
  kontaktPerTelefon: boolean;
}

export interface Adresse {
  strasse: string | null;
  hausNr: string | null;
  postleitzahl: string | null;
  wohnort: string | null;
  strasseHnr: string | null;
  plzOrt: string | null;
}

export interface Agent {
  code: string;
  useVermittler: boolean;
  pnr: string | null;
  oeNr: string | null;
  vorname: string | null;
  nachname: string | null;
  anrede: string | null;
  tel: string | null;
  fax: string | null;
  email: string | null;
  internet: string | null;
  mobileNr: string | null;
  regNr: string | null;
  strasse: string | null;
  plz: string | null;
  ort: string | null;
  isVermittlerMode: boolean;
}

export interface Vorversicherung {
  vorversicherung: string | null;
  nameDerGesellschaft: string | null;
  gekuendigtVon: string | null;
  versicherungsscheinNummer: string | null;
  ablaufdatum: string | null;
  vorschaeden: number;
  isLeistungsfreiheitVisible: boolean;
}

export type SubmitOfferErrors = 'pdfDownloadError' | 'offerSubmitError';
export const SubmitOfferErrorMessages = {
  pdfDownloadError:
    'Ein Online-Abschluss ist zurzeit leider nicht möglich. Bitte versuchen Sie es später wieder. Oder Sie rufen an und lassen sich telefonisch beraten.',
  offerSubmitError:
    'Ein Online-Abschluss ist zurzeit leider nicht möglich. Bitte versuchen Sie es später wieder. Oder Sie rufen an und lassen sich telefonisch beraten.',
};
