export enum FetchError {
  SERVER = 'ServerError',
  NETWORK = 'NetworkError',
}

export type FetchErrorType = FetchError.SERVER | FetchError.NETWORK;
export interface ErrorModalProps {
  onClick: () => void;
  open: boolean;
  errorType: FetchError;
}
