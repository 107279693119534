import FormSection from '@eg/elements/FormSection';
import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import LoaderIcon from '@eg/elements/Icons/LoaderIcon';
import InputRow from '@eg/elements/InputRow';
import { useEffect, useState } from 'react';
import { resetPaymentError, setPaymentError, setServerError } from '../../store/errors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changePayment } from '../../store/offer';
import { isAxiosError } from '../app/api/request';
import { RoutePath } from '../app/app.interface';
import { setDirectionsState } from '../navigation-buttons/navigation-button.slice';
import TooltipIconComponent from '../tooltip-icon/tooltip-icon';
import { clickEventTrack, errorTrack, pageLoadTrack } from '../tracking/tracking.slice';
import { IbanResponseMessages } from './payement.interface';
import * as api from './payment.api';
import './payment.component.scss';
import { PaymentTooltip } from './payment.tooltip';

const PaymentComponent = function PaymentComponent(): JSX.Element {
  const dispatch = useAppDispatch();
  const aem = useAppSelector((state) => state.aem);
  const { payment, personalData } = useAppSelector((store) => store.offer);
  const [bankName, setBankName] = useState(payment && payment.bankName ? payment.bankName : '');
  const [iban, setIban] = useState(payment && payment.iban ? payment.iban : '');
  const [bic, setBic] = useState(payment && payment.bic ? payment.bic : '');
  const [valid, setValid] = useState(false);
  const [message, setMessage] = useState<null | string>('');
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    dispatch(
      pageLoadTrack({
        viewName:
          `${aem.rechtsschutzAemAssets.trackingShortName}:${aem.rechtsschutzAemAssets.trackingViewNamePayment}` || '',
        transactionAttributes: { conversionType: 'SaleFunnelBankData' },
      })
    );
  }, []);
  const name = `${personalData?.firstName || ''} ${personalData?.lastName || ''}`;
  const paymentDetails = {
    bankName,
    iban,
    bic,
  };
  const minIbanLength = iban.match(/^DE/) ? 22 : 15;

  const handleIban = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    let position = target.selectionEnd || 0;
    const { length } = target.value;
    target.value = target.value
      .toUpperCase()
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    position +=
      target.value.charAt(position - 1) === ' ' &&
      target.value.charAt(length - 1) === ' ' &&
      length !== target.value.length
        ? 1
        : 0;
    target.selectionEnd = position;
    setIban(target.value);
    if (target.value.match(/^DE/)) {
      if (target.value.replace(/\s/g, '').length === 22) {
        target.blur();
      }
    }
  };

  const handleResponseMessage = (input: string) => {
    let modifiedMessage = input;
    if (input === IbanResponseMessages.nonSepaArea)
      modifiedMessage = 'Bitte geben Sie eine IBAN von einer Bank aus dem SEPA-Zahlungsraum an.';

    setMessage(modifiedMessage);
    return modifiedMessage;
  };

  useEffect(() => {
    dispatch(
      setDirectionsState({
        canGoFurther: valid,
        previousStep: RoutePath.TARIFF_OVERVIEW,
        nextStep: RoutePath.DOWNLOAD,
        showBackButton: true,
      })
    );
  });

  useEffect(() => {
    dispatch(changePayment(paymentDetails));
  }, [bankName, iban, bic]);

  useEffect(() => {
    if (payment && payment.bankName && payment.iban && payment.bic) setValid(true);
  }, []);

  useEffect(() => {
    dispatch(resetPaymentError());
    if (iban.replace(/\s/g, '').length > minIbanLength - 1) {
      setIsFetching(true);
      api
        .postIbanConfirmation(iban)
        .then(({ data }) => {
          setIsFetching(false);
          setValid(data.isValid);
          setBic(data.bic);
          setBankName(data.bankName);
          if (data.message) {
            handleResponseMessage(data.message);
            dispatch(setPaymentError(handleResponseMessage(data.message)));
          }
        })
        .catch((error: unknown) => {
          dispatch(setServerError(true));

          setIsFetching(false);
          setValid(false);
          if (isAxiosError(error) && error.response) {
            dispatch(
              errorTrack({
                errorType: error.response.status,
                errorMessages: `${error.request.responseURL}: ${error.message}`,
                errorUrl: window.location.href,
              })
            );
          }
        });
    } else {
      setValid(false);
    }
  }, [iban]);

  return (
    <div className="payment__container">
      <div id="payment_header">
        <div className="page__title">Geben Sie Ihre Bankverbindung an.</div>
        <div className="subheader">
          Für einen Vertragsabschluss benötigt der Versicherer ein SEPA-Lastschriftmandat.
        </div>
      </div>
      <FormSection className="payment__form" id="payment_form">
        <InputRow label="Kontoinhaber" readOnly value={name} />
        <InputRow
          label="IBAN"
          onChange={(e) => {
            handleIban(e);
            handleResponseMessage('');
          }}
          value={iban}
          error={valid && !message ? false : String(message)}
          placeholder="DE12 3456 7890 1234 5678 90"
          adornmentRight={isFetching ? <LoaderIcon /> : undefined}
        />
      </FormSection>

      <div className="payment__validation" style={{ display: valid ? 'flex' : 'none' }}>
        <CheckColorIcon height={24} width={24} />
        <span className="payment__validation-text">
          <p>Ihre Kontoverbindung wurde erfolgreich geprüft. Die zugehörige BIC wurde automatisch ergänzt.</p>
          <p>
            BIC: {bic} / Geldinstitut: {bankName}
          </p>
        </span>
      </div>

      <div className="payment__information" id="payment_information">
        <p>
          <strong>
            Ich ermächtige die ERGO Versicherung AG, ERGO-Platz 1, 40477 Düsseldorf (Gläubiger-ID DE05ZZZ00000012101),
            Zahlungen von meinem Konto mittels Lastschrift einzuziehen.
          </strong>
        </p>
        <p>
          Zugleich weise ich mein Kreditinstitut an, die von der ERGO Versicherung AG auf mein Konto gezogenen
          Lastschriften einzulösen. Der SEPA-Basislastschrift-Einzug wird mir spätestens 5 Kalendertage im Voraus unter
          Angabe der weiteren Fälligkeitstermine angekündigt.
        </p>
        <div className="payment__note">
          <strong> Hinweis:</strong> <br />
          <p>
            Ich kann innerhalb von 8 Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrags
            verlangen. Dabei gelten die mit meinem Kreditinstitut vereinbarten Bedingungen.
          </p>
          <div className="payment__note-sepa">
            <strong>Hinweise zur SEPA-Lastschrift</strong>
            <TooltipIconComponent
              isModal
              onToggledOpen={(isOpen) => {
                if (isOpen)
                  dispatch(
                    clickEventTrack({
                      clickedElement: `InfoIcon_Sepa`,
                      timedCall: true,
                    })
                  );
              }}
            >
              <PaymentTooltip />
            </TooltipIconComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentComponent;
