import { AemWindow } from './aem.interface';

export const noCacheHeaders = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '-1',
};

const getHostnameWithProtocol = () => `${window.location.protocol}//${window.location.hostname}`;

export const getXfUrlByAemProperty = function getXfUrlByAemProperty(path: string, isEditMode: boolean): string {
  if (isEditMode) {
    return getHostnameWithProtocol() + path;
  }
  const newPathToFile = path.replace('content/experience-fragments', 'xf');
  return getHostnameWithProtocol() + newPathToFile;
};

export const loadExternalJs = function loadExternalJs(content: string): string {
  const aemWindow = window as unknown as AemWindow;
  if (aemWindow.loadFetchedAemJs) {
    return aemWindow.loadFetchedAemJs(content);
  }
  return content;
};
