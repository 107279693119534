import { FetchErrorType } from '../error-handling-modal/error-handling-modal.interface';

export enum AdobeAnalyticsSysEnv {
  PROD = 'prod',
  DEVELOP = 'develop',
}

export interface PageLoadTrackProps {
  viewName: string;
  transactionAttributes?: TransactionAttributes;
  tariffOptions?: TariffOptions;
  transactionTotal?: TransactionTotal;
}

export interface PageClickTrackProps {
  clickedElement: string;
  tariffOptions?: TariffOptions;
  timedCall?: boolean;
}

export interface TrackingBasic {
  event?: string;
  eventdetails?: Eventdetails;
  page: Page;
  product: Product[];
  transaction: Transaction;
  user?: User[];
}

export interface Page {
  pageInfo: PageInfo;
  attributes: Attributes;
  category: Category;
}

interface PageInfo {
  pageName?: string;
  sysEnv: AdobeAnalyticsSysEnv;
  issueDate: string;
}

export interface Eventdetails {
  timedCall?: boolean;
  clickedElement?: string;
  clickType?: string;
}

interface Attributes {
  displayedBrand: string;
  brokerModus?: string;
}

interface Category {
  pagePurpose: string;
  primaryCategory: string;
}

export interface Product {
  productInfo: ProductInfo;
  attributes: ProductAttributes;
  category: ProductCategory;
}

interface ProductInfo {
  productName: string;
}

interface ProductAttributes {
  productCombination?: string;
  tariffOptions1: string;
  insuranceStart?: string;
}

export type TariffOptions = {
  [key: string]: string;
};

interface ProductCategory {
  primaryCategory: string;
}

export interface Transaction {
  transactionID: string;
  attributes?: TransactionAttributes;
  total?: TransactionTotal;
}

interface TransactionTotal {
  transactionTotal?: number;
}

export interface TransactionAttributes {
  conversionType?: string;
  calculatedValue?: string;
}

export interface User {
  profile: UserProfile;
}

interface UserProfile {
  attributes?: UserAttributes;
  address?: Address;
}

export interface UserAttributes {
  jobTitle?: string;
  birthday?: string;
  gender?: string;
}

interface Address {
  postalCode: string;
}

export interface TrackingError {
  event: 'GeneralClick';
  miscellaneous: { errors: Error };
  transaction?: Transaction;
  eventdetails: Eventdetails;
}

interface Error {
  errorFlag: true;
  errorType: TrackingErrorType;
  errorMessages: string;
  errorUrl: string;
}

export interface TrackingErrorProps {
  errorType: TrackingErrorType;
  errorMessages: string;
  errorUrl: string;
}

export type TrackingErrorType = 'FormFieldError' | 'CheckBoxError' | FetchErrorType | 'unknown' | number;
