import {
  ContractInsuranceTooltip,
  CyberInsuranceTooltip,
  InsuranceAmountTooltip,
  LastWillTooltip,
  SpecialInsuranceTooltip,
} from './tariff-data.tooltip';

export enum TarifType {
  PRIVATE = 'private',
  EMPLOYMENT = 'employment',
  TRAFFIC = 'traffic',
  HOUSE = 'house',
}

export enum DiscountType {
  SENIOR = 'senior',
  OFFICAL = 'offical',
  YOUNG = 'young',
}

export const bestBenefitList: {
  label: string;
  tooltip: JSX.Element;
  trackingName: string;
}[] = [
  {
    label: 'Versicherungssumme unbegrenzt',
    tooltip: InsuranceAmountTooltip,
    trackingName: 'BestVersicherungssumme',
  },
  {
    label: 'Rechtsschutz im Vertrags- und Sachenrecht auch bei Kapitalanlagestreitigkeiten',
    tooltip: ContractInsuranceTooltip,
    trackingName: 'BestVertragsrecht',
  },
  {
    label: 'Cyber-/Urheber-Rechtsschutz und Phishing',
    tooltip: CyberInsuranceTooltip,
    trackingName: 'BestCyberschutz',
  },
  {
    label: 'Vorsorgeverfügungen bzw. Testament',
    tooltip: LastWillTooltip,
    trackingName: 'BestVorsorge',
  },
  {
    label: 'Spezial-Straf-Rechtsschutz',
    tooltip: SpecialInsuranceTooltip,
    trackingName: 'BestSpezialschutz',
  },
];

export const smartBenefitList: {
  label: string;
  subtext?: string;
  tooltip: JSX.Element;
  trackingName: string;
}[] = [
  {
    label: 'Versicherungssumme 2 Mio. €',
    tooltip: InsuranceAmountTooltip,
    trackingName: 'SmartVersicherungssumme',
  },
  {
    label: 'Rechtsschutz im Vertrags- und Sachenrecht',
    tooltip: ContractInsuranceTooltip,
    trackingName: 'SmartVertragsrecht',
  },
  {
    label: 'Cyber-Rechtsschutz',
    tooltip: CyberInsuranceTooltip,
    trackingName: 'SmartCyberschutz',
  },
];

export const PayementIntervals = [
  { value: '12', description: 'monatlich' },
  { value: '4', description: 'vierteljährlich' },
  { value: '2', description: 'halbjährlich' },
  { value: '1', description: 'jährlich' },
];
