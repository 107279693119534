export interface IbanResponse {
  isValid: boolean;
  message: null;
  iban: string;
  bic: string;
  bankleitzahl: null;
  kontonummer: null;
  bankName: string;
  bankOrt: string;
}

export interface Payement {
  bankName: string | null;
  iban: string | null;
  bic: string | null;
}

export enum IbanResponseMessages {
  nonSepaArea = 'Die angegebene IBAN ist nicht gültig. Nur eine europäische IBAN ist erlaubt.',
}
