import * as React from 'react';

export const EmploymentTooltip = function EmploymentTooltip(): JSX.Element {
  return (
    <div className="tooltip">
      <h3>Berufstätig oder nicht berufstätig?</h3>

      <p>
        Sie sind im Ruhestand, aber Ihr <b> Ehe- bzw. Lebenspartner noch nicht?</b> Dann schließen Sie besser den{' '}
        <b>Rechtsschutz für Paare oder Familien</b> mit vollem Versicherungsschutz im Lebensbereich Beruf ab. Denn der
        Rechtsschutz Vital für Senioren enthält <b>nur einen eingeschränkten Arbeits-Rechtsschutz, </b>
        der zum Beispiel nur geringfügige Beschäftigungsverhältnisse abdeckt.
      </p>
    </div>
  );
};
