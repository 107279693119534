import Button from '@eg/elements/Button';
import Select from '@eg/elements/components/Select';
import ControlWithHint from '@eg/elements/ControlWithHint';
import FormRow from '@eg/elements/FormRow';
import EditIcon from '@eg/elements/Icons/EditIcon';
import LoaderIcon from '@eg/elements/Icons/LoaderIcon';
import Input from '@eg/elements/Input';
import Radio from '@eg/elements/Radio';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import TooltipIcon from '@eg/elements/TooltipIcon';
import { useEffect, useState, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetPersonalDataError, setPersonalDataError } from '../../store/errors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changePersonalData } from '../../store/offer';
import {
  autotab,
  capitalizeFirstLetter,
  containsUnallowedCharacters,
  isEmailForbidden,
  removeSpaces,
  validateEmail,
  validateNumberInput,
  validateRegexInput,
} from '../../utils/helpers';
import { loadExternalJs } from '../aem/aemHelper';
import { isAxiosError } from '../app/api/request';
import { RoutePath } from '../app/app.interface';
import { setDirectionsState } from '../navigation-buttons/navigation-button.slice';
import {
  addUserAddress,
  addUserAttribute,
  clickEventTrack,
  errorTrack,
  pageLoadTrack,
} from '../tracking/tracking.slice';
import * as api from './personal-data.api';
import './personal-data.component.scss';
import {
  AreaComponentProps,
  AreaDetails,
  AreaResponse,
  PersonalDataErrors,
  PersonSalutation,
} from './personal-data.interface';
import { PERSONALDATA_EMAIL_TOOLTIP } from './personal-data.tooltip';

const AreaComponent = ({ areaCollection, area, setArea, customArea, setCustomArea }: AreaComponentProps) => {
  const dispatch = useAppDispatch();

  let component = (
    <span className="ee_input-wrapper">
      <div className="ee_input" id="area">
        <p>Wohnort wird automatisch ermittelt</p>
      </div>
    </span>
  );

  if ((!customArea && areaCollection === undefined) || (areaCollection && areaCollection.length === 1))
    component = area ? (
      <span className="ee_input-wrapper">
        <div className="ee_input" id="area">
          <p>{area}</p>
        </div>
      </span>
    ) : (
      <span className="ee_input-wrapper">
        <div className="ee_input" id="area">
          <p>Wohnort wird automatisch ermittelt</p>
        </div>
      </span>
    );
  if (!customArea && areaCollection && areaCollection.length > 1)
    component = (
      <Select
        onChange={(e) => setArea(e.target.value)}
        // value={personalData ? personalData.adress?.area : area}
      >
        {areaCollection.map((areaDetail: AreaDetails) => (
          <option value={areaDetail.ort} key={`${areaDetail.ort}-key`}>
            {areaDetail.ort}
          </option>
        ))}
      </Select>
    );

  if (customArea || areaCollection === null) {
    component = (
      <Input
        placeholder="Ort"
        onKeyPress={(e) => validateRegexInput(e)}
        value={area}
        onChange={(e) => {
          dispatch(resetPersonalDataError('unknownPostcode'));
          setArea(capitalizeFirstLetter(e.target.value));
          setCustomArea(true);
        }}
      />
    );
  }

  return component;
};
export const PersonalDataComponent = function PersonalDataComponent(): JSX.Element {
  const personalData = useAppSelector((state) => state.offer.personalData);
  const errors = useAppSelector((state) => state.errors);
  const { rechtsschutzAemAssets, isEditMode } = useAppSelector((state) => state.aem);
  const [salutation, setSalutation] = useState<PersonSalutation>(PersonSalutation.UNKNOWN);
  const [firstName, setFirstName] = useState<string>(
    personalData && personalData.firstName ? personalData.firstName : ''
  );
  const [lastName, setLastName] = useState<string>(personalData && personalData.lastName ? personalData.lastName : '');
  const [street, setStreet] = useState<string>(
    personalData && personalData.adress.street ? personalData.adress.street : ''
  );
  const [houseNumber, setHouseNumber] = useState<string>(
    personalData && personalData.adress.houseNumber ? personalData.adress.houseNumber : ''
  );
  const [postcode, setPostcode] = useState<string>(
    personalData && personalData.adress.postcode ? personalData.adress.postcode : ''
  );
  const [email, setEmail] = useState<string>(personalData && personalData.email ? personalData.email : '');
  const [phonePrefix, setPhonePrefix] = useState<string>(
    personalData && personalData.phonePrefix ? personalData.phonePrefix : ''
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(
    personalData && personalData.phoneNumber ? personalData.phoneNumber : ''
  );

  // TODO: replace with "" in production
  const [areaCollection, setAreaCollection] = useState<AreaDetails[] | null | undefined>(undefined);
  const [area, setArea] = useState<string>('');
  const [customArea, setCustomArea] = useState(personalData?.adress.customArea || false);

  const [privacyTooltip, setPrivacyTooltip] = useState('');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const birthdate = useAppSelector((state) => state.offer.birthdate);
  const aem = useAppSelector((state) => state.aem);
  const [isFetching, setIsFetching] = useState(false);

  const Person = {
    salutation,
    firstName,
    lastName,
    birthdate,
    adress: {
      street,
      houseNumber,
      postcode,
      area,
      customArea,
    },
    email,
    countryCode: phonePrefix && phoneNumber && '+49',
    phonePrefix,
    phoneNumber,
  };

  const isWrongPhoneNumber = (phonePrefixValue: string, phoneNumberValue: string) => {
    let state = false;
    if (
      (phonePrefixValue && phonePrefixValue.length < 3) ||
      (phoneNumberValue && phoneNumberValue.length < 3) ||
      (phonePrefixValue && !phoneNumberValue) ||
      (phoneNumberValue && !phonePrefixValue)
    )
      state = true;

    return state;
  };

  const requirementsAreFulfilled =
    salutation &&
    firstName &&
    !containsUnallowedCharacters(firstName) &&
    lastName &&
    !containsUnallowedCharacters(lastName) &&
    birthdate &&
    street &&
    !containsUnallowedCharacters(street) &&
    houseNumber &&
    !containsUnallowedCharacters(houseNumber) &&
    postcode &&
    !containsUnallowedCharacters(postcode) &&
    area &&
    validateEmail(email) &&
    !isEmailForbidden(email) &&
    !isWrongPhoneNumber(phonePrefix, phoneNumber);

  function handleEmailErrors() {
    let errorMessage = '';
    if (errors.personalData.missingEmail) errorMessage = errors.personalData.missingEmail;
    if (errors.personalData.wrongEmail) errorMessage = errors.personalData.wrongEmail;
    if (errors.personalData.wrongEmailErgo) errorMessage = errors.personalData.wrongEmailErgo;
    return errorMessage;
  }

  function handleErrors(personalDataError: PersonalDataErrors, input: string) {
    let errorMessage = '';

    if (!!errors.personalData.unallowedCharacters && containsUnallowedCharacters(input))
      errorMessage = errors.personalData.unallowedCharacters;

    if (errors.personalData[personalDataError]) errorMessage = String(errors.personalData[personalDataError]);

    return errorMessage;
  }

  const checkIfPostCodeIsInvalid = (e: SyntheticEvent) => {
    const numberOfDigits = (e.target as HTMLInputElement).value.length;
    if (numberOfDigits === 0) dispatch(setPersonalDataError('missingPostcode'));
    else if (numberOfDigits < 5) dispatch(setPersonalDataError('invalidPostcode'));
  };

  const onChangePostCodeHandler = (e: SyntheticEvent) => {
    checkIfPostCodeIsInvalid(e);
    dispatch(resetPersonalDataError('missingPostcode'));
    dispatch(resetPersonalDataError('invalidPostcode'));
    dispatch(resetPersonalDataError('unknownPostcode'));
    dispatch(resetPersonalDataError('unallowedCharacters'));
    if ((e.target as HTMLInputElement).value.length < 6) {
      setPostcode((e.target as HTMLInputElement).value);
      dispatch(addUserAddress((e.target as HTMLInputElement).value));
    }
  };

  async function handleArea(plz: string) {
    try {
      const response = await api.getArea({ Postleitzahl: plz });
      const data = response.data as AreaResponse;
      return data;
    } catch (error: unknown) {
      setAreaCollection(null);
      if (isAxiosError(error) && error.response) {
        dispatch(
          errorTrack({
            errorType: error.response.status,
            errorMessages: `${error.request.responseURL}: ${error.message}`,
            errorUrl: window.location.href,
          })
        );
      }

      return null;
    }
  }

  useEffect(() => {
    dispatch(
      setDirectionsState({
        backLinkLabel: 'zurück',
        canGoFurther: true,
        previousStep: RoutePath.TARIFF_DATA,
        nextStep: RoutePath.PRE_INSURANCE,
        showBackButton: true,
        jumpToContent: !requirementsAreFulfilled ? '.personal-data__label' : undefined,
      })
    );
  });

  useEffect(() => {
    dispatch(changePersonalData(Person));
  }, [salutation, firstName, lastName, street, houseNumber, postcode, area, email, phonePrefix, phoneNumber]);

  useEffect(() => {
    if (postcode.length > 4 && !personalData?.adress.area) {
      setIsFetching(true);
      setCustomArea(false);
      handleArea(postcode)
        .then((data) => {
          setIsFetching(false);
          if (data) setAreaCollection(data.model);
          if (data && data.model && data.model.length > 1) setArea(data.model[0].ort);
          if (data && !data.model) setArea('');
        })
        .catch((error: unknown) => {
          setIsFetching(false);
          setAreaCollection(null);
          if (isAxiosError(error) && error.response) {
            dispatch(
              errorTrack({
                errorType: error.response.status,
                errorMessages: `${error.request.responseURL}: ${error.message}`,
                errorUrl: window.location.href,
              })
            );
          }
        });
    } else {
      setArea('');
      setAreaCollection(undefined);
    }
  }, [postcode]);

  useEffect(() => {
    if (areaCollection && areaCollection.length === 1) setArea(areaCollection[0].ort);
    if (areaCollection === null) {
      dispatch(setPersonalDataError('unknownPostcode'));
    }
  }, [areaCollection]);

  /* This is a workaround to save the salutation state if the user switches pages because the RadioGroupRow EE initializes the state on page load */
  useEffect(() => {
    if (personalData && personalData.salutation) setSalutation(personalData.salutation);
    if (personalData && personalData.adress) setArea(personalData.adress.area);

    dispatch(
      pageLoadTrack({
        viewName:
          `${aem.rechtsschutzAemAssets.trackingShortName}:${aem.rechtsschutzAemAssets.trackingViewNamePersonalData}` ||
          '',
        transactionAttributes: { conversionType: 'SaleFunnelPersonalData' },
      })
    );
  }, []);

  return (
    <div className="personal-data__container">
      <div id="personal-data_header">
        <div className="personal-data__title">Personalisieren Sie Ihren Vertrag.</div>
        <div className="personal-data__subtitle">
          <p>
            Das geht einfach mit wenigen Angaben. Vor dem Abschluss können Sie Ihre persönlichen Daten noch einmal
            prüfen.
          </p>

          <div className="personal-data__label">
            <b>Datenschutz bei ERGO</b>
            <TooltipIcon
              isModal
              onToggledOpen={(isOpen) => {
                if (isOpen)
                  dispatch(
                    clickEventTrack({
                      clickedElement: 'InfoIcon_Datenschutz',
                      timedCall: true,
                    })
                  );
                try {
                  api.getAemXf(rechtsschutzAemAssets.tooltipLinkPricvacyTooltip || '', isEditMode).then((response) => {
                    setPrivacyTooltip(loadExternalJs(response.data));
                  });
                } catch (error: unknown) {
                  setPrivacyTooltip('<div><strong>Fehler aufgetreten</strong></div>');
                  if (isAxiosError(error) && error.response) {
                    dispatch(
                      errorTrack({
                        errorType: error.response.status,
                        errorMessages: `${error.request.responseURL}: ${error.message}`,
                        errorUrl: window.location.href,
                      })
                    );
                  }
                }
              }}
            >
              {!privacyTooltip && <LoaderIcon />}
              <div dangerouslySetInnerHTML={{ __html: privacyTooltip }} />
            </TooltipIcon>
          </div>
        </div>
      </div>
      <div id="personal-data_input-container">
        <h3 className="personal-data__section-title">Persönliche Daten als Versicherungsnehmer</h3>

        <div className="personal-data__inputs-container">
          <RadioGroupRow
            name="salutation"
            className="personal-data__salutation"
            label="Anrede"
            value={personalData && personalData.salutation ? personalData.salutation : salutation}
            error={!!errors.personalData.missingSalutation && errors.personalData.missingSalutation}
          >
            <Radio
              data-testid="salutation-mr"
              value={PersonSalutation.MISTER}
              label="Herr"
              onChange={() => {
                setSalutation(PersonSalutation.MISTER);
                dispatch(resetPersonalDataError('missingSalutation'));
                dispatch(addUserAttribute({ gender: PersonSalutation.MISTER }));
              }}
            />

            <Radio
              data-testid="salutation-mrs"
              value={PersonSalutation.MISTRESS}
              label="Frau"
              onChange={() => {
                setSalutation(PersonSalutation.MISTRESS);
                dispatch(resetPersonalDataError('missingSalutation'));
                dispatch(addUserAttribute({ gender: PersonSalutation.MISTRESS }));
              }}
            />
          </RadioGroupRow>
          <FormRow label="Name" colspans={[5, 5]}>
            <ControlWithHint error={handleErrors('missingName', firstName)}>
              <Input
                placeholder="Vorname"
                onKeyPress={(e) => validateRegexInput(e)}
                value={firstName}
                onChange={(e) => {
                  setFirstName(capitalizeFirstLetter(e.target.value));
                  dispatch(resetPersonalDataError('missingName'));
                  dispatch(resetPersonalDataError('unallowedCharacters'));
                }}
              />
            </ControlWithHint>
            <ControlWithHint error={handleErrors('missingSurname', lastName)}>
              <Input
                placeholder="Nachname"
                onKeyPress={(e) => validateRegexInput(e)}
                value={lastName}
                onChange={(e) => {
                  setLastName(capitalizeFirstLetter(e.target.value));
                  dispatch(resetPersonalDataError('missingSurname'));
                  dispatch(resetPersonalDataError('unallowedCharacters'));
                }}
              />
            </ControlWithHint>
          </FormRow>
          <div className="personal-data__adress">
            <FormRow label="Adresse" colspans={[8, 3]}>
              <ControlWithHint error={handleErrors('missingStreet', street)}>
                <Input
                  placeholder="Straße"
                  onKeyPress={(e) => validateRegexInput(e)}
                  value={street}
                  maxLength={51}
                  onChange={(e) => {
                    setStreet(capitalizeFirstLetter(e.target.value));
                    dispatch(resetPersonalDataError('missingStreet'));
                    dispatch(resetPersonalDataError('unallowedCharacters'));
                  }}
                />
              </ControlWithHint>
              <ControlWithHint error={handleErrors('missingHouseNumber', houseNumber)}>
                <Input
                  placeholder="Nr."
                  value={houseNumber}
                  maxLength={10}
                  onChange={(e) => {
                    setHouseNumber(e.target.value);
                    dispatch(resetPersonalDataError('missingHouseNumber'));
                    dispatch(resetPersonalDataError('unallowedCharacters'));
                  }}
                />
              </ControlWithHint>
            </FormRow>
            <FormRow label="" colspans={[4, 8]} error={handleErrors('unknownPostcode', postcode)}>
              <ControlWithHint
                error={`${handleErrors('missingPostcode', postcode)}${handleErrors('invalidPostcode', postcode)}`}
              >
                <Input
                  type="number"
                  id="postcode"
                  placeholder="PLZ"
                  onKeyPress={(e) => validateNumberInput(e)}
                  value={postcode}
                  onBlur={checkIfPostCodeIsInvalid}
                  onChange={onChangePostCodeHandler}
                  adornmentRight={isFetching ? <LoaderIcon /> : undefined}
                />
              </ControlWithHint>
              <AreaComponent
                areaCollection={areaCollection}
                area={area}
                setArea={setArea}
                personalData={personalData}
                customArea={customArea}
                setCustomArea={setCustomArea}
              />
            </FormRow>
          </div>
          <FormRow label="Geburtsdatum">
            <div className="personal-data__birthdate">
              <p>{birthdate}</p>

              <Button
                data-testid="navigate-birthdate-button"
                variant="text-link"
                iconLeft={EditIcon}
                size="large"
                onClick={() => {
                  navigate(RoutePath.BIRTHDATE);
                  dispatch(
                    clickEventTrack({
                      clickedElement: 'Link_Geburtsdatum',
                      timedCall: false,
                    })
                  );
                }}
              >
                Geburtsdatum ändern
              </Button>
            </div>
          </FormRow>

          <FormRow
            className="personal-data__email"
            label={
              <div className="personal-data__email-label">
                <p>E-Mail-Adresse</p>
                <TooltipIcon
                  isModal
                  onToggledOpen={(isOpen) => {
                    if (isOpen)
                      dispatch(
                        clickEventTrack({
                          clickedElement: 'InfoIcon_Email',
                          timedCall: true,
                        })
                      );
                  }}
                >
                  {PERSONALDATA_EMAIL_TOOLTIP}
                </TooltipIcon>
              </div>
            }
          >
            <ControlWithHint error={handleEmailErrors()}>
              <Input
                id="email"
                placeholder="beispiel@beispiel.de"
                value={email}
                onBlur={(e) => {
                  if (!validateEmail(e.target.value)) dispatch(setPersonalDataError('wrongEmail'));
                  if (validateEmail(e.target.value) && isEmailForbidden(e.target.value))
                    dispatch(setPersonalDataError('wrongEmailErgo'));
                }}
                onChange={(e) => {
                  setEmail(removeSpaces(e.target.value));
                  dispatch(resetPersonalDataError('missingEmail'));
                  dispatch(resetPersonalDataError('wrongEmail'));
                  dispatch(resetPersonalDataError('wrongEmailErgo'));
                }}
                maxLength={60}
              />
            </ControlWithHint>
          </FormRow>

          <div className="personal-data__phone">
            <FormRow
              label="Telefon / Mobilfunknummer (optional)"
              error={errors.personalData.wrongPhoneNumber ? errors.personalData.wrongPhoneNumber : undefined}
            >
              <div className="personal-data__phone-prefix">
                <div className="personal-data__phone-prefix__country">
                  <Input value="+49" onChange={() => '+49'} />
                </div>
                <div className="personal-data__phone-prefix__number">
                  <ControlWithHint description="">
                    <Input
                      type="number"
                      id="phone-prefix"
                      placeholder="Vorwahl"
                      onKeyPress={(e) => validateNumberInput(e)}
                      maxLength={4}
                      onKeyUp={() =>
                        autotab(document.getElementById('phone-prefix'), document.getElementById('phone-number'))
                      }
                      value={phonePrefix}
                      onChange={(e) => {
                        if (e.target.value.length < 5) setPhonePrefix(String(e.target.value).replace(/^0+/, ''));
                        dispatch(resetPersonalDataError('wrongPhoneNumber'));
                      }}
                    />
                  </ControlWithHint>
                </div>
              </div>

              <ControlWithHint description="">
                <Input
                  type="number"
                  id="phone-number"
                  placeholder="Rufnummer"
                  onKeyPress={(e) => validateNumberInput(e)}
                  value={phoneNumber}
                  maxLength={11}
                  onChange={(e) => {
                    if (e.target.value.length < 12) setPhoneNumber(String(e.target.value));
                    dispatch(resetPersonalDataError('wrongPhoneNumber'));
                  }}
                />
              </ControlWithHint>
            </FormRow>

            <FormRow label="" className="phone-row">
              <div className="phone-text">
                <strong className="phone-text__title">Warum benötigt ERGO Ihre Telefonnummer?</strong>
                <p className="phone-text__body">
                  Fragen zu Ihrem Antrag lassen sich am schnellsten telefonisch klären.
                </p>
              </div>
            </FormRow>
          </div>
        </div>
      </div>
    </div>
  );
};
