import { useEffect } from 'react';
import classNames from 'classnames';
import FamilyIcon from '@eg/elements/Icons/FamilyIcon';
import ManIcon from '@eg/elements/Icons/ManIcon';
import ParentsIcon from '@eg/elements/Icons/ParentsIcon';
import Radio from '@eg/elements/Radio';
import RadioTile from '@eg/elements/RadioTile';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeMaritalStatus } from '../../store/offer';
import { RoutePath } from '../app/app.interface';
import { canGoToNextStep, setDirectionsState } from '../navigation-buttons/navigation-button.slice';
import TooltipIconComponent from '../tooltip-icon/tooltip-icon';
import { clickEventTrack, pageLoadTrack } from '../tracking/tracking.slice';
import { isMaritalStatus, RsMaritalStatus } from './marital-status.interface';
import { MaritalStatusTooltip } from './marital-status.tooltip';
import { ReactComponent as SingleParentIcon } from '../../assets/svgs/SingleParentIcon.svg';
import './marital-status.component.scss';

const maritalStatuses: {
  value: RsMaritalStatus;
  label: string;
  icon: JSX.Element;
}[] = [
  {
    value: RsMaritalStatus.SINGLE,
    label: 'Single',
    icon: <ManIcon width="100%" height="100%" />,
  },
  {
    value: RsMaritalStatus.SELF_PARENTING,
    label: 'Alleinerziehend',
    // Todo: Replace  with SingleParentIcon and remove the hardcoded one after EE Update
    icon: <SingleParentIcon />,
  },
  {
    value: RsMaritalStatus.PAIR,
    label: 'Paar',
    icon: <ParentsIcon width="100%" height="100%" />,
  },
  {
    value: RsMaritalStatus.FAMILY,
    label: 'Familie',
    icon: <FamilyIcon width="100%" height="100%" />,
  },
];

const MaritalStatus = function MaritalStatus(): JSX.Element {
  const dispatch = useAppDispatch();
  const maritalStatus = useAppSelector((state) => state.offer.maritalStatus);
  const aem = useAppSelector((state) => state.aem);

  useEffect(() => {
    dispatch(
      setDirectionsState({
        backLinkLabel: 'zurück zur Produktseite',
        canGoFurther: !!maritalStatus,
        previousStep: RoutePath.MARITAL_STATUS,
        nextStep: RoutePath.EMPLOYMENT,
      })
    );
    dispatch(
      pageLoadTrack({
        viewName:
          `${aem.rechtsschutzAemAssets.trackingShortName}:${aem.rechtsschutzAemAssets.trackingViewNameMaritalStatus}` ||
          '',
        transactionAttributes: { conversionType: 'SaleFunnelStart' },
      })
    );
  }, []);

  const setMaritalStatus = (newStatus: RsMaritalStatus) => {
    if (isMaritalStatus(newStatus)) {
      dispatch(changeMaritalStatus(newStatus));
      dispatch(canGoToNextStep(!!newStatus));
    }
  };

  return (
    <div className="marital-status__container">
      <div id="marital-status_header">
        <div className="page__title">Wählen Sie Ihre Lebenssituation aus.</div>
        <div className="page__subtitle">
          ERGO bietet Ihnen online den passenden Versicherungsschutz bei alltäglichen Rechtsproblemen.
          <TooltipIconComponent
            onToggledOpen={(isOpen) => {
              if (isOpen)
                dispatch(
                  clickEventTrack({
                    clickedElement: 'InfoIcon_Situation',
                    timedCall: true,
                  })
                );
            }}
          >
            <MaritalStatusTooltip />
          </TooltipIconComponent>
        </div>
      </div>

      <div className="marital-status__select-container-mobile" id="marital-status_select-container-mobile">
        {maritalStatuses.map(({ value, label }) => (
          <div
            className={classNames({
              'esc_border-radius': true,
              'marital-status__single-radio-row': true,
              selected: maritalStatus === value,
            })}
            key={value}
            id={`marital-status-select-mobile-${value}`}
          >
            <Radio
              className="marital-status__option-radio"
              value={value}
              data-testid={`marital-status-select-mobile-${value}`}
              name="marital-status-select-mobile"
              checked={maritalStatus === value}
              label={label}
              onChange={() => {
                setMaritalStatus(value);
                dispatch(
                  clickEventTrack({
                    clickedElement: `RButton_${value}`,
                    tariffOptions: { Situation: `${value}` },
                    timedCall: true,
                  })
                );
              }}
            />
          </div>
        ))}
      </div>
      <div className="marital-status__select-container" id="marital-status_select-container">
        {maritalStatuses.map((status) => (
          <div id={`marital-status-radio-tile-${status.value}`} key={status.value}>
            <RadioTile
              value={status.value}
              checked={maritalStatus === status.value}
              data-testid={`marital-status-radio-tile-${status.value}`}
              hideCheckMark
              onChange={() => {
                setMaritalStatus(status.value);
                dispatch(
                  clickEventTrack({
                    clickedElement: `RButton_${status.value}`,
                    tariffOptions: { Situation: `${status.value}` },
                    timedCall: true,
                  })
                );
              }}
            >
              <div className="marital-status__icon-container">{status.icon}</div>
              <div className="marital-status__icon-description">{status.label}</div>
              <Radio
                className="marital-status__radio"
                checked={maritalStatus === status.value}
                onChange={() => {
                  setMaritalStatus(status.value);
                  dispatch(
                    clickEventTrack({
                      clickedElement: `RButton_${status.value}`,
                      tariffOptions: { Situation: `${status.value}` },
                      timedCall: true,
                    })
                  );
                }}
                label=""
                value={status.value}
              />
            </RadioTile>
          </div>
        ))}
      </div>
    </div>
  );
};

const MaritalStatusComponent = MaritalStatus;
export default MaritalStatusComponent;
