export enum Environment {
  DEVELOPMENT = 'development',
  DVIT = 'dvit',
  FAIT = 'fait',
  ABN = 'abn',
  PRODUCTION = 'production',
}

export const NODE_ENV = window.NODE_ENV || process.env.NODE_ENV || Environment.PRODUCTION;

export const CONFIG_BACKEND_API_BASE_URL =
  window.CONFIG_BACKEND_API_BASE_URL || process.env.REACT_APP_BACKEND_API_BASE_URL;
