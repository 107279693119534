import { configureStore } from '@reduxjs/toolkit';
import aemAssetsReducer from '../components/aem/aem.slice';
import navigationButtonReducer from '../components/navigation-buttons/navigation-button.slice';
import progressbarReducer from '../components/progress-bar/progress-bar.slice';
import trackingReducer from '../components/tracking/tracking.slice';
import errorHandlingReducer from './errors';
import offerReducer from './offer';
import { loadSession, saveState } from './sessionStore';

const sessionStore = loadSession();
export const store = configureStore({
  reducer: {
    offer: offerReducer,
    errors: errorHandlingReducer,
    navigationButtons: navigationButtonReducer,
    progressbar: progressbarReducer,
    aem: aemAssetsReducer,
    tracking: trackingReducer,
  },
  preloadedState: sessionStore,
});

store.subscribe(() => {
  saveState(store.getState());
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
