import './progress-bar.component.scss';

interface ProgressBarProps {
  progressPercent: number; // 0-100%
}

export const ProgressBarComponent = function ProgressBarComponent(props: ProgressBarProps): JSX.Element {
  const { progressPercent } = props;
  return (
    <div className="progress-bar">
      <div className="progress-bar__container">
        <div className="progress-bar__progress" style={{ width: `${progressPercent}%` }} />
      </div>
    </div>
  );
};
