export const PaymentTooltip = function PaymentTooltip(): JSX.Element {
  return (
    <div className="tooltip">
      <p>
        Sollte bereits ein Mandat für die oben genannte Kontoverbindung bestehen, bin ich damit einverstanden, dass das
        von mir bereits erteilte SEPA-Lastschriftmandat auch für den Einzug der Beiträge für diesen Versicherungsvertrag
        genutzt wird.
      </p>
    </div>
  );
};
