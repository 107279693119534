/* eslint-disable react/jsx-props-no-spreading */
import { ForwardedRef, forwardRef } from 'react';
import { SelectFieldSvg } from './SelectField.svg';
import { SelectFieldProps } from './SelectField.types';

export const SelectField = forwardRef(
  (
    {
      isError,
      name,
      options,
      onClick,
      useEmptyOptionPlaceholder,
      showPlaceholder = true,
      disabled,
      defaultValue,
      ...formProps
    }: SelectFieldProps,
    ref: ForwardedRef<HTMLSelectElement>
  ) => {
    return (
      <div className="ee_select">
        <select
          disabled={disabled}
          onClick={onClick}
          className="ee_select__control"
          ref={ref}
          name={name}
          defaultValue={defaultValue}
          {...(isError && { 'aria-invalid': true })}
          {...formProps}
        >
          {useEmptyOptionPlaceholder && (
            <option
              value=""
              style={{
                display: showPlaceholder ? 'block' : 'none',
              }}
            >
              {/* Empty placeholder */}
            </option>
          )}
          {options.map((option: { value: string; label: string }) => (
            <option key={`duration_option_${option.value}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <SelectFieldSvg />
      </div>
    );
  }
);
