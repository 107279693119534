export const INSURANCE_START_TOOLTIP = (
  <div className="tooltip">
    <h2>Versicherungsbeginn und Wartezeit</h2>
    <h4>Versicherungsbeginn </h4>

    <p>
      Der Versicherungsschutz beginnt <b>zum vereinbarten Datum um 0 Uhr</b>. Voraussetzung: Sie zahlen den ersten
      Beitrag spätestens 14 Tage, nachdem Sie den Versicherungsschein erhalten haben. <br />
      <br />
      Ihren Rechtsschutz können Sie <b>bis zu ein Jahr im Voraus beantragen</b>. Liegt der Versicherungsbeginn in der
      Zukunft, müssen Sie den Beitrag nicht vorher zahlen.
    </p>
    <h4>3 Monate Wartezeit bestehen bei den folgenden Leistungen:</h4>
    <h4>Lebensbereich Privat</h4>
    <ul>
      <li>
        <p>Rechtsschutz im Vertrags- und Sachenrecht </p>
      </li>
      <li>
        <p>Verwaltungs-Rechtsschutz</p>
      </li>
      <li>
        <p>Rechtsschutz für Betreuungsverfahren</p>
      </li>
    </ul>

    <h4>Lebensbereich Beruf </h4>
    <ul>
      <li>
        <p>Arbeits-Rechtsschutz</p>
      </li>
      <li>
        <p>Rechtsschutz im Vertrags- und Sachenrecht </p>
      </li>
      <li>
        <p>Verwaltungs-Rechtsschutz </p>
      </li>
    </ul>

    <h4>Lebensbereich Wohnen </h4>
    <ul>
      <li>
        <p>Wohnungs- und Grundstücks-Rechtsschutz</p>
      </li>
      <li>
        <p>Rechtsschutz für Planfeststellungs-, Enteignungs- und Flurbereinigungsverfahren </p>
      </li>
    </ul>

    <h4>Lebensbereich Verkehr </h4>
    <ul>
      <li>
        <p>
          Verkehrs-Rechtsschutz im Vertrags- und Sachenrecht (keine Wartezeit bei Erwerb eines Neufahrzeugs oder bei
          Leasingverträgen)
        </p>
      </li>
      <li>
        <p>Verwaltungs-Rechtsschutz in Verkehrssachen</p>
      </li>
    </ul>

    <p>
      <b>Tipp</b>: Sie haben eine <b>Vorversicherung</b>? Wählen Sie in diesem Fall als <b>Versicherungsbeginn</b> den
      Tag, an dem die Vorversicherung endet. Dann <b>entfällt die Wartezeit</b>.
    </p>
  </div>
);
