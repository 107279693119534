import { AxiosResponse } from 'axios';
import request from '../app/api/request';
import { OfferRequest } from './download.interface';

export const getPdfDocument = (tarifData: OfferRequest): Promise<AxiosResponse> => {
  return request.post('/pdf/printPdf', tarifData, { responseType: 'blob' });
};

export const postOffer = (body: OfferRequest): Promise<AxiosResponse> => {
  return request.post('/api/antrag/post/', body);
};
