/* eslint-disable jsx-a11y/label-has-associated-control */
import Button from '@eg/elements/Button';
import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import DownloadIcon from '@eg/elements/Icons/DownloadIcon';
import LoaderIcon from '@eg/elements/Icons/LoaderIcon';
import Modal from '@eg/elements/Modal';
import RadioTab from '@eg/elements/RadioTab';
import RadioTabs from '@eg/elements/RadioTabs';
import SelectRow from '@eg/elements/SelectRow';
import TooltipIcon from '@eg/elements/TooltipIcon';
import React, { useEffect, useRef, useState } from 'react';
import { setServerError } from '../../store/errors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeContribution, changeDuration, changeInterval, changePrice, changeVariant } from '../../store/offer';
import { deviceType } from '../../utils/helpers';
import { createTarifData } from '../../utils/offer-german-mutation';
import { SelectField } from '../@eg_replacments/SelectField/SelectField';
import { loadExternalJs } from '../aem/aemHelper';
import { isAxiosError } from '../app/api/request';
import { RoutePath } from '../app/app.interface';
import { UnemployedSituationOptionLabel } from '../employment-situation/employment-situation.interface';
import { Grid } from '../grid/grid';
import { GridRow } from '../grid/grid-container';
import { changeLoading, setDirectionsState } from '../navigation-buttons/navigation-button.slice';
import {
  addTransactionAttribute,
  clickEventTrack,
  errorTrack,
  pageLoadTrack,
  setProductCombination,
  setTariffOptions,
} from '../tracking/tracking.slice';
import * as api from './tarif-data.api';
import { Checkboxes } from './tariff-data.checkbox';
import './tariff-data.component.scss';
import { bestBenefitList, PayementIntervals, smartBenefitList } from './tariff-data.interface';
import { ContributionTooltip, DurationTooltip, PaymentTooltip } from './tariff-data.tooltip';

const TariffData = function TariffData(): JSX.Element {
  const dispatch = useAppDispatch();
  const offer = useAppSelector((state) => state.offer);
  const aem = useAppSelector((state) => state.aem);
  const { rechtsschutzAemAssets, isEditMode } = useAppSelector((state) => state.aem);
  const [showModal, setShowModal] = useState(false);
  const [productTableHtml, setProductTableHtml] = useState('');
  const initial = useRef(true);
  const [width, setWidth] = useState(window.innerWidth);
  const resizeHandler = () => setWidth(window.innerWidth);

  useEffect(() => {
    dispatch(changeDuration(1));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    dispatch(
      setDirectionsState({
        backLinkLabel: 'zurück',
        canGoFurther: true,
        previousStep: RoutePath.BIRTHDATE,
        nextStep: RoutePath.PERSONAL_DATA,
        showBackButton: true,
        jumpToContent:
          !offer.rsPrivate && !offer.rsTraffic ? `#tariff-data_checkbox-title-${offer.variant}` : undefined,
      })
    );
  });

  const clickOpenPopup = function clickOpenPopup() {
    setShowModal(true);
    let url = rechtsschutzAemAssets.productTableLinkEmployed;
    if (
      offer.employmentSituation === UnemployedSituationOptionLabel.OFFICAL_RETIREMENT ||
      offer.employmentSituation === UnemployedSituationOptionLabel.RETIREMENT
    )
      url = rechtsschutzAemAssets.productTableLinkUnemployed;
    api
      .getAemXf(url || '', isEditMode)
      .then((response) => {
        setProductTableHtml(loadExternalJs(response.data));
      })
      .catch((error: unknown) => {
        if (isAxiosError(error) && error.response) {
          dispatch(
            errorTrack({
              errorType: error.response.status,
              errorMessages: `${error.request.responseURL}: ${error.message}`,
              errorUrl: window.location.href,
            })
          );
        }
        setProductTableHtml('<div><strong>Fehler aufgetreten</strong></div>');
      });
    dispatch(
      clickEventTrack({
        clickedElement: `Button_AlleLeistungen`,
        timedCall: true,
      })
    );
  };

  useEffect(() => {
    dispatch(changeLoading(true));

    api
      .postTarif(createTarifData(offer))
      .then((response) => {
        let price;
        let priceYearNetto;
        if (response.data.model) {
          if (offer.variant === 'best') {
            price = response.data.model[1].result.beitragNum;
            priceYearNetto = response.data.model[1].result.beitragJahrNettoNum;
          } else {
            price = response.data.model[0].result.beitragNum;
            priceYearNetto = response.data.model[0].result.beitragJahrNettoNum;
          }
          dispatch(changePrice({ price, priceYearNetto }));
        }
        dispatch(changeLoading(false));
        dispatch(addTransactionAttribute({ calculatedValue: priceYearNetto }));
        if (initial.current) {
          initial.current = false;
          dispatch(
            pageLoadTrack({
              viewName:
                `${aem.rechtsschutzAemAssets.trackingShortName}:${aem.rechtsschutzAemAssets.trackingViewNameTariffData}` ||
                '',
              transactionAttributes: { conversionType: 'SaleFunnelCalculation', calculatedValue: priceYearNetto },
              tariffOptions: {
                RSPr: offer.rsPrivate ? 'J' : 'N',
                RSBe: offer.rsEmployment ? 'J' : 'N',
                RSWo: offer.rsHouse ? 'J' : 'N',
                RSVe: offer.rsTraffic ? 'J' : 'N',
                Zahlung: `1/${offer.interval}`,
                SB: `${offer.contribution}`,
                Dauer: `${offer.duration}`,
              },
            })
          );
        }
      })
      .catch((error: unknown) => {
        dispatch(setServerError(true));

        if (isAxiosError(error) && error.response) {
          dispatch(
            errorTrack({
              errorType: error.response.status,
              errorMessages: `${error.request.responseURL}: ${error.message}`,
              errorUrl: window.location.href,
            })
          );
        }
        dispatch(changeLoading(false));
      });
  }, [offer]);

  useEffect(() => {
    dispatch(setProductCombination(`${offer.variant[0].toUpperCase()}${offer.variant.slice(1)}`));
  }, [offer.variant]);

  const selectTab = (value: string) => {
    dispatch(changeVariant(value));
    dispatch(setProductCombination(`${value[0].toUpperCase()}${value.slice(1)}`));
    dispatch(
      clickEventTrack({
        clickedElement: `RButton_${value[0].toUpperCase()}${value.slice(1)}`,
        timedCall: true,
      })
    );
    return true;
  };

  return (
    <div className="tariff-data__container">
      <div>
        <div className="tariff-data__page-title">Wählen Sie Ihren Versicherungsschutz.</div>
        <div className="subheader">Sichern Sie sich Ihren ERGO Rechtsschutz.</div>
      </div>
      <div id="tariff-data_input-container">
        <RadioTabs currentValue={offer.variant} onSelect={selectTab} name="tariff_data_compare">
          <RadioTab title="Smart" value="smart" data-testid="tariff-data-tab-smart">
            <div className="tariff-data_radio-best-tab">
              <div className="tariff-data__tab-container" id="tariff-data_benefit-container">
                <h3 className="radio-title" id="tariff-data_radio-title">
                  Leistungsstarker Rechtsschutz im Komforttarif Smart
                </h3>

                <ul className="benefit-list">
                  {smartBenefitList.map((benefit) => {
                    return (
                      <li key={benefit.label} id={`tariff-data_benefit-${benefit.trackingName}`}>
                        <div>
                          <CheckColorIcon height={22} width={22} />
                          <strong>{benefit.label}</strong>
                          <TooltipIcon
                            isModal
                            onToggledOpen={(isOpen) => {
                              if (isOpen)
                                dispatch(
                                  clickEventTrack({
                                    clickedElement: `InfoIcon_${benefit.trackingName}`,
                                    timedCall: true,
                                  })
                                );
                            }}
                          >
                            {benefit.tooltip}
                          </TooltipIcon>
                        </div>
                        {benefit.subtext ? <div className="subtext">{benefit.subtext}</div> : null}
                      </li>
                    );
                  })}
                </ul>
                {(offer.employmentSituation === UnemployedSituationOptionLabel.OFFICAL_RETIREMENT &&
                  rechtsschutzAemAssets.productTableLinkUnemployed) ||
                (offer.employmentSituation === UnemployedSituationOptionLabel.RETIREMENT &&
                  rechtsschutzAemAssets.productTableLinkUnemployed) ||
                (rechtsschutzAemAssets.productTableLinkEmployed &&
                  !(offer.employmentSituation === UnemployedSituationOptionLabel.RETIREMENT) &&
                  !(offer.employmentSituation === UnemployedSituationOptionLabel.OFFICAL_RETIREMENT)) ? (
                  <Button
                    variant={deviceType() === 'mobile' ? 'text-link' : 'secondary'}
                    className="overview-button"
                    id="tariff-data_overview-button"
                    onClick={() => clickOpenPopup()}
                  >
                    Alle Leistungen im Vergleich
                  </Button>
                ) : null}
              </div>
              <Checkboxes variant="smart" />
            </div>
          </RadioTab>
          <RadioTab title="Best" value="best" data-testid="tariff-data-tab-best">
            <div className="tariff-data_radio-best-tab">
              <div className="tariff-data__tab-container" id="tariff-data_benefit-container">
                <h3 className="radio-title" id="tariff-data_radio-title">
                  Maximaler Rechtsschutz im Premiumtarif Best
                </h3>

                <ul className="benefit-list">
                  {bestBenefitList.map((benefit) => {
                    return (
                      <li key={benefit.label} id={`tariff-data_benefit-${benefit.trackingName}`}>
                        <div>
                          <CheckColorIcon height={22} width={22} />
                          <strong>{benefit.label}</strong>
                          <TooltipIcon
                            isModal
                            onToggledOpen={(isOpen) => {
                              if (isOpen)
                                dispatch(
                                  clickEventTrack({
                                    clickedElement: `InfoIcon_${benefit.trackingName}`,
                                    timedCall: true,
                                  })
                                );
                            }}
                          >
                            {benefit.tooltip}
                          </TooltipIcon>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                {(offer.employmentSituation === UnemployedSituationOptionLabel.OFFICAL_RETIREMENT &&
                  rechtsschutzAemAssets.productTableLinkUnemployed) ||
                (offer.employmentSituation === UnemployedSituationOptionLabel.RETIREMENT &&
                  rechtsschutzAemAssets.productTableLinkUnemployed) ||
                (rechtsschutzAemAssets.productTableLinkEmployed &&
                  !(offer.employmentSituation === UnemployedSituationOptionLabel.RETIREMENT) &&
                  !(offer.employmentSituation === UnemployedSituationOptionLabel.OFFICAL_RETIREMENT)) ? (
                  <Button
                    variant={deviceType() === 'mobile' ? 'text-link' : 'secondary'}
                    className="overview-button"
                    id="tariff-data_overview-button"
                    onClick={() => clickOpenPopup()}
                  >
                    Alle Leistungen im Vergleich
                  </Button>
                ) : null}
              </div>
              <Checkboxes variant="best" />
            </div>
          </RadioTab>
        </RadioTabs>
        <Modal open={showModal} dismissible onDismiss={() => setShowModal(false)} backdropDismissesModal>
          {!productTableHtml && <LoaderIcon />}
          <div dangerouslySetInnerHTML={{ __html: productTableHtml }} />
        </Modal>
        <div className="tariff-data__dropdowns" id="tariff-data_dropdowns">
          <div id="tariff-data_interval">
            <SelectRow
              label="Zahlungsweise"
              // value={offer.interval ?? offer.interval}
              tooltip={
                <TooltipIcon
                  isModal
                  onToggledOpen={(isOpen) => {
                    if (isOpen)
                      dispatch(
                        clickEventTrack({
                          clickedElement: 'InfoIcon_Zahlungsweise',
                          timedCall: true,
                        })
                      );
                  }}
                >
                  {PaymentTooltip}
                </TooltipIcon>
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(changeInterval(Number(e.target.value)));
                dispatch(setTariffOptions({ Zahlung: `1/${e.target.value}` }));
              }}
            >
              {PayementIntervals.map((interval) => (
                <option value={interval.value} key={`payment-interval-${interval.value}`}>
                  {interval.description}
                </option>
              ))}
            </SelectRow>
          </div>
          <div id="tariff-data_contribution">
            <SelectRow
              label="Selbstbeteiligung"
              defaultValue={offer.contribution ?? offer.contribution}
              tooltip={
                <TooltipIcon
                  isModal
                  onToggledOpen={(isOpen) => {
                    if (isOpen)
                      dispatch(
                        clickEventTrack({
                          clickedElement: 'InfoIcon_Selbstbeteiligung',
                          timedCall: true,
                        })
                      );
                  }}
                >
                  {ContributionTooltip}
                </TooltipIcon>
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(changeContribution(Number(e.target.value)));
                dispatch(setTariffOptions({ SB: e.target.value }));
              }}
            >
              <option value="150">150 €</option>
              <option value="250">250 €</option>
              {offer.variant === 'best' ? <option value="500">500 € (nur in Best)</option> : null}
            </SelectRow>
          </div>

          <div id="tariff-data_duration">
            <GridRow margin="0">
              <Grid size={width < 768 ? 12 : 5}>
                <div
                  // className="duration-select-responsive-container"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: width < 768 ? 'space-between' : '',
                    alignItems: 'center',
                  }}
                >
                  <label
                    // className="duration-select-responsive-label"
                    style={{
                      marginRight: width < 768 ? 0 : 8,
                    }}
                    htmlFor="durtion_select"
                  >
                    Vertragsdauer
                  </label>
                  <TooltipIcon
                    isModal
                    onToggledOpen={(isOpen) => {
                      if (isOpen)
                        dispatch(
                          clickEventTrack({
                            clickedElement: 'InfoIcon_Vertragsdauer',
                            timedCall: true,
                          })
                        );
                    }}
                  >
                    {DurationTooltip}
                  </TooltipIcon>
                </div>
              </Grid>
              <Grid size={width < 768 ? 12 : 7}>
                <SelectField
                  defaultValue="1"
                  options={[
                    { value: '1', label: '1 Jahr' },
                    { value: '3', label: '3 Jahre (10 % Dauernachlass)' },
                  ]}
                  name="duration-select"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(changeDuration(Number(e.target.value)));
                    dispatch(setTariffOptions({ Dauer: e.target.value }));
                  }}
                />
              </Grid>
            </GridRow>
          </div>
        </div>
      </div>
      {(offer.employmentSituation === UnemployedSituationOptionLabel.OFFICAL_RETIREMENT &&
        rechtsschutzAemAssets.pdfLinkUnemployed) ||
      (offer.employmentSituation === UnemployedSituationOptionLabel.RETIREMENT &&
        rechtsschutzAemAssets.pdfLinkUnemployed) ||
      (rechtsschutzAemAssets.pdfLinkEmployed &&
        !(offer.employmentSituation === UnemployedSituationOptionLabel.RETIREMENT) &&
        !(offer.employmentSituation === UnemployedSituationOptionLabel.OFFICAL_RETIREMENT)) ? (
        <div className="tariff-data__pdf-download" id="tariff-data__pdf-download">
          <Button
            className="tariff-data__pdf-download__btn"
            variant="text-link"
            iconLeft={DownloadIcon}
            size="large"
            onClick={() => {
              dispatch(
                clickEventTrack({
                  clickedElement: 'Link_LeistungsuebersichtPDF',
                  timedCall: false,
                })
              );
            }}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                rechtsschutzAemAssets.pdfLinkEmployed &&
                !(offer.employmentSituation === UnemployedSituationOptionLabel.RETIREMENT) &&
                !(offer.employmentSituation === UnemployedSituationOptionLabel.OFFICAL_RETIREMENT)
                  ? rechtsschutzAemAssets.pdfLinkEmployed
                  : rechtsschutzAemAssets.pdfLinkUnemployed
              }
              className="tariff-data__pdf-text"
              id="tariff-data_pdf-text"
            >
              Leistungsübersicht als PDF herunterladen
            </a>
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export const TariffDataComponent = TariffData;
