import React from 'react';
import { GridRowProps } from './grid.types';

export const GridRow: React.FC<GridRowProps> = ({ margin, children }: GridRowProps) => {
  return (
    <div className="esc_grid__wrapper" style={{ margin }}>
      {children}
    </div>
  );
};
