import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import Radio from '@eg/elements/Radio';
import RadioTile from '@eg/elements/RadioTile';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeEmploymentSituation } from '../../store/offer';
import { RoutePath } from '../app/app.interface';
import { EmploymentStatusOptionLabel } from '../employment/employment.interface';
import { canGoToNextStep, setDirectionsState } from '../navigation-buttons/navigation-button.slice';
import TooltipIcon from '../tooltip-icon/tooltip-icon';
import { addUserAttribute, clickEventTrack, pageLoadTrack } from '../tracking/tracking.slice';
import './employment-situation.component.scss';
import {
  EmployedSituationOptionLabel,
  isEmploymentSituation,
  UnemployedSituationOptionLabel,
} from './employment-situation.interface';
import { EmployedSituationTooltip, UnemployedSituationTooltip } from './employment-situation.tooltip';

const employedSituations: {
  value: EmployedSituationOptionLabel;
  label: string;
}[] = [
  {
    value: EmployedSituationOptionLabel.EMPLOYEE,
    label: 'Arbeitnehmer',
  },
  {
    value: EmployedSituationOptionLabel.OFFICAL,
    label: 'Beamte bzw. im öffentlichen Dienst tätig',
  },
  {
    value: EmployedSituationOptionLabel.SELF_EMPLOYED,
    label: 'Selbstständig bzw. Freiberufler',
  },
];

const unemployedSituations: {
  value: UnemployedSituationOptionLabel;
  label: string;
}[] = [
  {
    value: UnemployedSituationOptionLabel.RETIREMENT,
    label: '(Vor-)Ruhestand oder dauerhaft nicht mehr erwerbstätig',
  },
  {
    value: UnemployedSituationOptionLabel.OFFICAL_RETIREMENT,
    label: 'Beamter bzw. im öffentlichen Dienst im (Vor-)Ruhestand',
  },
  {
    value: UnemployedSituationOptionLabel.UNEMPLOYED,
    label: 'Ohne berufliche Tätigkeit',
  },
];

const allEmploymentSituationsTemplate: {
  value: EmployedSituationOptionLabel | UnemployedSituationOptionLabel;
  label: string;
}[] = [];
export const allEmploymentSituations = allEmploymentSituationsTemplate
  .concat(employedSituations)
  .concat(unemployedSituations);

const EmploymentSituationContainer = function EmploymentSituationContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const { employmentStatus, employmentSituation } = useAppSelector((state) => state.offer);
  const aem = useAppSelector((state) => state.aem);

  const setEmploymentSituation = (newStatus: EmployedSituationOptionLabel | UnemployedSituationOptionLabel) => {
    if (isEmploymentSituation(newStatus)) {
      dispatch(changeEmploymentSituation(newStatus));
      dispatch(canGoToNextStep(!!newStatus));
    }
  };

  const officalHint = employmentSituation === 'Beamter' ? 'flex' : 'none';
  const retirementHint = employmentSituation === 'Ruhestand' ? 'flex' : 'none';
  const officalRetirementHint = employmentSituation === 'RuhestandBeamter' ? 'flex' : 'none';
  const selfEmployedHint = employmentSituation === 'Selbstaendiger' ? 'flex' : 'none';

  const situation = employmentStatus === EmploymentStatusOptionLabel.YES ? employedSituations : unemployedSituations;

  useEffect(() => {
    dispatch(
      setDirectionsState({
        canGoFurther: !!employmentSituation,
        previousStep: RoutePath.EMPLOYMENT,
        nextStep: RoutePath.INSURANCE_START,
        showBackButton: true,
      })
    );
    dispatch(
      pageLoadTrack({
        viewName:
          `${aem.rechtsschutzAemAssets.trackingShortName}:${aem.rechtsschutzAemAssets.trackingViewNameEmploymentSituation}${employmentStatus}` ||
          '',
      })
    );
  }, []);

  return (
    <div className="employment-situation__container">
      <div className="empoyment-situation_header">
        <div className="page__title">
          {employmentStatus === EmploymentStatusOptionLabel.YES
            ? 'Wählen Sie Ihre berufliche Situation aus.'
            : 'Wählen Sie Ihre Situation aus.'}
        </div>
        <div className="page__subtitle">
          {employmentStatus === EmploymentStatusOptionLabel.YES
            ? 'Ihre berufliche Situation ist wichtig für die Berechnung Ihres Beitrags.'
            : 'Sind Sie im Vorruhestand bzw. Ruhestand? Dann profitieren Sie vom besonders günstigen Tarif für Senioren.'}
          <TooltipIcon
            isModal
            onToggledOpen={(isOpen) => {
              if (isOpen)
                dispatch(
                  clickEventTrack({
                    clickedElement: `InfoIcon_Beruf${employmentStatus}`,
                    timedCall: true,
                  })
                );
            }}
          >
            {employmentStatus === EmploymentStatusOptionLabel.YES ? (
              <EmployedSituationTooltip />
            ) : (
              <UnemployedSituationTooltip />
            )}
          </TooltipIcon>
        </div>
      </div>
      <div className="employment-situation__select-container-mobile" id="employment-situation_select-container-mobile">
        {situation.map(({ value, label }) => (
          <div
            className={classNames({
              'esc_border-radius': true,
              'employment-situation__single-radio-row': true,
              selected: employmentSituation === value,
            })}
            key={value}
            id={`employment-situation-select-mobile-${value}`}
          >
            <Radio
              className="employment-situation__option-radio"
              value={value}
              data-testid={`employment-situation-select-mobile-${value}`}
              name="employment-situation-select-mobile"
              checked={employmentSituation === value}
              label={label}
              onChange={() => {
                setEmploymentSituation(value);
                dispatch(addUserAttribute({ jobTitle: value }));
                dispatch(
                  clickEventTrack({
                    clickedElement: `RButton_${value}`,
                    timedCall: true,
                  })
                );
              }}
            />
          </div>
        ))}
      </div>
      <div className="employment-situation__select-container" id="employment-situation_select-container">
        {situation.map((status) => (
          <div key={status.value} id={`employment-situation-radio-tile-${status.value}`}>
            <RadioTile
              value={status.value}
              hideCheckMark
              data-testid={`employment-situation-radio-tile-${status.value}`}
              onChange={() => {
                setEmploymentSituation(status.value);
                dispatch(addUserAttribute({ jobTitle: status.value }));
                dispatch(
                  clickEventTrack({
                    clickedElement: `RButton_${status.value}`,
                    timedCall: true,
                  })
                );
              }}
              checked={employmentSituation === status.value}
            >
              <Radio
                className="employment-situation__icon-description"
                checked={employmentSituation === status.value}
                onChange={() => {
                  setEmploymentSituation(status.value);
                  dispatch(addUserAttribute({ jobTitle: status.value }));
                  dispatch(
                    clickEventTrack({
                      clickedElement: `RButton_${status.value}`,
                      timedCall: true,
                    })
                  );
                }}
                label={status.label}
                value={status.value}
              />
            </RadioTile>
          </div>
        ))}
      </div>
      <div className="employment-situation__offical-hint" style={{ display: officalHint }}>
        <CheckColorIcon width={20} height={20} />
        <span className="employment-situation__offical-hint-text">
          Als Beamter profitieren Sie von einem <b>günstigeren Beamtentarif</b>, wenn Sie den Lebensbereich Verkehr
          absichern.
        </span>
      </div>
      <div className="employment-situation__offical-hint" style={{ display: retirementHint }}>
        <CheckColorIcon width={20} height={20} />
        <span className="employment-situation__offical-hint-text">
          Da Sie im Ruhestand sind, profitieren Sie vom <strong>günstigeren Tarif</strong> für Senioren.
        </span>
      </div>
      <div className="employment-situation__offical-hint" style={{ display: officalRetirementHint }}>
        <CheckColorIcon width={20} height={20} />
        <span className="employment-situation__offical-hint-text">
          Sie profitieren vom <strong>günstigeren Tarif</strong> für Senioren. Und als Beamter zusätzlich von einer{' '}
          <strong>günstigeren Absicherung</strong> im Bereich Verkehr.
        </span>
      </div>
      <div className="employment-situation__offical-hint" style={{ display: selfEmployedHint }}>
        <span className="employment-situation__offical-hint-text">
          Private Risiken sind abgesichert. Um den Lebensbereich Beruf zusätzlich abzusichern, wählen Sie den{' '}
          <b>Rechtsschutz Best</b>. Nur darin sind Risiken aus <b>selbstständiger Tätigkeit</b> bis zu einem
          Gesamtumsatz von <b>22.000 € pro Jahr</b> mitversichert, wenn <b>keine Mitarbeiter</b> beschäftigt sind.
          <br />
          <br />
          Möchten Sie selbstständige Tätigkeiten <b>über 22.000 € pro Jahr</b> absichern, hilft Ihnen Ihr ERGO Berater
          vor Ort gern weiter.
        </span>
      </div>
    </div>
  );
};

const EmploymentSituationComponent = EmploymentSituationContainer;
export default EmploymentSituationComponent;
