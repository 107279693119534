export enum RoutePath {
  BIRTHDATE = '/geburtsdatum',
  INSURANCE_START = '/versicherungsbeginn',
  INIT = '/init',
  MARITAL_STATUS = '/lebenssituation',
  ROOT = '/',
  EMPLOYMENT = '/berufliche_situation',
  EMPLOYMENT_SITUATION = '/arbeitsverhaeltnis',
  TARIFF_DATA = '/tarifauswahl',
  PERSONAL_DATA = '/personendaten',
  TARIFF_OVERVIEW = '/zusammenfassung',
  PAYMENT = '/zahlungsdetails',
  THANK_YOU = '/feedback',
  PRE_INSURANCE = '/vorversicherung',
  DOWNLOAD = '/download',
}
