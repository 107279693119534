import FormFooter from '@eg/elements/FormFooter';
import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import Provider from '@eg/elements/Provider';
import classNames from 'classnames';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Environment, appId, isDevelopmentEnv } from '../../constants';
import { resetErrors, setNetworkError, setServerError } from '../../store/errors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeAgent, resetOffer } from '../../store/offer';
import { aemTracking } from '../aem/aem-tracking';
import { AemWrapper } from '../aem/aem-wrapper.component';
import { AemWindow } from '../aem/aem.interface';
import { aemLoadingComplete, loadAemAssets, resetAem, setEditMode } from '../aem/aem.slice';
import { BirthdateComponent } from '../birthdate/birthdate.component';
import buildInfo from '../../build-info.json';
import DownloadComponent from '../download/download.component';
import EmploymentSituationComponent from '../employment-situation/employment-situation.component';
import EmploymentComponent from '../employment/employment.component';
import ErrorHandlingModal from '../error-handling-modal/error-handling-modal.component';
import { FetchError } from '../error-handling-modal/error-handling-modal.interface';
import InsuranceStartDateComponent from '../insurance-start-date/insurance-start-date.component';
import MaritalStatusComponent from '../marital-status/marital-status.component';
import { resetNavigation, setButtonLabel, setButtonSpacing } from '../navigation-buttons/navigation-button.slice';
import { NavigationButtons } from '../navigation-buttons/navigation-buttons';
import PaymentComponent from '../payment/payment.component';
import { PersonalDataComponent } from '../personal-data/personal-data.component';
import PreInsuranceComponent from '../pre-insurance/pre-insurance.component';
import { PriceHeaderComponent } from '../price-header/price-header.component';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { NavigationPathsIndexes } from '../progress-bar/progress-bar.interface';
import { setProgress } from '../progress-bar/progress-bar.slice';
import TarifOverviewComponent from '../tarif-overview/tarif-overview.component';
import { TariffDataComponent } from '../tariff-data/tariff-data.component';
import ThankYouComponent from '../thank-you/thank-you.component';
import { AdobeAnalyticsSysEnv, TrackingBasic } from '../tracking/tracking.interface';
import { changeBrokerMode, initTrack, resetTracking } from '../tracking/tracking.slice';
import { RoutePath } from './app.interface';
import { NODE_ENV } from '../../config';
import '../common.scss';

export const App = function App(): JSX.Element {
  const isFormSubmittedSuccessfully = aemTracking.status.getIsFormSubmitted(appId);
  const dispatch = useAppDispatch();
  const [queryParams] = useSearchParams();

  const agentParams = {
    pnr: queryParams.get('adh_pnr'),
    oenr: queryParams.get('adh_oenr'),
    vorname: queryParams.get('adh_vorname'),
    nachname: queryParams.get('adh_nachname'),
    anrede: queryParams.get('adh_anrede'),
    tel: queryParams.get('adh_tel'),
    fax: queryParams.get('adh_fax'),
    mail: queryParams.get('adh_mail'),
    internet: queryParams.get('adh_internet'),
    mobil: queryParams.get('adh_mobil'),
    strasse: queryParams.get('adh_strasse'),
    plz: queryParams.get('adh_plz'),
    ort: queryParams.get('adh_ort'),
    regnr: queryParams.get('regNr'),
    channelType: queryParams.get('channelType'),
  };

  const { brokerModus } = useAppSelector((state) => state.tracking.trackingInformation.page.attributes);
  const changeBrokerModeCondition = agentParams.channelType === 'makler' || agentParams.channelType === 'Makler';

  const currentStepProgressPercentage = useAppSelector((state) => state.progressbar.currentProgress);

  const location = useLocation();
  const navigate = useNavigate();
  const personalData = useAppSelector((state) => state.offer.personalData);
  const navigation = useAppSelector((state) => state.navigationButtons);
  const errors = useAppSelector((state) => state.errors);
  const progressbar = useAppSelector((state) => state.progressbar);
  const routesOrder = [
    RoutePath.MARITAL_STATUS,
    RoutePath.EMPLOYMENT,
    RoutePath.EMPLOYMENT_SITUATION,
    RoutePath.INSURANCE_START,
    RoutePath.BIRTHDATE,
    RoutePath.TARIFF_DATA,
    RoutePath.PERSONAL_DATA,
    RoutePath.PRE_INSURANCE,
    RoutePath.TARIFF_OVERVIEW,
    RoutePath.PAYMENT,
    RoutePath.DOWNLOAD,
    RoutePath.THANK_YOU,
  ];
  const redirectedRouteIdx = routesOrder.findIndex((route) => route === location.pathname);
  const isNotNextRoute = () =>
    redirectedRouteIdx !== 0 && redirectedRouteIdx > routesOrder.findIndex((route) => route === navigation.goToNextUri);
  const canNotGoFurther = () =>
    redirectedRouteIdx === routesOrder.findIndex((route) => route === navigation.goToNextUri) &&
    !navigation.canGoFurther;
  const pageNotFound = () => redirectedRouteIdx === -1;
  const reachedLastPage = () =>
    progressbar.currentProgress > 100 && location.pathname !== routesOrder[routesOrder.length - 1];
  const routeGuard = () =>
    isDevelopmentEnv
      ? pageNotFound() || reachedLastPage()
      : canNotGoFurther() || isNotNextRoute() || pageNotFound() || reachedLastPage();
  const resetStore = () => {
    dispatch(resetOffer());
    dispatch(resetErrors());
    dispatch(resetNavigation());
    dispatch(resetTracking());
    dispatch(resetAem());
  };
  const userIsOnline = navigator.onLine;

  useEffect(() => {
    if (location.pathname === RoutePath.MARITAL_STATUS) {
      aemTracking.status.set({ appId, isTracking: false });
      aemTracking.status.setIsFormSubmitted({ appId, isFormSubmitted: false });
    }
  }, []);

  useEffect(() => {
    if (changeBrokerModeCondition) {
      dispatch(changeBrokerMode('On'));
    } else {
      dispatch(changeBrokerMode('Off'));
    }
  }, [agentParams.channelType]);

  useEffect(() => {
    if (userIsOnline) {
      dispatch(setNetworkError(false));
    } else {
      dispatch(setNetworkError(true));
    }

    dispatch(setProgress(NavigationPathsIndexes[location.pathname] || 1));

    if (location.pathname === RoutePath.DOWNLOAD) {
      dispatch(setButtonLabel('2 | Online beantragen'));
      dispatch(setButtonSpacing(false));
    } else if (location.pathname === RoutePath.TARIFF_DATA) {
      dispatch(setButtonLabel('Online beantragen'));
      dispatch(setButtonSpacing(true));
    } else {
      dispatch(setButtonLabel('weiter'));
      dispatch(setButtonSpacing(true));
    }

    if (reachedLastPage()) resetStore();

    // scroll to the top of the page
    return window.scrollTo(0, 0);
  }, [location.pathname]);
  useEffect(() => {
    if (
      isFormSubmittedSuccessfully === 'false' &&
      aemTracking.status.get(appId) === 'false' &&
      window.location.pathname === RoutePath.MARITAL_STATUS
    ) {
      aemTracking.status.set({ appId, isTracking: true });
      aemTracking.init();
    }
    dispatch(aemLoadingComplete());
    const aemWindow = window as unknown as AemWindow;
    if (aemWindow.rechtsschutzAemAssets) {
      dispatch(loadAemAssets(aemWindow.rechtsschutzAemAssets));

      const staticTrackingData: TrackingBasic = {
        page: {
          attributes: {
            displayedBrand: 'ERGO',
            brokerModus,
          },
          pageInfo: {
            // TODO CHANGE ACCORDING TO ENVIRONMENT
            sysEnv: NODE_ENV === Environment.PRODUCTION ? AdobeAnalyticsSysEnv.PROD : AdobeAnalyticsSysEnv.DEVELOP,
            issueDate: `OTRRechtsschutz2022|${buildInfo.buildDate}`,
          },
          category: {
            pagePurpose: 'Sale',
            primaryCategory: 'Rechtsschutz2022',
          },
        },
        product: [
          {
            productInfo: {
              productName: aemWindow.rechtsschutzAemAssets.trackingShortName || '',
            },
            attributes: {
              tariffOptions1: '',
            },
            category: { primaryCategory: aemWindow.rechtsschutzAemAssets.trackingProductGroup || '' },
          },
        ],
        transaction: {
          transactionID: uuidv4(),
        },
      };
      dispatch(initTrack(staticTrackingData));

      const isEditMode = aemWindow.location.hostname.includes('author');
      if (isEditMode) {
        dispatch(setEditMode);
      }
    }
    if (window.location.href.includes('&adh')) {
      dispatch(changeAgent(agentParams));
    }
  }, []);

  const getAemNavHeight = (): number => document.querySelector('.page__navi')?.clientHeight ?? 0;
  const getHeaderElement = (): HTMLElement | null => document.querySelector('.app__header');
  const getHeaderHeight = (): number => document.querySelector('.app__header')?.clientHeight ?? 0;
  const getMainElement = (): HTMLElement | null => document.querySelector('.app__main');

  // Move Unfall price header and progress bar just below AEM header
  const adjustHeaderToAEMWrapper = () => {
    const header = getHeaderElement();
    if (header) {
      header.style.marginTop = `${getAemNavHeight()}px`;
    }
  };

  const adjustContentToHeader = () => {
    const main = getMainElement();
    if (main) {
      main.style.paddingTop = `${getHeaderHeight()}px`;
    }
  };

  useEffect(adjustHeaderToAEMWrapper, [window.innerWidth]);
  useEffect(adjustContentToHeader);

  return (
    <AemWrapper inAem>
      <Provider className="app" theme="ergo-one">
        {location.pathname === RoutePath.THANK_YOU ? (
          // eslint-disable-next-line
          <>
            <div className="thank-you__hero">
              <div className="thank-you__hero-title">
                <CheckColorIcon width={40} height={40} />
                <br />
                <span>
                  Vielen Dank, {personalData?.firstName} {personalData?.lastName}!
                </span>
              </div>
              <h3 className="thank-you__hero-subtitle" id="thank-you_hero-subtitle">
                Ihr Antrag ist angekommen. Sie müssen nichts weiter tun.
              </h3>
            </div>
          </>
        ) : (
          <div className="app__header" id="app_header">
            <ProgressBarComponent progressPercent={currentStepProgressPercentage} />
            <PriceHeaderComponent location={location.pathname} />
          </div>
        )}
        <div className={classNames('app__main')} id="app_main">
          <section className="app__section">
            <ErrorHandlingModal
              open={errors.network || errors.server}
              onClick={() => {
                dispatch(setNetworkError(false));
                dispatch(setServerError(false));
                return navigate(0);
              }}
              errorType={errors.server ? FetchError.SERVER : FetchError.NETWORK}
            />
            <Routes>
              {routeGuard() && (
                <Route
                  key={`root-${location.pathname}`}
                  path={location.pathname}
                  element={<Navigate replace to={RoutePath.MARITAL_STATUS} />}
                />
              )}

              <Route path={RoutePath.MARITAL_STATUS} element={<MaritalStatusComponent />} />
              <Route path={RoutePath.EMPLOYMENT} element={<EmploymentComponent />} />
              <Route path={RoutePath.EMPLOYMENT_SITUATION} element={<EmploymentSituationComponent />} />
              <Route path={RoutePath.PERSONAL_DATA} element={<PersonalDataComponent />} />
              <Route path={RoutePath.BIRTHDATE} element={<BirthdateComponent />} />
              <Route path={RoutePath.INSURANCE_START} element={<InsuranceStartDateComponent />} />
              <Route path={RoutePath.TARIFF_DATA} element={<TariffDataComponent />} />
              <Route path={RoutePath.PAYMENT} element={<PaymentComponent />} />
              <Route path={RoutePath.PRE_INSURANCE} element={<PreInsuranceComponent />} />
              <Route path={RoutePath.TARIFF_OVERVIEW} element={<TarifOverviewComponent />} />
              <Route path={RoutePath.DOWNLOAD} element={<DownloadComponent />} />
              <Route path={RoutePath.THANK_YOU} element={<ThankYouComponent />} />
            </Routes>
          </section>
          <div className={navigation.spacingExists ? 'app__navigation' : 'app__navigation-download-page'}>
            <NavigationButtons />
          </div>
          <div className="app__footer">
            <FormFooter />
          </div>
        </div>
      </Provider>
    </AemWrapper>
  );
};
