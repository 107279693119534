import { OfferRequest } from '../components/download/download.interface';
import {
  EmployedSituationOptionLabel,
  UnemployedSituationOptionLabel,
} from '../components/employment-situation/employment-situation.interface';
import { RsMaritalStatus } from '../components/marital-status/marital-status.interface';
import { TarifBody } from '../components/tariff-data/tarif-data.api';
import { OfferState } from '../store/offer';

export const calculateDisplayTarif = (offer: OfferState): string => {
  let displayTarifName;

  switch (offer.variant) {
    case 'best':
      displayTarifName = 'Best ';
      break;
    case 'smart':
    default:
      displayTarifName = 'Smart ';
  }

  if (
    offer.employmentSituation === UnemployedSituationOptionLabel.OFFICAL_RETIREMENT ||
    offer.employmentSituation === UnemployedSituationOptionLabel.RETIREMENT
  )
    displayTarifName += 'Vital ';

  switch (offer.maritalStatus) {
    case RsMaritalStatus.SINGLE:
      displayTarifName += 'Single';
      break;
    case RsMaritalStatus.SELF_PARENTING:
      displayTarifName += 'Single';
      if (!displayTarifName.includes('Vital')) displayTarifName += ' für Alleinerziehende';
      break;
    case RsMaritalStatus.FAMILY:
      if (!displayTarifName.includes('Vital')) displayTarifName += 'Familie';
      break;
    case RsMaritalStatus.PAIR:
      if (!displayTarifName.includes('Vital')) displayTarifName += 'Familie für Paare';
      break;
    default:
  }

  if (offer.youngDiscount) {
    if (!displayTarifName.includes('Vital')) displayTarifName += ' inkl. 10 % Startbonus';
  }

  return displayTarifName;
};

export const calculateRequestTarif = (offer: OfferState): string => {
  let tarifName = '';
  if (
    offer.employmentSituation === UnemployedSituationOptionLabel.OFFICAL_RETIREMENT ||
    offer.employmentSituation === UnemployedSituationOptionLabel.RETIREMENT
  ) {
    if (offer.maritalStatus === RsMaritalStatus.SINGLE || offer.maritalStatus === RsMaritalStatus.SELF_PARENTING)
      tarifName = 'VitalSingle';
    else tarifName = 'Vital';
  } else if (offer.maritalStatus === RsMaritalStatus.SINGLE || offer.maritalStatus === RsMaritalStatus.SELF_PARENTING)
    tarifName = 'Single';
  else tarifName = 'Familie';

  return tarifName;
};

export const createTarifData = (offer: OfferState): TarifBody => {
  let rabattSeniorentarif = false;
  let rabattBeamtentarif = false;
  if (
    offer.employmentSituation === UnemployedSituationOptionLabel.OFFICAL_RETIREMENT ||
    offer.employmentSituation === UnemployedSituationOptionLabel.RETIREMENT
  )
    rabattSeniorentarif = true;
  if (
    offer.employmentSituation === EmployedSituationOptionLabel.OFFICAL ||
    offer.employmentSituation === UnemployedSituationOptionLabel.OFFICAL_RETIREMENT
  )
    rabattBeamtentarif = true;

  let insuranceTime: string | null = null;

  if (offer.duration === 1) insuranceTime = 'einJahr';
  if (offer.duration === 3) insuranceTime = 'FuenfJahre'; // its confirmed that for 3 and 5 ,value to be sent in request is same "FuenfJahre"

  return {
    geburtsdatumVN: offer.birthdate,
    rechtsschutzPrivat: offer.rsPrivate,
    rechtsschutzBeruf: offer.rsEmployment,
    rechtsschutzVerkehr: offer.rsTraffic,
    rechtsschutzWohnen: offer.rsHouse,
    tarifauswahl: offer.variant,
    rabattSeniorentarif,
    rabattBeamtentarif,
    situation: offer.maritalStatus,
    rabattStartbonus: offer.youngDiscount,
    selbstbeteiligung: `Wert${offer.contribution}`,
    versicherungsbeginn: offer.insuranceStartDate,
    vertragsdauer: insuranceTime,
    zahlungsweise: offer.interval,
    tarif: calculateRequestTarif(offer),
  };
};

export const createOfferRequestData = (offer: OfferState): OfferRequest => {
  return {
    key: null,
    websitePartnerId: 1,
    tarifDaten: createTarifData(offer),
    vorversicherung: {
      vorversicherung: offer.preInsuranceStatus,
      nameDerGesellschaft: offer.preInsurance && offer.preInsurance.name,
      gekuendigtVon: offer.preInsurance && offer.preInsurance.quitter,
      versicherungsscheinNummer: offer.preInsurance && offer.preInsurance.number,
      ablaufdatum: offer.preInsurance && offer.preInsurance.expiration,
      vorschaeden: Number(offer.preInsurance?.dropdown),
      isLeistungsfreiheitVisible: Number(offer.preInsurance?.dropdown) > 0,
    },
    persDaten: {
      anrede: offer.personalData && offer.personalData.salutation,
      vorname: offer.personalData && offer.personalData.firstName,
      nachname: offer.personalData && offer.personalData.lastName,
      geburtsdatum: offer.personalData && offer.personalData.birthdate,
      adresse: {
        strasse: offer.personalData && offer.personalData.adress.street,
        hausNr: offer.personalData && offer.personalData.adress.houseNumber,
        postleitzahl: offer.personalData && offer.personalData.adress.postcode,
        wohnort: offer.personalData && offer.personalData.adress.area,
        strasseHnr:
          offer.personalData && `${offer.personalData.adress.street} ${offer.personalData.adress.houseNumber}`,
        plzOrt: offer.personalData && `${offer.personalData.adress.postcode} ${offer.personalData.adress.area}`,
      },

      telefon:
        offer.personalData &&
        offer.personalData.countryCode &&
        offer.personalData.phonePrefix &&
        offer.personalData.phoneNumber
          ? `${offer.personalData.countryCode}${offer.personalData.phonePrefix}/${offer.personalData.phoneNumber}`
          : '',
      telefonVorwahl: offer.personalData && offer.personalData.countryCode,
      telefonNr: offer.personalData && `${offer.personalData.phonePrefix}${offer.personalData.phoneNumber}`,
      email: offer.personalData && offer.personalData.email,
      partnernummer: null,
      freiwilligeEinverstaendniserklaerung: false,
      kontaktPerEmail: false,
      kontaktPerTelefon: false,
    },
    bankDaten: {
      zahlart: 2,
      isLastschrift: true,
      zahlungstag: 1,
      iban: offer.payment.iban && offer.payment.iban.replace(' ', ''),
      bic: offer.payment.bic,
      bankname: offer.payment.bankName,
      abbuchungAm: '1. des Fälligkeitsmonats',
      ibanFormatted: offer.payment.iban && offer.payment.iban.replace(' ', ''),
      ibanDisplay: offer.payment.iban && offer.payment.iban,
      zahlungstagAsString: '1.',
    },
    vermittler: offer.agent,
    calculatedTarif: null,
    angebotsUrl: null,
    tarifGewaehlt: true,
    url: '',
    zahldatum: offer.insuranceStartDate,
  };
};
